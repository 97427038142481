import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { PrimaryButton, RiverSpinner, useNotification } from "@river/common-ui";
import { RiverDataGrid, tooltipCell } from "../river-data-grid";
import { PageHeader } from "../page-header";
import { LicenseDto } from "@river/interfaces";
import { customerService } from "../../services";
import { AddLicenseDialog } from "../add-license-dialog";
import EditIcon from "@mui/icons-material/Edit";
import { Column, RowsChangeData, TextEditor } from "react-data-grid";
import { AssignModuleAndActionsDialog } from "./assign-module-and-actions-dialog";
import { CustomerContext } from "../customer";
import { SubHeader } from "../sub-header";
import sharedStyles from "../shared-styles/shared-styles.module.scss";
import styles from "./customer-licenses.module.scss";

export const CustomerLicenses: React.FC = () => {
  const { customer } = useContext(CustomerContext)!;

  const [licenses, setLicenses] = useState<LicenseDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addLicenseDialog, setAddLicenseDialog] = useState<boolean>(false);
  const [assignModuleAndActionsDialog, setAssignModuleAndActionsDialog] =
    useState<boolean>(false);
  const [selectedLicense, setSelectedLicense] = useState<any>(null);

  const notify = useNotification();

  const openAddLicenseDialog = (): void => setAddLicenseDialog(true);
  const closeAddLicenseDialog = (): void => setAddLicenseDialog(false);

  const openAssignModuleAndActionsDialog = (): void =>
    setAssignModuleAndActionsDialog(true);
  const closeAssignModuleAndActionsDialog = (): void =>
    setAssignModuleAndActionsDialog(false);

  useEffect(() => {
    getLicenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLicenses = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await customerService.getCustomerLicenses(customer!._id);
      setLicenses(result);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const refresh = (): void => {
    getLicenses();
  };

  const columns: Column<any>[] = [
    {
      key: "license",
      name: "License",
      formatter: (row) => (
        <div
          className={sharedStyles.dataGridLink}
          onClick={() => {
            setSelectedLicense(row.row);
            openAssignModuleAndActionsDialog();
          }}
        >
          {tooltipCell(row.row[row.column.key])}
        </div>
      ),
    },
    {
      key: "description",
      name: "Description",
    },
    {
      key: "count",
      name: "Licensed Users",
      width: 120,
      editor: TextEditor,
      formatter: (formatterProps) => {
        return (
          <>
            <div className={styles.formatterContainer}>
              <p className={styles.formatterText}>{formatterProps.row.count}</p>
              <EditIcon className={styles.formatterIcon} />
            </div>
          </>
        );
      },
    },
  ];

  const onRowsChange = (rows: any[], rowData: RowsChangeData<any>) => {
    const newValue = rows[rowData.indexes[0]][rowData.column.key];
    const selectedRow = rows[rowData.indexes[0]];
    const update = async () => {
      try {
        await customerService.updateCustomerLicense(
          customer!._id,
          selectedRow._id,
          { ...selectedRow, count: Number(newValue) } as LicenseDto
        );
        await refresh();
      } catch (message) {
        notify.error({ message });
      }
    };

    update();
  };

  const renderAddLicenseButton = (): ReactElement => (
    <PrimaryButton text={"Add License"} onClick={openAddLicenseDialog} />
  );

  return (
    <>
      <PageHeader title={"Licences"} />
      <SubHeader subtitle={customer?.description!} />
      <Box className={sharedStyles.primaryButtons}>
        {renderAddLicenseButton()}
      </Box>
      <Box className={sharedStyles.viewContent}>
        <RiverDataGrid
          className={sharedStyles.dataGrid}
          columns={columns}
          rows={licenses}
          onRowsChange={onRowsChange}
        />
      </Box>
      <RiverSpinner show={isLoading} />

      <AddLicenseDialog
        open={addLicenseDialog}
        customer={customer!}
        onClose={(success) => {
          closeAddLicenseDialog();
          if (success) refresh();
        }}
      />
      <AssignModuleAndActionsDialog
        open={assignModuleAndActionsDialog}
        customer={customer!}
        onClose={(success) => {
          closeAssignModuleAndActionsDialog();
          if (success) refresh();
        }}
        selectedLicense={selectedLicense}
      />
    </>
  );
};
