import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  RiverDialogButton,
  RiverPasswordInput,
  RiverTextInput,
  useNotification,
  useValidation,
  IUseValidation,
  IValidationErrors,
  RiverSpinner,
} from "@river/common-ui";
import { EnvOauth2OptionsDto, IEnvOauth2Options } from "@river/interfaces";
import { useParams } from "react-router-dom";
import { customerService } from "../../../services";
import { SubHeader } from "../../sub-header";
import { CustomerContext } from "../../customer";
import { EnvironmentContext } from "../../customer-environment";
import { PageHeader } from "../../page-header";
import sharedStyles from "../../shared-styles/shared-styles.module.scss";

export const EnvironmentOAuth2Configuration: React.FC = () => {
  const environmentHandle: string = useParams().environmentHandle!;

  const { customer } = useContext(CustomerContext)!;
  const { environment } = useContext(EnvironmentContext)!;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validation: IUseValidation = useValidation();
  const [oAuth2Configuration, setOAuth2Configuration] =
    useState<EnvOauth2OptionsDto>(new EnvOauth2OptionsDto());

  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });

  const notify = useNotification();

  useEffect(() => {
    fetchEnvironmentOAuth2Options();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEnvironmentOAuth2Options = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const environmentOAuth2Options: IEnvOauth2Options | null =
        await customerService.getEnvironmentOauth2Options(
          customer!._id,
          environment!._id
        );
      if (environmentOAuth2Options) {
        Object.setPrototypeOf(
          environmentOAuth2Options,
          Object.getPrototypeOf(oAuth2Configuration)
        );

        setOAuth2Configuration(environmentOAuth2Options as EnvOauth2OptionsDto);
      }
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const validateEnvironment = async (
    environment: EnvOauth2OptionsDto
  ): Promise<IValidationErrors> => {
    const errors = await validation.validateFields(environment);
    setValidationErrors(errors);
    return errors;
  };

  const saveEnvironmentOauth2Options = async () => {
    const errors: IValidationErrors =
      await validateEnvironment(oAuth2Configuration);
    if (errors.list.length > 0) {
      return;
    }
    try {
      await customerService
        .saveEnvironmentOauth2Options(
          customer!._id,
          environment!._id,
          oAuth2Configuration as EnvOauth2OptionsDto
        )
        .then(() => {
          notify.success(`Successfully saved OAuth2 Configuration`);
        });
    } catch (message) {
      notify.error({ message });
    }
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const newState: EnvOauth2OptionsDto = {
      ...oAuth2Configuration,
      [event.target.name!]: event.target.value,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(oAuth2Configuration));
    setOAuth2Configuration(newState);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const renderLoginUrlField = (): ReactElement => (
    <RiverTextInput
      id="login_url"
      label="Login Url"
      value={oAuth2Configuration.login_url}
      className={sharedStyles.input}
      fullWidth
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["login_url"]}
    />
  );
  const renderReplyUrlField = (): ReactElement => (
    <RiverTextInput
      id="reply_url"
      label="Reply Url"
      value={oAuth2Configuration.reply_url}
      className={sharedStyles.input}
      fullWidth
      disabled
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["reply_url"]}
    />
  );
  const renderTokenUrlField = (): ReactElement => (
    <RiverTextInput
      id="token_url"
      label="Token Url"
      value={oAuth2Configuration.token_url}
      fullWidth
      className={sharedStyles.input}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["token_url"]}
    />
  );
  const renderScopeField = (): ReactElement => (
    <RiverTextInput
      id="scope"
      label="Scope"
      value={oAuth2Configuration.scope}
      fullWidth
      className={sharedStyles.input}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["scope"]}
    />
  );
  const renderGrantTypeField = (): ReactElement => (
    <RiverTextInput
      id="grant_type"
      label="Grant Type"
      value={oAuth2Configuration.grant_type}
      fullWidth
      className={sharedStyles.input}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["grant_type"]}
    />
  );
  const renderCliendIdField = (): ReactElement => (
    <RiverTextInput
      id="client_id"
      label="Client ID"
      value={oAuth2Configuration.client_id}
      fullWidth
      className={sharedStyles.input}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["client_id"]}
    />
  );
  const renderClientSecretField = (): ReactElement => (
    <RiverPasswordInput
      id="client_secret"
      label="Client Secret"
      value={oAuth2Configuration.client_secret}
      fullWidth
      className={sharedStyles.input}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["client_secret"]}
      autoComplete={false}
    />
  );

  return (
    <>
      <PageHeader title={"Environments"} />
      <SubHeader subtitle={`${environmentHandle!} - OAuth2 Configuration`} />
      <Box className={sharedStyles.viewContent}>
        <div className={sharedStyles.form}>
          {renderLoginUrlField()}
          {renderReplyUrlField()}
          {renderTokenUrlField()}
          {renderScopeField()}
          {renderGrantTypeField()}
          {renderCliendIdField()}
          {renderClientSecretField()}
          <div className={sharedStyles.buttonGroup}>
            <RiverDialogButton
              text="Save"
              variant="contained"
              className={sharedStyles.button}
              onClick={saveEnvironmentOauth2Options}
            />
          </div>
        </div>
      </Box>
      <RiverSpinner show={isLoading} />
    </>
  );
};
