import { IsNotEmpty, IsIn, IsArray, ValidateNested, IsOptional  } from 'class-validator';
import { Type } from 'class-transformer';

import { Constants } from '@river/constants';
import { IndexAttributeDto } from './index-attribute.dto';

// --------------
export class IndexDto {

  @IsNotEmpty()
  index_name: string;

  @IsNotEmpty()
  @IsIn(Object.values(Constants.index_type))
  index_type: string = Constants.index_type.regular;

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => IndexAttributeDto)
  fields: IndexAttributeDto[];

  @IsOptional()
  options?: any;

}