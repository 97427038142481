import React, { ReactElement } from "react";
import styles from "./sub-header.module.scss";

interface ISubHeaderProps {
  subtitle: string;
}

export const SubHeader: React.FC<ISubHeaderProps> = (props): ReactElement => {
  return (
    <div className={styles.root}>
      <div className={styles.subtitle}>{props.subtitle}</div>
    </div>
  );
};
