import { jsx as _jsx } from "react/jsx-runtime";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styles from "./river-checkbox.module.scss";
import clsx from "clsx";
import { useFormField } from "../../../hooks";
export const RiverCheckbox = (props) => {
    const { label, checked, disabled, onChange } = useFormField(props);
    return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: checked, onChange: onChange, name: props.id, color: "primary", classes: {
                root: styles.checkBox,
            } }), label: label, classes: {
            root: clsx([
                styles.formControl,
                props.className,
                { [styles.smallerIcon]: !!props.smallerIcon },
            ]),
            label: styles.label,
        }, disabled: disabled }));
};
