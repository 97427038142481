import { IsOptional, IsArray, ArrayMinSize, IsNotEmpty } from "class-validator";
import { IAdapterAutoDependenciesAction } from "./adapter-auto-dependencies-action.interface";

export class AdapterAutoDependenciesActionDto
  implements IAdapterAutoDependenciesAction
{
  @IsOptional()
  folder_id?: string | null;

  @IsArray()
  @ArrayMinSize(1)
  operation_ids: string[];
}
