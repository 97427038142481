import { IsOptional, IsArray, ValidateNested } from "class-validator";
import { Type } from "class-transformer";

import { IQuery } from "./query.interface";
import { QueryAttributeSortDto } from "./query-attribute-sort.dto";
import { QuerySimpleDto } from "./query-simple.dto";
import { QueryAttributeGroupDto } from "./query-attribute-group.dto";

export class QueryDto extends QuerySimpleDto implements IQuery {
  // @IsOptional()
  // connector?: TQueryAttributeOperator = "$and";

  @IsOptional()
  @ValidateNested()
  @Type(() => QueryAttributeGroupDto)
  query?: QueryAttributeGroupDto;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => QueryAttributeSortDto)
  sort?: QueryAttributeSortDto[];

  @IsOptional()
  @IsArray()
  $pipeline?: any[];
}
