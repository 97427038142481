import React, { ReactElement, useEffect, useState } from "react";
import { LogoutIcon } from "@river/common-ui";
import { IAccessTokenPayload } from "@river/interfaces";
import { Box, Popover, PopoverOrigin, Tabs, Tab, Button } from "@mui/material";
import { RiverLogoAccount } from "../river-logo-account";
import { ChangeEmail } from "./change-email";
import { ChangePassword } from "./change-password";
import { PlatformUserContext } from "../../context";
import { useEnv, IUseEnv } from "@river/common-ui";
import styles from "./user-account-popup.module.scss";
import clsx from "clsx";

const DEFAULT_POPOVER_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const DEFAULT_POPOVER_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

interface IUserAccountPopupProps {
  open: boolean;
  anchorEl: HTMLElement;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  onClose: () => void;
}

enum EAccountTab {
  CHANGE_EMAIL = "CHANGE_EMAIL",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
}

interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const UserAccountPopup: React.FC<IUserAccountPopupProps> = (
  props
): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const userContext = React.useContext(PlatformUserContext);
  const user: IAccessTokenPayload = userContext?.user!;
  const displayName: string = user?.display_name || "";
  const email: string = user?.user_id || "";
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorOrigin, setAnchorOrigin] = useState<PopoverOrigin>(
    DEFAULT_POPOVER_ANCHOR_ORIGIN
  );
  const [transformOrigin, setTransformOrigin] = useState<PopoverOrigin>(
    DEFAULT_POPOVER_TRANSFORM_ORIGIN
  );
  const [selectedTab, setSelectedTab] = useState<EAccountTab | false>(false);

  useEffect(() => {
    setAnchorEl(props.anchorEl!);
    setAnchorOrigin(props.anchorOrigin || DEFAULT_POPOVER_ANCHOR_ORIGIN);
    setTransformOrigin(
      props.transformOrigin || DEFAULT_POPOVER_TRANSFORM_ORIGIN
    );
    setIsOpen(props.open);
  }, [props.open, props.anchorEl, props.anchorOrigin, props.transformOrigin]);

  const AccountInfo = (): ReactElement => (
    <Box className={styles.accountInfo}>
      <div className={styles.displayName}>{displayName}</div>
      <div className={styles.email}>{email}</div>
    </Box>
  );

  const onTabChange = (event: object, value: EAccountTab): void => {
    setSelectedTab(value);
  };

  const onClose = (event: object, reason: string): void => {
    closeDialog();
  };

  const closeDialog = (): void => {
    setSelectedTab(false);
    props.onClose();
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin!}
      transformOrigin={transformOrigin!}
      classes={{ paper: clsx([styles.paper, { [styles.mobile]: isMobile }]) }}
      /*sx={dynamicPopupStyles}*/
    >
      <Box className={styles.topSection}>
        <RiverLogoAccount className={styles.logo} />
        <Box className={styles.topContent}>
          <AccountInfo />
          <Tabs
            value={selectedTab}
            onChange={onTabChange}
            indicatorColor="primary"
            variant="fullWidth"
            aria-label="user dialog tabs"
            classes={{
              root: styles.tabs,
              flexContainer: styles.flexContainer,
              indicator: styles.indicator,
            }}
          >
            <Tab
              label="Change e-mail"
              value={EAccountTab.CHANGE_EMAIL}
              classes={{ root: styles.tab }}
              {...a11yProps(EAccountTab.CHANGE_EMAIL)}
            />
            <Tab
              label="Change Password"
              value={EAccountTab.CHANGE_PASSWORD}
              classes={{ root: styles.tab }}
              {...a11yProps(EAccountTab.CHANGE_PASSWORD)}
            />
          </Tabs>
          {!selectedTab && (
            <Button
              className={styles.logout}
              href="/Logout"
              color="primary"
              startIcon={<LogoutIcon />}
            >
              Logout
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <TabPanel value={selectedTab} index={EAccountTab.CHANGE_EMAIL}>
          <ChangeEmail onCancel={() => setSelectedTab(false)} />
        </TabPanel>
        <TabPanel value={selectedTab} index={EAccountTab.CHANGE_PASSWORD}>
          <ChangePassword onCancel={() => setSelectedTab(false)} />
        </TabPanel>
      </Box>
    </Popover>
  );
};
