import { IsNotEmpty, IsIn } from 'class-validator';

import { Constants } from '@river/constants';

// ----------------
export class IndexAttributeDto {

  @IsNotEmpty()  
  attribute_name: string;

  @IsNotEmpty()
  @IsIn(Object.values(Constants.index_sort_type))
  sort_type: string;
}