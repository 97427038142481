import React, { ReactElement } from "react";
import { FormatterProps } from "react-data-grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import clsx from "clsx";
import styles from "./user-status-cell-formatter.module.scss";

const Active: React.FC = (): ReactElement => {
  return (
    <div className={clsx([styles.content, styles.active])}>
      <CheckCircleOutlineIcon className={styles.icon} />
      <span className={styles.text}>{"Active"}</span>
    </div>
  );
};

/*const Blocked: React.FC = (): ReactElement => {
  return (
    <div className={clsx([styles.content, styles.blocked])}>
      <CancelIcon className={styles.icon} />
      <span className={styles.text}>Blocked</span>
    </div>
  );
};*/

export const UserStatusCellFormatter: React.FC<FormatterProps<any>> = (
  props
) => {
  const renderStatus = (): ReactElement => <Active />;
  return <div className={styles.root}>{renderStatus()}</div>;
};
