import React, {
  useState,
  ReactElement,
  SyntheticEvent,
  useEffect,
} from "react";
import { Box, Container, Grid, Link } from "@mui/material";
import { AuthDto, IAccessTokenPayload } from "@river/interfaces";
import { Config } from "@river/config";
import {
  authService,
  ForgotPassword,
  RiverPasswordInput,
  RiverTextInput,
  IValidationErrors,
  useValidation,
  IUseValidation,
  getErrorMessageFromObject,
  PrimaryButton,
  IUseEnv,
  useEnv,
} from "@river/common-ui";

import defaultBanner from "./login-banner-new.jpg";
import defaultLogo from "./rhine-river-logo.png";

import { useNavigate, useLocation } from "react-router";
import Turnstile, { useTurnstile } from "react-turnstile";

import styles from "./platform-login.module.scss";
import clsx from "clsx";

type PlatformLoginRouteState = {
  error: string;
  noPass: boolean;
};

interface IPlatformLoginProps {
  apiUrl: string;
  bannerUrl?: string;
  logoUrl?: string;
  onLoggedIn: (userInfo: IAccessTokenPayload | null) => void;
}

export const PlatformLogin: React.FC<IPlatformLoginProps> = (props) => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const validation: IUseValidation = useValidation();
  const [auth, setAuth] = useState<AuthDto>(new AuthDto());
  const [isLoginError, setIsLoginError] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });

  const turnstile = useTurnstile();
  const [captchaVerified, setCaptchaVerified] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [isOpenForgotPassword, setIsOpenForgotPassword] =
    useState<boolean>(false);

  const routeState: PlatformLoginRouteState = (location.state ||
    {}) as PlatformLoginRouteState;
  const [message, setMessage] = useState<string>(routeState.error || "");
  const [isRouteStateError, setIsRouteStateError] = useState<boolean>(
    !!routeState.error
  );

  useEffect(() => {
    if (isProcessing) {
      setIsRouteStateError(false);
      setMessage("Authenticating...");
    }
    // eslint-disable-next-line
  }, [isProcessing]);

  useEffect(() => {
    if (isAuthenticated() && !routeState.noPass) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPropertyChange: React.ChangeEventHandler<HTMLInputElement> = async ({
    currentTarget: input,
  }): Promise<void> => {
    const newAuth: AuthDto = {
      ...auth,
      [input.name]: input.value,
    };
    Object.setPrototypeOf(newAuth, Object.getPrototypeOf(auth));
    setAuth(newAuth);

    const newErrors: IValidationErrors = await validation.validateProperty(
      newAuth,
      input.name,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const handleLogin = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const errors: IValidationErrors = await validation.validateFields(auth);
    setValidationErrors(errors);

    if (errors.list.length > 0) {
      return;
    }

    try {
      setIsProcessing(true);
      setIsLoginError(false);
      const user = await authService.login(props.apiUrl, auth);
      props.onLoggedIn(user);
      const { state } = location as any;
      const redirectTo = state?.from?.pathname || "/";
      navigate(redirectTo, { replace: true });
    } catch (error) {
      setIsProcessing(false);
      const msg: string = getErrorMessageFromObject({ message: error });
      setIsLoginError(true);
      setMessage(msg);
    }
  };

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const isAuthenticated = (): boolean => !!authService.getCurrentUser();

  const getBannerUrl = (): string => defaultBanner;
  const renderBanner = (): ReactElement => (
    <div
      className={styles.banner}
      style={{ backgroundImage: `url(${getBannerUrl()})` }}
    />
  );

  const getLogoUrl = (): string => defaultLogo;
  const renderLogo = (): ReactElement => (
    <img src={getLogoUrl()} className={styles.logo} alt="Rhine River Logo" />
  );

  const renderLandingMessage = (): ReactElement => (
    <div className={styles.landingMessage}>
      {"Please login to your account."}
    </div>
  );

  const renderProcessInfo = (): ReactElement => {
    if (!message) return <></>;
    return (
      <div className={styles.infoContainer}>
        <span
          className={clsx([
            styles.processInfo,
            {
              [styles.error]: isLoginError || isRouteStateError,
            },
            { [styles.authenticating]: isProcessing },
          ])}
        >
          {message}
        </span>
      </div>
    );
  };

  const renderEmailAdressField = (): ReactElement => (
    <RiverTextInput
      className={styles.username}
      id={"username"}
      value={auth.username}
      onChangeEvent={(event) => onPropertyChange(event)}
      label={"User Name"}
      fullWidth
      disabled={isProcessing}
      validationErrors={validationErrors?.fields["username"]}
    />
  );

  const renderPasswordField = (): ReactElement => (
    <RiverPasswordInput
      id={"password"}
      value={auth.password}
      label={"Password"}
      onChangeEvent={(event) => onPropertyChange(event)}
      className={styles.password}
      fullWidth
      disabled={isProcessing}
      validationErrors={validationErrors?.fields["password"]}
    />
  );

  const openForgotPasswordDialog = (event: SyntheticEvent): void => {
    preventDefault(event);
    setIsOpenForgotPassword(true);
  };

  const handleCloseForgotPasswordDialog = (): void => {
    setIsOpenForgotPassword(false);
  };

  const renderAuthenticationControls = (): ReactElement => (
    <Box className={styles.authenticationControls}>
      <Link
        href="#"
        onClick={openForgotPasswordDialog}
        className={`${styles.loginLabel} ${styles.forgotPassword}`}
      >
        {"Forgot Password?"}
      </Link>
    </Box>
  );
  const renderMainButtons = (): ReactElement => (
    <Box className={styles.mainButtons}>
      <PrimaryButton
        text={"LOGIN"}
        type="submit"
        disabled={isProcessing}
        className={styles.mainButton}
      />
    </Box>
  );

  const renderLoginForm = (): ReactElement => (
    <form onSubmit={handleLogin} autoComplete="off">
      {renderEmailAdressField()}
      {renderPasswordField()}
      {renderAuthenticationControls()}
      {renderProcessInfo()}
      {renderMainButtons()}
    </form>
  );

  const renderCaptcha = (): ReactElement => {
    return (
      <Turnstile
        sitekey={Config.web.REACT_APP_CAPTCHA_SITE_KEY}
        onVerify={(token) => {
          setCaptchaVerified(true);
        }}
        onError={() => turnstile.reset()}
      />
    );
  };

  const renderForgotPasswordDialog = (): ReactElement => (
    <ForgotPassword
      open={isOpenForgotPassword}
      onClose={handleCloseForgotPasswordDialog}
    />
  );

  const render = (): ReactElement => {
    return (
      <>
        <Container
          maxWidth="lg"
          classes={{
            root: clsx([styles.loginContainer, { [styles.mobile]: isMobile }]),
          }}
        >
          <Grid container style={{ flexWrap: "nowrap" }}>
            <Grid item xs={false} sm={6}>
              {renderBanner()}
            </Grid>
            <Grid item xs={12} sm={6} className={styles.rightGrid}>
              <Box
                className={styles.flexWrapper}
                minWidth={{ xs: "auto", sm: "400px" }}
                maxWidth={{ xs: "100%", sm: "514px" }}
                padding={{ xs: "35px 15px 0", sm: "150px 0 0 0" }}
              >
                <Box
                  className={styles.formContainer}
                  maxWidth="328px"
                  width={{ xs: "100%", sm: "328px" }}
                >
                  {renderLogo()}
                  {renderLandingMessage()}
                  {captchaVerified ? renderLoginForm() : renderCaptcha()}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {renderForgotPasswordDialog()}
      </>
    );
  };

  return render();
};
