import { IsNotEmpty, IsIn, Matches } from "class-validator";

import { Constants } from "@river/constants";
import { ICustomer } from "./customer.interface";
import { StatusDto } from "../common";
//import { CustomerModuleDto } from './customer-module.dto';

export class CustomerDto
  implements Pick<ICustomer, "handle" | "description" | "adapter_type">
{
  @IsNotEmpty()
  @Matches(new RegExp("^([\\p{Ll}0-9]){2,30}$", "u"), {
    message:
      "Handle must contain between 2 and 30 lowercase letters or numbers, no special characters allowed",
  })
  handle: string = "";

  @IsNotEmpty()
  description: string = "";

  @IsNotEmpty()
  @IsIn(Object.values(Constants.adapter_type)) //sap | maximo | ...
  adapter_type: string = "";

  // @IsOptional()
  // @IsArray()
  // @ValidateNested()
  // modules?: CustomerModuleDto[];
  //adapter_modules?: string[] = [];

  status: StatusDto = {
    code: Constants.customer_status.active,
  };

  @IsNotEmpty()
  cluster_id: string = "";
}
