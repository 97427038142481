import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputLabel, OutlinedInput, FormControl, } from "@mui/material";
import { useFormField } from "../../../hooks";
import { RiverFormFieldError } from "../river-form-field-error";
import styles from "./river-text-input.module.scss";
import clsx from "clsx";
export const RiverTextInput = (props) => {
    const { label, value, disabled, onChange, hasErrors } = useFormField(props);
    const { multiline, rows, inputRef, name, fullWidth, id, inputProps, className, labelProps, hideBlank, description, descriptionClassName, } = props;
    const shouldRender = () => {
        return !(hideBlank && value === "");
    };
    return (_jsx(_Fragment, { children: shouldRender() && (_jsxs(FormControl, { variant: "outlined", className: clsx([
                styles.root,
                className,
                { [styles.error]: hasErrors },
            ]), error: hasErrors, fullWidth: fullWidth, disabled: disabled, children: [_jsx(InputLabel, Object.assign({ htmlFor: id }, labelProps, { children: label })), _jsx(OutlinedInput, Object.assign({ multiline: multiline, rows: rows, inputRef: inputRef, classes: { root: styles.outlinedInput }, id: id, name: name || id, type: "text", value: value, onChange: onChange, "aria-describedby": `${id}-helper-text`, label: label }, inputProps)), _jsx(RiverFormFieldError, { id: id }), description && (_jsx("span", { className: clsx([descriptionClassName, styles.input_description]), children: description }))] })) }));
};
