import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, RowsChangeData } from "react-data-grid";

import { IEnvironment } from "@river/interfaces";
import { EnvStatusCellFormatter } from "../river-data-grid";
import {
  PrimaryButton,
  RiverSpinner,
  useNotification,
  useSimpleDialog,
} from "@river/common-ui";
import { customerService } from "../../services";
import { PageHeader } from "../page-header";
import { RiverDataGrid, tooltipCell } from "../river-data-grid";
import { AddEnvironmentDialog } from "../add-environment-dialog";
import { Box } from "@mui/material";
import { SidebarContext } from "../sidebar";
import { getCustomerSidebarContent } from "../customer/sidebar-content";
import { Config } from "@river/config";
import { CustomerContext } from "../customer";
import { SubHeader } from "../sub-header";
import sharedStyles from "../shared-styles/shared-styles.module.scss";

export const CustomerEnvironments: React.FC = () => {
  const notify = useNotification();
  const { customer } = useContext(CustomerContext)!;
  const [environments, setEnvironments] = useState<IEnvironment[]>([]);
  const [addEnvironmentDialogOpened, setAddEnvironmentDialogOpened] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const sidebarContext = useContext(SidebarContext);
  const navigate = useNavigate();

  useEffect(() => {
    sidebarContext?.setSidebarContent(getCustomerSidebarContent(customer!));
    loadEnvironments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadEnvironments = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await customerService.getEnvironments(customer!._id);
      setEnvironments(result);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const columns: Column<any>[] = [
    {
      key: "handle",
      name: "Handle",
      formatter: (row) => (
        <div
          className={sharedStyles.dataGridLink}
          onClick={() => {
            navigate(
              `/customers/${customer!._id}/environments/${row.row.handle}`
            );
          }}
        >
          {tooltipCell(row.row[row.column.key])}
        </div>
      ),
    },
    {
      key: "authentication",
      name: "Authentication",
    },
    {
      key: "url",
      name: "URL",
      formatter: (row) => (
        <div
          className={sharedStyles.dataGridLink}
          onClick={() => {
            window.open(
              `https://${row.row.handle}-${customer!.handle}.${
                Config.web.REACT_APP_ADAPTER_WEB_DOMAIN
              }`
            );
          }}
        >
          {tooltipCell(
            `${row.row.handle}-${customer!.handle}.${
              Config.web.REACT_APP_ADAPTER_WEB_DOMAIN
            }`
          )}
        </div>
      ),
    },
    {
      key: "status",
      name: "Status",
      formatter: (props) => <EnvStatusCellFormatter {...props} />,
    },
  ];

  const onRowsChange = (
    rows: IEnvironment[],
    data: RowsChangeData<any, any>
  ) => {
    setEnvironments(rows);
  };

  const openAddEnvironmentDialog = (): void =>
    setAddEnvironmentDialogOpened(true);
  const closeAddEnvironmentDialog = (): void =>
    setAddEnvironmentDialogOpened(false);

  const deleteEnvironmentConfirmationDialog = useSimpleDialog({
    title: "Delete Environment",
    message: "Are you sure you want to delete the selected environment?",
    confirmButtonText: "Delete",
  });

  const renderAddEnvironmentButton = (): ReactElement => (
    <PrimaryButton
      onClick={openAddEnvironmentDialog}
      text={"ADD ENVIRONMENT"}
    />
  );
  return (
    <>
      <PageHeader title={"Environments"} />
      <SubHeader subtitle={customer?.description!} />
      <Box className={sharedStyles.primaryButtons}>
        {renderAddEnvironmentButton()}
      </Box>
      <Box className={sharedStyles.viewContent}>
        <RiverDataGrid
          className={sharedStyles.dataGrid}
          columns={columns}
          rows={environments}
          onRowsChange={onRowsChange}
        />
      </Box>
      <RiverSpinner show={isLoading} />

      <AddEnvironmentDialog
        open={addEnvironmentDialogOpened}
        customer={customer!}
        onClose={(success) => {
          closeAddEnvironmentDialog();
          if (success) loadEnvironments();
        }}
      />
      {deleteEnvironmentConfirmationDialog.render()}
    </>
  );
};
