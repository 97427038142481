import React, { useState, useEffect, useContext, ReactElement } from "react";
import { Column, RowsChangeData } from "react-data-grid";
import { IPlatformUser } from "@river/interfaces";
import { customerService } from "../../services";
import { AddUserDialog } from "../add-user-dialog";
import { PageHeader } from "../page-header";
import { Box } from "@mui/material";
import {
  RiverDataGrid,
  UserStatusCellFormatter,
  UserEmailCellFormatter,
} from "../river-data-grid";
import {
  PrimaryButton,
  RiverSpinner,
  useNotification,
  useSimpleDialog,
} from "@river/common-ui";
import { CustomerContext } from "../customer";
import { SubHeader } from "../sub-header";
import sharedStyles from "../shared-styles/shared-styles.module.scss";

export const CustomerUsers: React.FC = () => {
  const notify = useNotification();
  const { customer } = useContext(CustomerContext)!;

  const [users, setUsers] = useState<IPlatformUser[]>([]);
  const [addUserDialogOpened, setAddUserDialogOpened] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsers = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await customerService.getUsers(customer!._id);
      setUsers(result);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const columns: Column<any>[] = [
    {
      key: "status",
      name: "Status",
      formatter: (props) => <UserStatusCellFormatter {...props} />,
    },
    { key: "display_name", name: "Name" },
    {
      key: "email",
      name: "e-mail",
      formatter: (props) => (
        <UserEmailCellFormatter
          {...props}
          editUser={editUser}
          blockUser={blockUser}
          deleteUser={deleteUser}
          reinviteUser={reinviteUser}
        />
      ),
    },
  ];

  const onRowsChange = (
    rows: IPlatformUser[],
    data: RowsChangeData<any, any>
  ) => {
    setUsers(rows);
  };

  const openAddUserDialog = (): void => setAddUserDialogOpened(true);
  const closeAddUserDialog = (): void => setAddUserDialogOpened(false);

  const refresh = (): void => {
    getUsers();
  };

  const editUser = (user: IPlatformUser): void => {
    console.log(`editing user ${user.display_name}`);
  };

  const deleteUser = (user: IPlatformUser): void => {
    deleteUserConfirmationDialog.open();
    console.log(`deleting user ${user.display_name}`);
  };

  const blockUser = (user: IPlatformUser): void => {
    console.log(`blocking user ${user.display_name}`);
  };

  const reinviteUser = (user: IPlatformUser): void => {
    console.log(`reinviting user ${user.display_name}`);
  };

  const deleteUserConfirmationDialog = useSimpleDialog({
    title: "Delete User",
    message: "Are you sure you want to delete the selected user?",
    confirmButtonText: "Delete",
  });

  const renderAddUserButton = (): ReactElement => (
    <PrimaryButton text={"Add user"} onClick={openAddUserDialog} />
  );

  return (
    <>
      <PageHeader title={"Users"} />
      <SubHeader subtitle={customer?.description!} />
      <Box className={sharedStyles.primaryButtons}>{renderAddUserButton()}</Box>
      <Box className={sharedStyles.viewContent}>
        <RiverDataGrid
          className={sharedStyles.dataGrid}
          columns={columns}
          rows={users}
          onRowsChange={onRowsChange}
        />
      </Box>
      <RiverSpinner show={isLoading} />
      <AddUserDialog
        customer={customer!}
        open={addUserDialogOpened}
        onClose={(success) => {
          closeAddUserDialog();
          if (success) refresh();
        }}
      />
      {deleteUserConfirmationDialog.render()}
    </>
  );
};
