import { IsNotEmpty } from 'class-validator';

import { IQueryAttributeSort, TQueryAttributeSort } from './query-attribute-sort.interface';

export class QueryAttributeSortDto implements IQueryAttributeSort {

  @IsNotEmpty()
  attribute_name: string;

  @IsNotEmpty()
  sort: TQueryAttributeSort = 'asc';
}