import { IsNotEmpty, IsOptional, Matches } from "class-validator";

import { Constants } from "@river/constants";
import { IProperty } from "../common";

export class EnvironmentDto {
  @IsNotEmpty()
  @Matches(new RegExp("^([\\p{Ll}0-9]){2,30}$", "u"), {
    message:
      "Handle must contain between 2 and 30 lowercase letters or numbers, no special characters allowed",
  })
  handle: string = "";

  @IsOptional()
  description?: string = "";

  @IsOptional()
  authentication?: string = Constants.authentication_type.basic;

  @IsOptional()
  options?: IProperty[] = [
    {
      name: "sap-username",
      value: "",
    },
    // {
    //   name: "sap-system",
    //   value: "",
    // },
    {
      name: "sap-client",
      value: "",
    },
    {
      name: "sap-webgui-url",
      value: "",
    },
  ];

  // Mobile application Id as configured in MongoDb Atlas Application Services
  @IsOptional()
  mobile_app_id?: string = "";
  // , {
  //   name: 'sap-client',
  //   value: ''
  // }, {
  //   name: 'sap-base-url',
  //   value: ''
  // }];
}
