import { ICustomer, IEnvironment } from "@river/interfaces";
import { ISidebarContent } from "../sidebar";

export const getEnvironmentSidebarContent = (
  customer: ICustomer,
  environment: IEnvironment
): ISidebarContent => {
  return {
    parent: {
      primary: `${environment?.handle}`,
      to: `/customers/${customer?._id}/environments`,
    },
    items: [
      {
        primary: "Details",
        to: `/customers/${customer._id}/environments/${environment?.handle}/details`,
      },
      {
        primary: "SAML2 Configuration",
        to: `/customers/${customer._id}/environments/${environment?.handle}/saml2_configuration`,
      },
      {
        primary: "OAuth2 Configuration",
        to: `/customers/${customer._id}/environments/${environment?.handle}/oauth2_configuration`,
      },
      {
        primary: "API Keys",
        to: `/customers/${customer._id}/environments/${environment?.handle}/api_keys`,
      },
      {
        primary: "Mobile",
        to: `/customers/${customer._id}/environments/${environment?.handle}/mobile`,
      },
    ],
  };
};
