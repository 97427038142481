import { IsNotEmpty } from "class-validator";

import { IAdapterAutoScheduleAction } from "./adapter-auto-schedule-action.interface";

export class AdapterAutoScheduleActionDto
  implements IAdapterAutoScheduleAction
{
  @IsNotEmpty()
  folder_id: string;
}
