import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputLabel, OutlinedInput, FormControl, IconButton, FormHelperText, } from "@mui/material";
import { useState } from "react";
import styles from "./river-password-input.module.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import clsx from "clsx";
import { useValidation } from "../use-validation";
const DEFAULT_AUTOCOMPLETE = true;
export const RiverPasswordInput = (props) => {
    const [value, setValue] = useState(String(props.value));
    const [showPassword, setShowPassword] = useState(false);
    const validation = useValidation();
    const getInputProps = () => {
        var _a;
        const autoComplete = (_a = props.autoComplete) !== null && _a !== void 0 ? _a : DEFAULT_AUTOCOMPLETE;
        const inputProps = Object.assign({}, Object.assign({}, props.inputProps, !autoComplete
            ? {
                value: props.value,
                autoComplete: "new-password",
            }
            : {}));
        return inputProps;
    };
    const onValueChange = (event) => {
        const input = event.currentTarget;
        const value = input.value;
        setValue(value);
        if (props.onChange) {
            props.onChange(value);
        }
        if (props.onChangeEvent) {
            props.onChangeEvent(event);
        }
    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const formatHelperText = () => {
        const validationMessage = validation.getFieldValidationMessage(props.validationErrors);
        let formatted = props.helperText || validationMessage;
        return formatted;
    };
    const hasValidationErrors = () => {
        var _a;
        let val = false;
        const errors = (_a = props.validationErrors) === null || _a === void 0 ? void 0 : _a.errors;
        if (errors) {
            val = errors.length > 0;
        }
        return val;
    };
    const isErrorState = () => props.error || hasValidationErrors();
    return (_jsxs(FormControl, { variant: "outlined", className: clsx([styles.root, props.className]), error: isErrorState(), fullWidth: props.fullWidth, disabled: props.disabled, children: [_jsx(InputLabel, { htmlFor: props.id, children: props.label }), _jsx(OutlinedInput, Object.assign({ classes: { root: styles.outlinedInput, input: styles.input }, id: props.id, type: showPassword ? "text" : "password", name: props.name || props.id, value: value, onChange: onValueChange, label: props.label, "aria-describedby": `${props.id}-helper-text` }, getInputProps())), _jsx(IconButton, { className: styles.passwordVisibilityToggle, "aria-label": "toggle password visibility", onClick: handleClickShowPassword, disabled: props.disabled, size: "large", children: showPassword ? _jsx(Visibility, {}) : _jsx(VisibilityOff, {}) }), _jsx(FormHelperText, { id: `${props.id}-helper-text`, classes: { root: styles.helperText }, children: formatHelperText() })] }));
};
