import React, { ReactElement } from "react";
import { RiverSelect, IRiverSelectProps } from "@river/common-ui";
import styles from "./river-platform-select.module.scss";

export const RiverPlatformSelect: React.FC<IRiverSelectProps> = (
  props
): ReactElement => {
  const platformSelectProps: IRiverSelectProps = Object.assign({
    ...props,
    classes: {
      select: {
        select: styles.select,
        dropdownIcon: styles.dropdownIcon,
      },
      menu: {
        paper: styles.menu,
        list: styles.list,
      },
      menuItem: {
        root: styles.item,
        selected: styles.selected,
      },
    },
  });
  return <RiverSelect {...platformSelectProps} />;
};
