import { IsNotEmpty, ValidateIf } from "class-validator";

import {
  IQueryAttributeValue,
  TQueryOperator,
} from "./query-attribute-value.interface";

export class QueryAttributeValueDto implements IQueryAttributeValue {
  @IsNotEmpty()
  operator: TQueryOperator = "$eq";

  @IsNotEmpty()
  @ValidateIf((object, value) => value !== null)
  value: any | null;
}
