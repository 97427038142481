import {
  IsNotEmpty,
  IsOptional,
  IsArray,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";

import { AdapterCalendarDay } from "./adapter-calendar-day.dto";
import { IAdapterCalendar } from "./adapter-calendar.interface";

export class AdapterCalendarDto implements Omit<IAdapterCalendar, "_id"> {
  @IsNotEmpty()
  calendar: string;

  @IsNotEmpty()
  description: string;

  @IsOptional()
  reference_date?: Date | null;

  // @IsNotEmpty()
  // @IsInt()
  // @Min(0)
  // @Max(6)
  // week_start: number; // 0 = Sunday

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AdapterCalendarDay)
  template: AdapterCalendarDay[];

  // Other ERP specific attributes
  [s: string]: any;
}
