import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { Protected } from "../index";
import { clusterService } from "../../services";
import { ICluster } from "@river/interfaces";
import { ClusterDetails } from "./cluster-details";
import { ClusterOptions } from "./cluster-options";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { ClusterContext } from "./cluster.context";
import { getClusterSidebarContent } from "./sidebar-content";
import { SidebarContext } from "../sidebar";

export const Cluster: React.FC = () => {
  const clusterId: string = useParams().clusterId!;
  const sidebarContext = useContext(SidebarContext);

  const notify = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cluster, setCluster] = useState<ICluster | null>(null);

  const loadCluster = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const cluster = await clusterService.getCluster(clusterId);
      setCluster(cluster);
      sidebarContext?.setSidebarContent(getClusterSidebarContent(cluster));
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadCluster();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClusterContext.Provider
      value={{
        cluster,
        setCluster,
      }}
    >
      <RiverSpinner show={isLoading} />
      {cluster && (
        <Routes>
          <Route
            path={`/details`}
            element={
              <Protected>
                <ClusterDetails />
              </Protected>
            }
          />
          <Route
            path={`/options`}
            element={
              <Protected>
                <ClusterOptions />
              </Protected>
            }
          />
        </Routes>
      )}
    </ClusterContext.Provider>
  );
};
