import { IsNotEmpty, IsDate, IsOptional } from "class-validator";

export class AdapterAvailabilityUpdateDto {
  @IsOptional()
  shift_id?: string | null;

  @IsNotEmpty()
  available_hours: number;

  @IsNotEmpty()
  @IsDate()
  start_date: Date;

  @IsNotEmpty()
  @IsDate()
  end_date: Date;

  @IsNotEmpty()
  availability_header_id: string;
}
