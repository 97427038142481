import { IsEmail, IsNotEmpty } from 'class-validator';


export class PlatformUserDto {
  
  @IsEmail()
  email: string;

  @IsNotEmpty()
  password: string;
    
  first_name?: string;
  last_name?: string;

  @IsNotEmpty()
  display_name: string;
}