import {
  IsNotEmpty,
  IsOptional,
  IsArray,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";

import { QueryDto } from "../query";
import { IAdapterReportCreate } from "./adapter-report-create.interface";
import { IAdapterReportColumn } from "./adapter-report.interface";

import { Property } from "../common";

export class AdapterReportColumnDto implements IAdapterReportColumn {
  @IsOptional()
  label?: string;

  @IsNotEmpty()
  attribute: string;
}

export class AdapterReportCreateDto
  implements Omit<IAdapterReportCreate, "_id">
{
  @IsNotEmpty()
  report_type: string;

  @IsOptional()
  entity_name: string;

  @IsOptional()
  module: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => QueryDto)
  query?: QueryDto;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AdapterReportColumnDto)
  @IsArray()
  columns?: AdapterReportColumnDto[];

  @IsOptional()
  folder_id?: string;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Property)
  options?: Property[];
}
