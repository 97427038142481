import { IsNumber, IsNotEmpty } from "class-validator";

export class OracleEbsAdapterWorkorderUncompletionDto {
  @IsNotEmpty()
  _id: string;

  @IsNotEmpty()
  @IsNumber()
  STATUS_TYPE: number;
}
