import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "../../hooks";
import { withTranslation } from "react-i18next";
const NotFound = (props) => {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: props.tReady && (_jsx("div", { children: t("shared.routes:label.not_found", {
                defaultValue: "Not Found",
            }) })) }));
};
export default withTranslation(["shared.routes"])(NotFound);
