import React from "react";
import { Link } from "react-router-dom";

// ----------------
export interface IGridLinkFormatterProps {
  url: string;
  text: string;
  className?: string;
}

// ----------------
export const LinkCellFormatter: React.FC<IGridLinkFormatterProps> = (props) => {
  const { url, text, className } = props;
  return (
    <Link className={className} to={`${url}`}>
      {text}
    </Link>
  );
};
