import React from "react";
import { IAccessTokenPayload } from "@river/interfaces";

// --------------
export interface IPlatformUserContextState {
  user: IAccessTokenPayload;
  onLoggedIn: (userInfo: IAccessTokenPayload | null) => void;
}

export const PlatformUserContext =
  React.createContext<IPlatformUserContextState | null>(null);
