import { IsNotEmpty, IsArray, ValidateNested } from "class-validator";
import { Type } from "class-transformer";

import { AdapterCrewSupervisorDto } from "./adapter-crew-supervisor.dto";

import { IAdapterCrew } from "./adapter-crew.interface";

export class AdapterCrewDto implements Omit<IAdapterCrew, "_id"> {
  //
  @IsNotEmpty()
  crew: string = "";

  @IsNotEmpty()
  description: string = "";

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AdapterCrewSupervisorDto)
  supervisors: AdapterCrewSupervisorDto[] = [];

  // Other ERP specific attributes
  [s: string]: any;
}
