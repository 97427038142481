import React, { useState } from "react";
import { FormControl, IconButton, OutlinedInput } from "@mui/material";
import Search from "@mui/icons-material/Search";
import styles from "./river-search.module.scss";

export interface IRiverSearchProps {
  className?: string;
}

export const RiverSearch: React.FC<IRiverSearchProps> = (props) => {
  const [value, setValue] = useState<string>("");
  const onPropertyChange: React.ChangeEventHandler<HTMLInputElement> = async ({
    currentTarget: input,
  }): Promise<void> => {
    const value = input.value;
    setValue(value);
    console.log(`search: ${value}`);
  };

  return (
    <FormControl
      variant="outlined"
      className={props.className}
      classes={{ root: styles.root }}
    >
      <OutlinedInput
        type="text"
        value={value}
        onChange={onPropertyChange}
        inputProps={{ placeholder: "Search" }}
        classes={{
          input: styles.input,
          notchedOutline: styles.outlined,
          focused: styles.focused,
        }}
      />
      <IconButton
        className={styles.searchIcon}
        disableFocusRipple={true}
        disableRipple={true}
        size="large"
      >
        <Search />
      </IconButton>
    </FormControl>
  );
};
