import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, } from "@mui/material";
import { useFormField } from "../../../hooks";
import styles from "./river-form-select.module.scss";
import clsx from "clsx";
export const RiverFormSelect = (props) => {
    const { label, value, disabled, onChange, hasErrors, helperText } = useFormField(props);
    return (_jsxs(FormControl, Object.assign({ variant: "outlined", className: clsx([
            styles.root,
            props.className,
            { [styles.error]: hasErrors },
        ]), error: hasErrors, fullWidth: props.fullWidth, disabled: disabled }, props.formControlProps, { children: [_jsx(InputLabel, Object.assign({ id: props.id }, props.labelProps, { children: label })), _jsx(Select, Object.assign({ id: props.id, name: props.id, labelId: props.id, value: value, onChange: (event) => onChange(event), label: label, "aria-describedby": `${props.id}-helper-text`, MenuProps: Object.assign({ anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    }, transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    } }, props.menuProps) }, props.selectProps, { children: props.items.map((item, index) => (_jsx(MenuItem, { value: item.value, children: item.text }, index))) })), _jsx(FormHelperText, { id: `${props.id}-helper-text`, classes: { root: styles.helperText }, children: helperText })] })));
};
