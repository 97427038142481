import { validate } from "class-validator";
import { useTranslation } from "../../hooks";
const constraintOverrides = [
    {
        constraintKey: "isNotEmpty",
        translationKey: "shared.error_messages:validation.required_field",
        defaultTranslation: "This field is required",
    },
];
const constraintOverridesByKey = {};
constraintOverrides.forEach((override) => (constraintOverridesByKey[override.constraintKey] = override));
export function useValidation() {
    const { t } = useTranslation();
    const getFailedConstraints = (error) => {
        let failedConstraints = [];
        if (error.constraints) {
            failedConstraints = Object.entries(error.constraints).map(([constraint, message]) => {
                return { constraint, message };
            });
        }
        return failedConstraints;
    };
    const validateFields = async (object) => {
        const errors = await validate(object);
        return getValidationErrorsFromValidatedObject(errors);
    };
    const translateConstraints = (error) => {
        const getMessageKey = (message) => message.split("?")[0];
        const getMessageParams = (message) => {
            let messageParams = {};
            const paramStr = message.split("?")[1];
            if (paramStr) {
                const valuePairs = paramStr.split("&");
                valuePairs.forEach((pair) => {
                    const [key, value] = pair.split("=");
                    messageParams[key] = value;
                });
            }
            return messageParams;
        };
        const constraints = error.constraints || {};
        Object.keys(constraints).forEach((propName) => {
            const message = constraints[propName];
            const messageKey = getMessageKey(message);
            const messageParams = getMessageParams(message);
            constraints[propName] = t(messageKey, messageParams);
        });
    };
    const getValidationErrorsFromValidatedObject = (errors) => {
        const validationErrors = {
            fields: {},
            list: [],
        };
        errors.forEach((error) => {
            var _a;
            translateConstraints(error);
            validationErrors.fields[error.property] = {
                validation: error,
                errors: getFailedConstraints(error),
            };
            validationErrors.list.push(error);
            (_a = error.children) === null || _a === void 0 ? void 0 : _a.forEach((child, index) => {
                let childrenByIndex;
                childrenByIndex =
                    validationErrors.fields[error.property].childrenByIndex || {};
                validationErrors.fields[error.property].childrenByIndex =
                    childrenByIndex;
                childrenByIndex[child.property] =
                    getValidationErrorsFromValidatedObject(child.children);
            });
        });
        return validationErrors;
    };
    const formatConstraintMessage = (constraintKey, message) => {
        const override = constraintOverridesByKey[constraintKey];
        if (override) {
            const { translationKey: key, defaultTranslation: defaultValue } = override;
            message = t(key, { defaultValue });
        }
        return message;
    };
    const getFieldValidationMessage = (fieldValidationErrors) => {
        let validationMessage = "";
        const errors = fieldValidationErrors === null || fieldValidationErrors === void 0 ? void 0 : fieldValidationErrors.errors;
        if (errors && errors.length > 0) {
            let validation;
            validation = errors.find((error) => error.constraint === "isNotEmpty");
            if (!validation) {
                validation = errors[0];
            }
            const { constraint, message } = validation;
            validationMessage = formatConstraintMessage(constraint, message);
        }
        return validationMessage;
    };
    const validateProperty = async (dto, property, validationErrors) => {
        const errors = await validateFields(dto);
        const newErrors = Object.assign({}, validationErrors);
        const index = newErrors.list.findIndex((error) => error.property === property);
        if (errors.fields[property]) {
            newErrors.fields[property] = errors.fields[property];
            if (index !== -1) {
                newErrors.list.splice(index, 1, errors.fields[property].validation);
            }
            else {
                newErrors.list.push(errors.fields[property].validation);
            }
        }
        else {
            delete newErrors.fields[property];
            newErrors.list.splice(index, 1);
        }
        return newErrors;
    };
    return {
        validateFields,
        validateProperty,
        getFieldValidationMessage,
    };
}
