import BusinessIcon from "@mui/icons-material/Business";
import StorageIcon from "@mui/icons-material/Storage";
import { ISidebarContent } from "./sidebar";

export const RootSidebarContent: ISidebarContent = {
  items: [
    {
      icon: BusinessIcon,
      primary: "Companies",
      to: "/customers",
    },
    {
      icon: StorageIcon,
      primary: "DB Clusters",
      to: "/clusters",
    },
  ],
};
