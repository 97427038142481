import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback, } from "react";
import clsx from "clsx";
import { Dialog, Typography, IconButton, Button, Divider, } from "@mui/material";
import { useEnv } from "../../hooks";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import styles from "./river-dialog.module.scss";
import { useTranslation } from "../../hooks";
import { RiverFormContext } from "../../context";
const DEFAULT_SHOW_XCLOSE = true;
const DEFAULT_SHOW_ACTIONS_DIVIDER = true;
const DEFAULT_SHOW_TITLE_DIVIDER = false;
const DEFAULT_IS_DRAGGABLE_DESKTOP = true;
const DEFAULT_IS_DRAGGABLE_MOBILE = false;
export const RiverDialogButton = (props) => {
    return (_jsx(Button, Object.assign({ variant: "outlined", classes: { root: clsx([styles.button, styles.dimmed]) } }, props, { children: props.children || props.text })));
};
export const RiverDialogActionButton = (props) => (_jsx(Button, Object.assign({ classes: { root: clsx([styles.button, styles.action]) }, variant: "contained", color: "primary" }, props, { children: props.children || props.text })));
export const RiverDialog = (props) => {
    var _a;
    const { t } = useTranslation();
    const DEFAULT_CLOSE_BUTTON_TEXT = t("common.button:cancel", {
        defaultValue: "Cancel",
    });
    const env = useEnv();
    const { isMobile } = env;
    const [dragHandleId] = useState("d" + new Date().getTime());
    const [showXClose, setShowXClose] = useState(DEFAULT_SHOW_XCLOSE);
    const [isDraggable, setIsDraggable] = useState(isMobile ? DEFAULT_IS_DRAGGABLE_MOBILE : DEFAULT_IS_DRAGGABLE_DESKTOP);
    const [showActionsDivider, setShowActionsDivider] = useState(DEFAULT_SHOW_ACTIONS_DIVIDER);
    const [showTitleDivider, setShowTitleDivider] = useState(DEFAULT_SHOW_TITLE_DIVIDER);
    const DraggablePaper = useCallback((props) => (_jsx(Draggable, { handle: `#${dragHandleId}`, children: _jsx(Paper, Object.assign({}, props)) })), [dragHandleId]);
    useEffect(() => {
        setShowXClose(typeof props.showXClose !== "undefined"
            ? props.showXClose
            : DEFAULT_SHOW_XCLOSE);
        if (typeof props.isDraggable !== "undefined") {
            setIsDraggable(props.isDraggable);
        }
        setShowActionsDivider(typeof props.showActionsDivider !== "undefined"
            ? props.showActionsDivider
            : DEFAULT_SHOW_ACTIONS_DIVIDER);
        setShowTitleDivider(typeof props.showTitleDivider !== "undefined"
            ? props.showTitleDivider
            : DEFAULT_SHOW_TITLE_DIVIDER);
    }, [
        props.showXClose,
        props.showActionsDivider,
        props.showTitleDivider,
        props.isDraggable,
    ]);
    const handleClose = () => {
        props.onClose();
    };
    const handleSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit();
        }
    };
    const onClose = (event, reason) => {
        if (reason === "backdropClick") {
            props.closeOnBackdropClick && handleClose();
        }
        else if (reason === "escapeKeyDown" && !props.preventCloseOnEscape) {
            handleClose();
            setTimeout(() => {
                var _a;
                (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.blur();
            }, 0);
        }
    };
    const renderCloseButton = () => (_jsx(RiverDialogButton, { onClick: handleClose, text: props.closeButtonText || DEFAULT_CLOSE_BUTTON_TEXT }));
    const renderActionButton = () => (_jsx(_Fragment, { children: props.actionButtonText && (_jsx(RiverDialogActionButton, { onClick: handleSubmit, text: props.actionButtonText })) }));
    const renderDialogTitle = () => {
        var _a, _b;
        return (_jsxs(_Fragment, { children: [_jsxs(DialogTitle, { classes: {
                        root: clsx([
                            styles.dialogTitle,
                            { [styles.draggable]: isDraggable },
                            (_a = props.classes) === null || _a === void 0 ? void 0 : _a.title,
                        ]),
                    }, id: dragHandleId, children: [(_b = props === null || props === void 0 ? void 0 : props.titleContent) !== null && _b !== void 0 ? _b : (_jsx(Typography, { classes: { root: styles.title }, children: props.title })), showXClose && (_jsx(IconButton, { classes: { root: styles.closeButton }, onClick: () => {
                                handleClose();
                            }, size: "large", children: _jsx(CloseIcon, { fontSize: "inherit" }) }))] }), showTitleDivider && _jsx(Divider, { className: styles.titleDivider })] }));
    };
    const renderDialogContent = () => {
        var _a;
        return (_jsxs(DialogContent, { classes: { root: clsx([styles.dialogContent, (_a = props.classes) === null || _a === void 0 ? void 0 : _a.content]) }, children: [props.form && (_jsx(RiverFormContext.Provider, { value: { form: props.form }, children: props.children })), !props.form && props.children] }));
    };
    const renderDialogActions = () => {
        var _a, _b;
        return (_jsxs(_Fragment, { children: [showActionsDivider && _jsx(Divider, {}), _jsx(DialogActions, { classes: { root: clsx([styles.dialogActions, (_a = props.classes) === null || _a === void 0 ? void 0 : _a.actions]) }, children: props.actionsContent || (_jsxs(_Fragment, { children: [renderCloseButton(), !((_b = props.form) === null || _b === void 0 ? void 0 : _b.isReadOnly) && renderActionButton()] })) })] }));
    };
    return (_jsxs(Dialog, Object.assign({ onClose: onClose, open: props.open, classes: {
            paper: clsx([
                styles.paper,
                (_a = props.classes) === null || _a === void 0 ? void 0 : _a.paper,
                { [styles.mobile]: isMobile },
            ]),
        }, PaperComponent: isDraggable ? DraggablePaper : undefined, "aria-labelledby": isDraggable ? dragHandleId : "" }, props.dialogProps, { children: [renderDialogTitle(), renderDialogContent(), renderDialogActions()] })));
};
