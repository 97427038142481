import React, { useState, useEffect, useContext } from "react";
import { Routes, Navigate, useParams, Route } from "react-router-dom";

import { ICustomer } from "@river/interfaces";
import { customerService } from "../../services";
import { CustomerEnvironments } from "../customer-environments";
import { CustomerEnvironment } from "../customer-environment";
import { Protected } from "../";
import { CustomerUsers } from "../customer-users";
import { SidebarContext } from "../sidebar";
import { getCustomerSidebarContent } from "./sidebar-content";
import { CustomerLicenses } from "../customer-licenses";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { CustomerContext } from "./customer.context";

export const Customer: React.FC = () => {
  const notify = useNotification();
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const sidebarContext = useContext(SidebarContext);
  const customerId: string = useParams().customerId!;

  useEffect(() => {
    const loadCustomer = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const cust = await customerService.getCustomer(customerId);
        setCustomer(cust);
        sidebarContext?.setSidebarContent(getCustomerSidebarContent(cust));
      } catch (message) {
        notify.error({ message });
      } finally {
        setIsLoading(false);
      }
    };
    loadCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomerContext.Provider
      value={{
        customer,
        setCustomer,
      }}
    >
      <RiverSpinner show={isLoading} />
      {customer && (
        <>
          <Routes>
            <Route path={`/*`} element={<Navigate to={`environments`} />} />
            <Route
              path={`/environments`}
              element={
                <Protected>
                  <CustomerEnvironments />
                </Protected>
              }
            />
            <Route
              path={`/environments/:environmentHandle/*`}
              element={
                <Protected>
                  <CustomerEnvironment />
                </Protected>
              }
            />
            <Route
              path={`/users`}
              element={
                <Protected>
                  <CustomerUsers />
                </Protected>
              }
            />
            <Route
              path={`/licenses`}
              element={
                <Protected>
                  <CustomerLicenses />
                </Protected>
              }
            />
          </Routes>
        </>
      )}
    </CustomerContext.Provider>
  );
};
