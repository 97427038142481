import { IsNotEmpty, IsNumber } from "class-validator";

import { AdapterCrewCraftDto } from "../adapter-crew-craft.dto";

export class OracleCloudAdapterCrewCraftDto extends AdapterCrewCraftDto {
  //
  @IsNotEmpty()
  @IsNumber()
  ResourceId: number;
}
