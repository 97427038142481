import React, { useState, useEffect, useContext, ReactElement } from "react";
import { ICustomer } from "@river/interfaces";
import { customerService } from "../../services";
import { AddCustomerDialog } from "../add-customer-dialog";
import { RootSidebarContent } from "../root-sidebar-content";
import { SidebarContext } from "../sidebar";
import { PageHeader } from "../page-header";
import { PrimaryButton, useNotification } from "@river/common-ui";
import { Box } from "@mui/material";
import { CustomerItem } from "./customer-item";
import sharedStyles from "../shared-styles/shared-styles.module.scss";
import styles from "./customers.module.scss";
import clsx from "clsx";

export const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const sidebarContext = useContext(SidebarContext);
  const [addCustomerDialogOpened, setAddCustomerDialogOpened] =
    useState<boolean>(false);
  const notify = useNotification();

  useEffect(() => {
    sidebarContext?.setSidebarContent(RootSidebarContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openAddCustomerDialog = (): void => setAddCustomerDialogOpened(true);
  const closeAddCustomerDialog = (): void => setAddCustomerDialogOpened(false);

  const loadCustomers = (): void => {
    customerService
      .getCustomers()
      .then((result) => {
        setCustomers(result as ICustomer[]);
      })
      .catch((message) => {
        notify.error({ message });
      });
  };

  const renderAddCompanyButton = (): ReactElement => (
    <PrimaryButton text={"Add company"} onClick={openAddCustomerDialog} />
  );

  return (
    <>
      <PageHeader title={"Client Companies"} />
      <Box className={sharedStyles.primaryButtons}>
        {renderAddCompanyButton()}
      </Box>
      <Box className={clsx([sharedStyles.viewContent, styles.customerList])}>
        {customers.map((item) => (
          <CustomerItem
            key={item._id}
            customer={item}
            className={styles.customerItem}
            refreshCustomers={() => loadCustomers()}
          />
        ))}
      </Box>
      <AddCustomerDialog
        open={addCustomerDialogOpened}
        onClose={(success) => {
          closeAddCustomerDialog();
          if (success) loadCustomers();
        }}
      />
    </>
  );
};
