import { IsOptional, IsNumber, IsNotEmpty, IsArray } from "class-validator";
import { IAdapterCompletedPctAction } from "./adapter-completed-pct-action.interface";

export class AdapterCompletedPctActionDto
  implements IAdapterCompletedPctAction
{
  //object_type: 'workorder' | 'operation' | 'assignment';
  @IsNotEmpty()
  folder_id: string; // In case of SAP => 'workorder' | 'operation' | 'assignment'

  @IsNotEmpty()
  entity_name: string; // In case of SAP => 'workorder' | 'operation' | 'assignment'

  @IsNotEmpty()
  @IsArray()
  entity_ids: string[];

  @IsOptional()
  @IsNumber()
  completed_pct: number | null;
}
