import { IsNotEmpty } from "class-validator";

export class AdapterCrewSupervisorDto {
  @IsNotEmpty()
  user_id: string;

  @IsNotEmpty()
  user_name: string;

  @IsNotEmpty()
  display_name: string;
}
