import { IsNotEmpty, IsArray } from "class-validator";
import { AdapterSchedulingActionBaseDto } from "./adapter-scheduling-action-base.dto";
import { IAdapterSchedulingAction } from "./adapter-scheduling-action.interface";

export class AdapterSchedulingActionDto
  extends AdapterSchedulingActionBaseDto
  implements IAdapterSchedulingAction
{
  @IsNotEmpty()
  @IsArray()
  entity_ids: string[];
}
