import React, { ReactElement, useState } from "react";
import {
  RiverTextInput,
  RiverDialog,
  IValidationErrors,
  useValidation,
  IUseValidation,
  useNotification,
} from "@river/common-ui";
import { ClusterDto } from "@river/interfaces";
import styles from "./cluster-dialog.module.scss";
import { clusterService } from "../../../services";

interface ClusterDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
}

export const ClusterDialog: React.FC<ClusterDialogProps> = (
  props
): ReactElement => {
  const validation: IUseValidation = useValidation();
  const [cluster, setCluster] = useState<ClusterDto>(new ClusterDto());
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });
  const notify = useNotification();

  const resetValidationErrors = (): void =>
    setValidationErrors({
      fields: {},
      list: [],
    });

  const resetDialogState = (): void => {
    resetValidationErrors();
    setCluster(new ClusterDto());
  };

  const closeDialog = (success: boolean): void => {
    resetDialogState();
    props.onClose(success);
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const newState: ClusterDto = {
      ...cluster,
      [event.target.name!]: event.target.value,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(cluster));
    setCluster(newState);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const validateCluster = async (
    cluster: ClusterDto
  ): Promise<IValidationErrors> => {
    const errors = await validation.validateFields(cluster);
    setValidationErrors(errors);
    return errors;
  };

  const submitCluster = async (): Promise<void> => {
    const errors: IValidationErrors = await validateCluster(cluster);
    if (errors.list.length > 0) {
      return;
    }
    try {
      await clusterService.createCluster(cluster);
      notify.success(`Cluster has been successfully created`);
      const success = true;
      closeDialog(success);
    } catch (message) {
      notify.error({ message });
    }
  };
  const renderClusterField = (): ReactElement => (
    <RiverTextInput
      id={"cluster"}
      label={"Cluster"}
      value={cluster.cluster || ""}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["cluster"]}
      fullWidth
    />
  );

  const renderDescriptionField = (): ReactElement => (
    <RiverTextInput
      id={"description"}
      label={"Description..."}
      value={cluster.description || ""}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["description"]}
      inputProps={{
        multiline: true,
        minRows: 5,
      }}
      fullWidth
    />
  );

  const renderConnectionStringField = (): ReactElement => (
    <RiverTextInput
      id={"connection_string"}
      label={"Connection String"}
      value={cluster.connection_string || ""}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["connection_string"]}
      fullWidth
    />
  );

  const renderConnectionOptionsField = (): ReactElement => (
    <RiverTextInput
      id={"connection_options"}
      label={"Connection Options"}
      value={cluster.connection_options || ""}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["connection_options"]}
      fullWidth
    />
  );

  return (
    <RiverDialog
      title={"Create Cluster"}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={"Create"}
      showActionsDivider={false}
      onSubmit={submitCluster}
    >
      {renderClusterField()}
      {renderDescriptionField()}
      {renderConnectionStringField()}
      {renderConnectionOptionsField()}
    </RiverDialog>
  );
};
