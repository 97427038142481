import { IsNotEmpty, IsIn } from "class-validator";

import { IAdapterLookup } from "./adapter-lookup.interface";
import { Constants } from "@river/constants";

export class AdapterLookupDto implements Omit<IAdapterLookup, "_id"> {
  @IsNotEmpty()
  @IsIn(Object.values(Constants.lookup_type))
  lookup_type: string;

  @IsNotEmpty()
  value: string;

  @IsNotEmpty()
  description: string;
}
