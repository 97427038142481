import { IsNotEmpty, IsDate, IsOptional } from 'class-validator';
import { IKpiParams } from './kpi-params.interface';

// obsolete...
export class KpiParamsDto implements IKpiParams {

  @IsNotEmpty()
  @IsDate()
  start_date: Date;

  @IsOptional()
  WorkCenter?: string;

  @IsOptional()
  PersonNumber?: string;
}