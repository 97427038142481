import { IsOptional } from "class-validator";

import { AdapterTimeCardDto } from "../adapter-timecard.dto";

export class OracleEbsAdapterTimeCardDto extends AdapterTimeCardDto {
  //
  @IsOptional()
  INSTANCE_ID: string;

  @IsOptional()
  INSTANCE_NAME: string;
}
