import { IsNotEmpty, IsOptional } from "class-validator";

import { IAdapterBaseline } from "./adapter-baseline.interface";

export class AdapterBaselineDto implements Omit<IAdapterBaseline, "_id"> {
  @IsNotEmpty()
  folder_id: string;

  @IsNotEmpty()
  baseline: string;

  @IsOptional()
  description?: string;

  @IsOptional()
  display_options?: string;

  [s: string]: any;
}
