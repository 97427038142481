import { jsx as _jsx } from "react/jsx-runtime";
import { useEnv } from "../../../hooks";
import styles from "./river-checkbox-formatter.module.scss";
import clsx from "clsx";
import { createUseStyles } from "react-jss";
export const RiverCheckboxFormatter = (props) => {
    const env = useEnv();
    const { isMobile } = env;
    const useStyles = createUseStyles({
        title: {
            border: `2px solid ${props.fontColor}`,
        },
    });
    const dynamicClasses = useStyles();
    return (_jsx("input", { type: "checkbox", id: props.id, checked: props.checked, disabled: props.disabled, onChange: props.onChange, className: clsx([
            styles.checkbox,
            { [styles.mobile]: isMobile },
            props.className,
            dynamicClasses.title,
        ]) }));
};
