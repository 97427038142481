import { IsOptional, IsBoolean, IsNotEmpty, IsArray } from "class-validator";
import { IAdapterBreakinAction } from "./adapter-breakin-action.interface";
import { IAdapterCompletedPctAction } from "./adapter-completed-pct-action.interface";

export class AdapterBreakinActionDto implements IAdapterBreakinAction {
  //object_type: 'workorder' | 'operation' | 'assignment';
  @IsNotEmpty()
  folder_id: string; // In case of SAP => 'workorder' | 'operation' | 'assignment'

  @IsNotEmpty()
  entity_name: string; // In case of SAP => 'workorder' | 'operation' | 'assignment'

  @IsNotEmpty()
  @IsArray()
  entity_ids: string[];

  @IsOptional()
  @IsBoolean()
  breakin: boolean | null;
}
