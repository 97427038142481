import { IsNotEmpty, IsBoolean, IsNumber, Min } from "class-validator";
import { AdapterTimeCardDto } from "../adapter-timecard.dto";

// TODO
export class JdeAdapterTimeCardDto extends AdapterTimeCardDto {
  // @IsNotEmpty()
  // PersonNumber: string;

  // @IsNotEmpty()
  // PersonName: string;

  // @IsBoolean()
  // is_final: boolean = false;

  // @IsNotEmpty()
  // @IsNumber()
  // @Min(0, {
  //   message: (opts) =>
  //     `module.execution:validation.remaining_hours_validator?hours=${opts.constraints[0]}`,
  // })
  // remaining_hours: number = 0;
}
