import React, { ReactElement, useState } from "react";
import { CustomerDto, ICustomer } from "@river/interfaces";
import { LinkCellFormatter } from "../../river-data-grid";
import { RiverPlatformSelect } from "../../shared";
import { Card, Box } from "@mui/material";
import clsx from "clsx";
import styles from "./customer-item.module.scss";
import { customerService } from "../../../services";
import { useNavigate } from "react-router";

const DEFAULT_CUSTOMER_LOGO_URL = "default-customer-logo.svg";

interface ICustomerItemProps {
  customer: ICustomer;
  className?: string;
  refreshCustomers: () => void;
}
export const CustomerItem: React.FC<ICustomerItemProps> = (
  props
): ReactElement => {
  const customer: ICustomer = props.customer;
  const [status, setStatus] = useState<string>(customer.status.code);
  const getLogoUrl = (): string => DEFAULT_CUSTOMER_LOGO_URL;
  const onStatusChange = async (status: string) => {
    setStatus(status);
    await customerService.updateCustomer(customer._id, {
      ...customer,
      status: { code: status },
    } as CustomerDto);
    props.refreshCustomers();
  };
  const navigate = useNavigate();

  return (
    <Box className={clsx([styles.root, props.className])}>
      <Card
        className={styles.card}
        onClick={() => navigate(`/customers/${customer._id}`)}
      >
        <div
          className={styles.logo}
          style={{
            background: `url('${getLogoUrl()}') no-repeat`,
          }}
        />
        <div className={styles.status}>
          <RiverPlatformSelect
            items={[
              {
                label: "Active",
                value: "active",
              },
              {
                label: "Inactive",
                value: "inactive",
              },
            ]}
            value={status}
            onChange={onStatusChange}
          />
        </div>
      </Card>
      <Box className={styles.desc}>
        <LinkCellFormatter
          className={styles.name}
          url={`/customers/${customer._id}`}
          text={customer.description!}
        />
      </Box>
    </Box>
  );
};
