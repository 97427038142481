import { IsArray, IsNotEmpty, IsOptional } from 'class-validator';
import { IAdapterMaterialShortageAction } from './adapter-material-shortage-action.interface';

export class AdapterMaterialShortageActionDto implements IAdapterMaterialShortageAction {
  @IsNotEmpty()
  @IsArray()
  entity_ids: string[];

  @IsOptional()
  pipeline?: string;
}