import { IsNotEmpty, IsOptional, Matches, IsObject, ValidateNested, IsArray } from "class-validator";
import { Type } from 'class-transformer';

import { Constants } from "@river/constants";
import { IProperty, Property } from "../common";

/** Payload that is provided when updating a system environment cron task. */
export class SystemEnvCronTaskDto {
  @IsOptional()
  frequency?: string;

  @IsNotEmpty()
  site_id?: any;

  @IsOptional()
  enabled?: boolean;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Property)
  options?: Property[];
}
