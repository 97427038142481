var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import styles from "./river-metrics-subheader.module.scss";
import clsx from "clsx";
import { Box, Tabs, Tab } from "@mui/material";
import { useTranslation } from "../../hooks";
export const RiverMetricsSubheader = (props) => {
    const { className, tabs } = props;
    const { t } = useTranslation();
    const [collaps, setCollaps] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const CustomTabPanel = (props) => {
        const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
        return (_jsx("div", Object.assign({ role: "tabpanel", hidden: value !== index, id: `metrics-subheader-tabpanel-${index}`, "aria-labelledby": `metrics-subheader-tab-${index}` }, other, { children: value === index && _jsx(Box, { children: children }) })));
    };
    function getSeverityClassName(value) {
        switch (value) {
            default:
            case "neutral":
                return styles.neutral;
            case "success":
                return styles.success;
            case "error":
                return styles.error;
        }
    }
    function handleToggleCollaps() {
        setCollaps(!collaps);
    }
    function a11yProps(index) {
        return {
            id: `metrics-subheader-tab-${index}`,
            "aria-controls": `metrics-subheader-tabpanel-${index}`,
        };
    }
    function handleChange(event, newValue) {
        setActiveTabIndex(newValue);
    }
    return (_jsx(_Fragment, { children: _jsxs(Box, { className: clsx([styles.root, className]), children: [_jsxs(Box, { className: clsx([styles.header]), children: [_jsx(Box, { sx: { borderBottom: 1, borderColor: "divider", width: "100%" }, children: _jsx(Tabs, { classes: {
                                    root: styles.tabs_root,
                                    indicator: styles.indicator,
                                }, value: activeTabIndex, onChange: handleChange, children: tabs.map((tab, index) => (_jsx(Tab, { label: tab.label, classes: { root: styles.tab_root } }, index))) }) }), _jsx("span", { className: styles.toggle, onClick: handleToggleCollaps, children: collaps
                                ? t("common.label:show_metrics")
                                : t("common.label:hide_metrics") })] }), tabs.map((tab, tabIndex) => (_jsx(CustomTabPanel, Object.assign({ value: activeTabIndex, index: tabIndex }, a11yProps(tabIndex), { children: _jsx(Box, { className: clsx([styles.main, collaps ? styles.main_hide : null]), children: tab.metrics.map((metric, metricIndex) => {
                            var _a, _b, _c;
                            return (_jsxs(Box, { className: styles.item, children: [_jsx("span", { className: styles.item_label, children: metric.label }), _jsxs(Box, { className: styles.item_bottom, children: [_jsx("span", { className: styles.item_value, children: metric.value }), _jsx("span", { className: clsx([
                                                    styles.item_detail,
                                                    getSeverityClassName((_a = metric === null || metric === void 0 ? void 0 : metric.valueDetail) === null || _a === void 0 ? void 0 : _a.severity),
                                                ]), children: (_c = (_b = metric === null || metric === void 0 ? void 0 : metric.valueDetail) === null || _b === void 0 ? void 0 : _b.text) !== null && _c !== void 0 ? _c : "" })] })] }, metricIndex));
                        }) }) }), tabIndex)))] }) }));
};
