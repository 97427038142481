import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Snackbar, Box, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { InfoIcon, WarningIcon, ErrorIcon, SuccessIcon } from "./icons";
import { NotificationContext, NotificationType, } from "./notify-context";
import clsx from "clsx";
import styles from "./river-notify.module.scss";
export const useNotification = () => {
    return useContext(NotificationContext);
};
const getTypeElements = (type) => {
    return ({
        [NotificationType.SUCCESS]: {
            typeClass: styles.success,
            typeIcon: SuccessIcon,
        },
        [NotificationType.INFO]: {
            typeClass: styles.info,
            typeIcon: InfoIcon,
        },
        [NotificationType.ERROR]: {
            typeClass: styles.error,
            typeIcon: ErrorIcon,
        },
        [NotificationType.WARN]: {
            typeClass: styles.warn,
            typeIcon: WarningIcon,
        },
    }[type] || {});
};
function NotificationTransition(props) {
    return _jsx(Slide, Object.assign({}, props, { direction: "right", timeout: 150 }));
}
export const NotificationContainer = (props) => {
    const notification = useNotification();
    const { typeClass, typeIcon } = getTypeElements(notification.type);
    return (_jsx(Snackbar, Object.assign({ classes: { root: styles.root }, anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        }, autoHideDuration: null, onClose: notification.close, TransitionComponent: NotificationTransition }, notification.snackbarProps, { children: _jsxs(Box, { className: clsx([styles.notificationBox, typeClass]), children: [_jsx("div", { className: styles.icon, children: _jsx(Box, { component: typeIcon }) }), _jsxs("div", { className: styles.content, children: [_jsx("div", { className: styles.title, children: notification.title }), _jsx("div", { className: styles.message, children: notification.message })] }), _jsx(IconButton, { classes: { root: styles.closeIcon }, onClick: notification.close, size: "large", children: _jsx(CloseIcon, { fontSize: "inherit" }) })] }) })));
};
