import EnvironmentsIcon from "@mui/icons-material/Public";
import UsersIcon from "@mui/icons-material/PermIdentity";
import GavelIcon from "@mui/icons-material/Gavel";
import { ICustomer } from "@river/interfaces";
import { ISidebarContent } from "../sidebar";

export const getCustomerSidebarContent = (
  customer: ICustomer
): ISidebarContent => ({
  parent: {
    primary: customer.description,
    to: "/customers",
  },
  items: [
    {
      icon: EnvironmentsIcon,
      primary: "Environments",
      to: `/customers/${customer._id}/environments`,
    },
    {
      icon: UsersIcon,
      primary: "Users",
      to: `/customers/${customer._id}/users`,
    },
    {
      icon: GavelIcon,
      primary: "Licenses",
      to: `/customers/${customer._id}/licenses`,
    },
  ],
});
