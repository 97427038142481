import { IsNumber, IsNotEmpty, IsOptional } from "class-validator";

import { IOracleEbsAdapterWorkorderMeterReading } from "./oracle-ebs-adapter-workorder-meter-reading.interface";

export class OracleEbsAdapterWorkorderMeterReadingDto
  implements IOracleEbsAdapterWorkorderMeterReading
{
  @IsNotEmpty()
  @IsNumber()
  METER_ID: number;

  @IsOptional()
  @IsNumber()
  READING_VALUE?: number | null;
}
