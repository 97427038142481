import { useFocusRef } from "../../../../hooks/useFocusRef";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

interface IRiverCellExpanderFormatter {
  isCellSelected: boolean;
  expanded: boolean;
  onCellExpand: () => void;
}

export function RiverCellExpanderFormatter({
  isCellSelected,
  expanded,
  onCellExpand,
}: IRiverCellExpanderFormatter) {
  const { ref, tabIndex } = useFocusRef<HTMLSpanElement>(isCellSelected);

  function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      onCellExpand();
    }
  }

  return (
    <span onClick={onCellExpand} onKeyDown={handleKeyDown}>
      <span ref={ref} tabIndex={tabIndex}>
        {expanded ? <RemoveCircleIcon /> : <ArrowDropDownCircleIcon />}
      </span>
    </span>
  );
}
