import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEnv } from "../../../hooks";
import styles from "./river-sidebar-toggle.module.scss";
import clsx from "clsx";
export const RiverSidebarToggle = (props) => {
    const env = useEnv();
    const { isMobile } = env;
    const sidebarContext = props.context;
    const commonProps = {
        className: props.className,
        classes: {
            root: clsx([
                styles.root,
                { [styles.mobile]: isMobile },
                { [styles.collapsed]: sidebarContext === null || sidebarContext === void 0 ? void 0 : sidebarContext.collapsed },
            ]),
        },
    };
    return (_jsx(_Fragment, { children: sidebarContext.collapsed ? (_jsx(ArrowForwardIosIcon, Object.assign({}, commonProps, { onClick: () => sidebarContext.setCollapsed({ collapsed: false }) }))) : (_jsx(ArrowBackIosIcon, Object.assign({}, commonProps, { onClick: () => sidebarContext.setCollapsed({ collapsed: true }) }))) }));
};
