import { IStatus } from "../common";

/** Represents a Transactions submitted from a mobile device for future processing. */
export class MobileTransactionDto {
  /** Determines how to process transaction. */
  transaction_type: string;

  /** User who submitted the transaction. */
  user_id: string;

  /** Payload used to execute the transaction. This will be different for different transaction_type. */
  payload: any; // Record<string, any>;
}
