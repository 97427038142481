import { IsNotEmpty, IsOptional, IsArray } from "class-validator";

import { IAdapterRole } from "./adapter-role.interface";

export class AdapterRoleDto implements Omit<IAdapterRole, "_id"> {
  @IsNotEmpty()
  role: string = "";

  @IsNotEmpty()
  description: string = "";

  @IsNotEmpty()
  license: string = "";
}
