import { IsNotEmpty } from "class-validator";
import { StatusDto } from "../common";

export class ModuleActionSummaryDto {
  @IsNotEmpty()
  action: string;

  @IsNotEmpty()
  status: StatusDto;
}
