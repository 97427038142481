import { IsNotEmpty, IsOptional, IsIn } from 'class-validator';

import { Constants } from '@river/constants';

// import { AttributeDto } from './attribute.dto';
// import { RelationDto } from './relation.dto';
// import { IndexDto } from './index.dto';

export class EntityDto {
  
  // @IsNotEmpty()
  // @IsIn(Object.values(Constants.adapter_type))
  // adapter_type?: string; // "sap", "maximo", ...

  @IsNotEmpty()
  entity_name: string;

  @IsOptional()
  description?: string;
  

  @IsOptional()
  is_readonly?: boolean = false;

  @IsOptional()
  is_schema?: boolean = true;

  @IsOptional()
  collection_name?: string;

  @IsOptional()
  add_timestamps?: boolean = true;

  @IsOptional()
  add_id?: boolean = true;
  
  @IsOptional()
  external_url?: string;

  //customer_id?: string;

  // @IsOptional()
  // @IsArray()
  // attributes?: AttributeDto[];

  // @IsOptional()
  // @IsArray()
  // relations?: RelationDto[];

  // @IsOptional()
  // @IsArray()
  // indexes?: IndexDto[];
}