export const setAccessToken = (accessToken) => {
    localStorage.setItem("token", accessToken);
};
export const removeAccessToken = () => {
    localStorage.removeItem("token");
};
export const getAccessToken = () => {
    return localStorage.getItem("token");
};
export const getLanguage = () => {
    return localStorage.getItem("language");
};
export const setLanguage = (language) => {
    localStorage.setItem("language", language);
};
