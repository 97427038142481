import React from "react";
import { IEnvironment } from "@river/interfaces";

export interface IEnvironmentContext {
  environment: IEnvironment | null;
  setEnvironment: (environment: IEnvironment) => void;
}

export const EnvironmentContext =
  React.createContext<IEnvironmentContext | null>(null);
