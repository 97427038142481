import React, { SVGProps } from "react";

const LeftArrowIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.40991 1.91L2.82991 6.5L7.40991 11.09L5.99991 12.5L-8.82626e-05 6.5L5.99991 0.5L7.40991 1.91Z"
        fill="white"
      />
    </svg>
  );
};

export default LeftArrowIcon;
