import { IsNotEmpty, IsOptional, IsNumber } from "class-validator";
import { IAdapterDependencyAction } from "./adapter-dependency-action.interface";

export class AdapterDependencyActionDto implements IAdapterDependencyAction {
  @IsOptional()
  folder_id?: string | null;

  @IsNotEmpty()
  predecessor_id: string;

  @IsNotEmpty()
  successor_id: string;

  @IsNotEmpty()
  dependency_type: "FS" | "FF" | "SF" | "SS";

  @IsNumber()
  lag_hours: number = 0;
}
