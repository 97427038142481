import { IsNotEmpty, IsOptional, Matches } from "class-validator";

import { Constants } from "@river/constants";
import { IProperty } from "../common";

/** Data used when creating a custom cron task. */
export class CronTaskAuthDto {
  @IsNotEmpty()
  username: string;

  @IsNotEmpty()
  password: string;
}

