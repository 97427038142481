import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RiverSidebarToggle } from "./river-sidebar-toggle";
import { RiverSidebarLogo } from "./river-sidebar-logo";
import { useEnv } from "../../hooks";
import styles from "./river-sidebar.module.scss";
import clsx from "clsx";
const DEFAULT_COLLAPSABLE_IN_DESKTOP_MODE = true;
export const RiverSidebar = (props) => {
    var _a;
    const env = useEnv();
    const { isMobile } = env;
    const collapsableInDesktopMode = (_a = props.collapsableInDesktopMode) !== null && _a !== void 0 ? _a : DEFAULT_COLLAPSABLE_IN_DESKTOP_MODE;
    const shouldRenderToggle = isMobile || collapsableInDesktopMode;
    const Logo = props.logo || (() => _jsx(RiverSidebarLogo, { src: props.logoSrc }));
    return (_jsxs("div", { className: clsx([
            styles.sidebar,
            props.className,
            { [styles.mobile]: isMobile },
        ]), children: [_jsxs("div", { className: styles.sidebarHeader, children: [_jsx(Logo, {}), shouldRenderToggle && (_jsx(RiverSidebarToggle, { context: props.context, className: styles.sidebarToggle }))] }), props.children] }));
};
