import { IsNotEmpty, IsInt } from "class-validator";

export class LicenseDto {
  @IsNotEmpty()
  license: string;

  @IsNotEmpty()
  description: string;

  @IsNotEmpty()
  @IsInt()
  count: number;
}
