import { IsBoolean, IsNotEmpty, IsOptional } from "class-validator";
import { saml2ReplyUrl } from "@river/config";

export class EnvSaml2OptionsDto {
  // identity provider - options
  @IsOptional()
  idp_xml?: string;

  @IsNotEmpty()
  idp_login_url: string = "";

  @IsNotEmpty()
  idp_issuer: string = ""; //app identifier in IDP

  @IsNotEmpty()
  idp_certificate_subject: string = "";

  @IsNotEmpty()
  idp_certificate: string = "";

  @IsNotEmpty()
  idp_reply_url: string = saml2ReplyUrl("<subdomain>");

  @IsOptional()
  @IsBoolean()
  want_metadata_signed?: boolean = false;

  @IsOptional()
  @IsBoolean()
  want_assertions_signed?: boolean = false;

  @IsOptional()
  @IsBoolean()
  want_auth_response_signed?: boolean = false;

  // Identity propagation config

  @IsNotEmpty()
  target_issuer: string = "";

  @IsNotEmpty()
  target_audience: string = "";

  @IsNotEmpty()
  target_reply_url: string = "https://<server>:<port>/sap/bc/sec/oauth2/token";

  @IsOptional()
  target_scope?: string =
    "/RIVER/ATTA_SRV_0001 /RIVER/LIST_SRV_0001 /RIVER/LOOKUP_SRV_0001 /RIVER/MO_CONF_SRV_0001 /RIVER/NET2WO_SRV_0001 /RIVER/NOTIF_SRV_0001 /RIVER/OQUEUE_SRV_0001 /RIVER/TO_SRV_0001 /RIVER/WO_SRV_0001 /RIVER/NETWORK_SRV_0001 /RIVER/MAT_DOC_SRV_0001";

  // SAP OAuth2 config - access token retrieval
  @IsNotEmpty()
  client_id: string = "";

  @IsNotEmpty()
  client_secret: string = "";

  created_at?: Date;
  updated_at?: Date;

  created_by?: string;
  updated_by?: string;
}
