import React, { ReactElement } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Protected } from "../";
import { Customers } from "../customers";
import { Clusters } from "../clusters";
import { Customer } from "../customer";
import { Cluster } from "../cluster";
import sharedStyles from "../shared-styles/shared-styles.module.scss";

export const Content: React.FC = (): ReactElement => {
  return (
    <div className={sharedStyles.content}>
      <Routes>
        <Route path="/" element={<Navigate to="/customers" />} />
        <Route
          path="/customers"
          element={
            <Protected>
              <Customers />
            </Protected>
          }
        />
        <Route
          path="/customers/:customerId/*"
          element={
            <Protected>
              <Customer />
            </Protected>
          }
        />
        <Route
          path="/clusters"
          element={
            <Protected>
              <Clusters />
            </Protected>
          }
        />
        <Route
          path="/clusters/:clusterId/*"
          element={
            <Protected>
              <Cluster />
            </Protected>
          }
        />
      </Routes>
    </div>
  );
};
