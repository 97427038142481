import { IsNotEmpty, IsArray, ValidateNested } from "class-validator";
import { Type } from "class-transformer";

import { QueryAttributeValueDto } from "./query-attribute-value.dto";

import { IQueryAttribute } from "./query-attribute.interface";

export class QueryAttributeDto implements IQueryAttribute {
  @IsNotEmpty()
  attribute_name: string;

  @IsNotEmpty()
  @IsArray()
  @ValidateNested()
  @Type(() => QueryAttributeValueDto)
  attribute_value: QueryAttributeValueDto;
}
