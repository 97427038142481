import {
  IsNumber,
  IsNotEmpty,
  IsOptional,
  IsDate,
  ValidateNested,
  IsArray,
} from "class-validator";
import { Type } from "class-transformer";

import { IOracleEbsAdapterWorkorderCompletion } from "./oracle-ebs-adapter-workorder-completion.interface";
import { OracleEbsAdapterWorkorderMeterReadingDto } from "./oracle-ebs-adapter-workorder-meter-reading.dto";

export class OracleEbsAdapterWorkorderCompletionDto
  implements IOracleEbsAdapterWorkorderCompletion
{
  @IsNotEmpty()
  _id: string;

  //   @IsNotEmpty()
  //   @IsNumber()
  //   ORGANIZATION_ID: number;

  @IsNotEmpty()
  @IsNumber()
  STATUS_TYPE: number;

  @IsNotEmpty()
  @IsDate()
  ACTUAL_START_DATE: Date;

  @IsNotEmpty()
  @IsDate()
  ACTUAL_END_DATE: Date;

  @IsOptional()
  @IsDate()
  SHUTDOWN_START_DATE?: Date | null;

  @IsOptional()
  @IsDate()
  SHUTDOWN_END_DATE?: Date | null;

  @IsOptional()
  RECONCILIATION_CODE?: string | null;

  @IsOptional()
  COMPLETION_COMMENTS?: string | null;

  @IsOptional()
  SUBINVENTORY_CODE?: string | null;

  @IsOptional()
  LOCATOR_ID?: number | null;

  @IsOptional()
  LOT_NUMBER: string | null;

  @IsOptional()
  @IsNumber()
  FAILURE_ID?: number | null;
  FAILURE_ENTRY_ID?: number | null;

  @IsOptional()
  @IsDate()
  FAILURE_DATE?: Date | null;

  @IsOptional()
  FAILURE_CODE?: string | null;

  @IsOptional()
  CAUSE_CODE?: string | null;

  @IsOptional()
  RESOLUTION_CODE?: string | null;

  @IsOptional()
  FAILURE_COMMENTS?: string | null;

  @IsOptional()
  ATTRIBUTE1?: string | null;

  @IsOptional()
  ATTRIBUTE2?: string | null;

  @IsOptional()
  ATTRIBUTE3?: string | null;

  @IsOptional()
  ATTRIBUTE4?: string | null;

  @IsOptional()
  ATTRIBUTE5?: string | null;

  @IsOptional()
  ATTRIBUTE6?: string | null;

  @IsOptional()
  ATTRIBUTE7?: string | null;

  @IsOptional()
  ATTRIBUTE8?: string | null;

  @IsOptional()
  ATTRIBUTE9?: string | null;

  @IsOptional()
  ATTRIBUTE10?: string | null;

  @IsOptional()
  ATTRIBUTE11?: string | null;

  @IsOptional()
  ATTRIBUTE12?: string | null;

  @IsOptional()
  ATTRIBUTE13?: string | null;

  @IsOptional()
  ATTRIBUTE14?: string | null;

  @IsOptional()
  ATTRIBUTE15?: string | null;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => OracleEbsAdapterWorkorderMeterReadingDto)
  METER_READINGS?: OracleEbsAdapterWorkorderMeterReadingDto[];
}
