import { IsNotEmpty, IsArray, IsOptional } from 'class-validator';
import { IAdapterJobPriorityScoreAction } from './adapter-job-priority-score-action.interface';

export class AdapterJobPriorityScoreActionDto implements IAdapterJobPriorityScoreAction {

  @IsNotEmpty()
  entity_name: string;

  @IsOptional()
  @IsArray()
  entity_ids?: string[];

  @IsOptional()
  pipeline?: string;

  // Other ERP specific attributes
  [s: string]: any;
}