import { IsNotEmpty, IsArray } from "class-validator";
import { IAdapterFolderAction } from "./adapter-folder-action.interface";

export class AdapterFolderActionDto implements IAdapterFolderAction {
  @IsNotEmpty()
  folder_id: string; // In case of SAP => 'workorder' | 'operation' | 'assignment'

  @IsNotEmpty()
  entity_name: string; // In case of SAP => 'workorder' | 'operation' | 'assignment'

  @IsNotEmpty()
  @IsArray()
  entity_ids: string[];
}
