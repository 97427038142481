import React, {
  ReactElement,
  useContext,
  useRef,
  useState,
  PropsWithChildren,
} from "react";
import { Avatar, Box, Divider, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowRightIcon from "./arrow-right";
import { RiverSearch } from "./river-search";
import { SidebarContext } from "../sidebar";
import { UserAccountPopup } from "../user-account-popup";
import { DropdownIconVariant, IUseEnv, useEnv } from "@river/common-ui";
import { RiverPlatformSelect } from "../shared";
import { PlatformUserContext } from "../../context";
import styles from "./page-header.module.scss";
import clsx from "clsx";

export interface IPageHeaderProps {
  title?: string;
}

const SidebarToggle: React.FC = (): ReactElement => {
  const sidebarContext = useContext(SidebarContext)!;
  return (
    <IconButton
      color="inherit"
      onClick={() => sidebarContext.setCollapsed({ collapsed: false })}
      className={styles.sidebarToggle}
    >
      <MenuIcon />
    </IconButton>
  );
};
const Title: React.FC<PropsWithChildren> = (props): ReactElement => (
  <span className={styles.title}>{props.children}</span>
);
const SearchSection: React.FC = (props): ReactElement => {
  const [value, setValue] = useState<string>("show_all");
  const onSearchChange = (value: string): void => {
    setValue(value);
  };
  return (
    <Box className={styles.searchSection}>
      <RiverPlatformSelect
        items={[
          {
            label: "Show all",
            value: "show_all",
          },
          {
            label: "Active",
            value: "active",
          },
          {
            label: "Inactive",
            value: "inactive",
          },
        ]}
        value={value}
        onChange={onSearchChange}
        iconVariant={DropdownIconVariant.Chevron}
        selectorAutoWidth={true}
        minWidth={"136px"}
      />
      <RiverSearch className={styles.searchField} />
    </Box>
  );
};

const UserInfo: React.FC = (props): ReactElement => {
  const [userAccountPopoverOpened, setUserAccountPopoverOpened] =
    useState<boolean>(false);
  const anchor = useRef(null);
  const userContext = React.useContext(PlatformUserContext);
  const displayName = userContext?.user.display_name || "";
  const getAvatarShorthand = (displayName: string): string =>
    displayName
      .split(" ")
      .map((token) => token.substr(0, 1).toUpperCase())
      .join("");
  return (
    <>
      <div
        className={styles.userInfo}
        ref={anchor}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setUserAccountPopoverOpened(true);
        }}
      >
        <Avatar className={styles.avatar}>
          {getAvatarShorthand(displayName)}
        </Avatar>
        <Box className={styles.name}>{displayName}</Box>
        <Box className={styles.arrow}>
          <ArrowRightIcon />
        </Box>
      </div>
      <UserAccountPopup
        open={userAccountPopoverOpened}
        anchorEl={anchor.current!}
        onClose={() => {
          setUserAccountPopoverOpened(false);
        }}
      />
    </>
  );
};
export const PageHeader: React.FC<IPageHeaderProps> = (props): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  return (
    <div className={clsx([styles.root, { [styles.mobile]: isMobile }])}>
      <Box className={clsx([styles.leftSection])}>
        <SidebarToggle />
        <Title>{props.title}</Title>
      </Box>
      <Box className={styles.rightSection}>
        <SearchSection />
        <Divider orientation="vertical" classes={{ root: styles.divider }} />
        <UserInfo />
      </Box>
    </div>
  );
};
