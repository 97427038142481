import { IsNotEmpty, IsOptional } from 'class-validator';

import { IAdapterUserPreference } from './adapter-user-preference.interface';

export class AdapterUserPreferenceDto implements Omit<IAdapterUserPreference, '_id' | 'user_id'> {

  @IsNotEmpty()
  key: string;

  @IsOptional()
  value?: any;
}