import { ICluster } from "@river/interfaces";
import { ISidebarContent } from "../sidebar";

export const getClusterSidebarContent = (
  cluster: ICluster
): ISidebarContent => ({
  parent: {
    primary: `${cluster.cluster}`,
    to: `/clusters`,
  },
  items: [
    {
      primary: "Details",
      to: `/clusters/${cluster._id}/details`,
    },
    {
      primary: "Options",
      to: `/clusters/${cluster._id}/options`,
    },
  ],
});
