import React, { ReactElement } from "react";
import ReactDataGrid, { DataGridProps } from "react-data-grid";
import styles from "./river-data-grid.module.scss";
import clsx from "clsx";

export enum RiverDataGridTheme {
  LIGHT = "light",
  DARK = "dark",
}

const DEFAULT_ROW_HEIGHT = 40;
const DEFAULT_THEME = RiverDataGridTheme.LIGHT;

interface IRiverDataGridProps extends DataGridProps<any> {
  theme?: RiverDataGridTheme;
}
export const tooltipCell = (value: any): ReactElement => (
  <div className={styles.tooltipCell} title={value}>
    {value}
  </div>
);

export const RiverDataGrid: React.FC<IRiverDataGridProps> = (
  props
): ReactElement => {
  const getThemeClass = (): string => {
    const theme: RiverDataGridTheme = props.theme || DEFAULT_THEME;
    return {
      [RiverDataGridTheme.LIGHT]: "rdg-light",
      [RiverDataGridTheme.DARK]: "rdg-dark",
    }[theme];
  };

  const getRowHeight = (): any => props.rowHeight || DEFAULT_ROW_HEIGHT;
  const gridProps = { ...props };
  gridProps.className = clsx([props.className, styles.root, getThemeClass()]);
  gridProps.rowHeight = getRowHeight();
  return <ReactDataGrid {...gridProps} />;
};
