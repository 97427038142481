import { jsx as _jsx } from "react/jsx-runtime";
import { useEnv } from "../../../hooks";
import { RhineRiverLogo } from "../../rhine-river-logo";
import styles from "./river-sidebar-logo.module.scss";
import clsx from "clsx";
const DEFAULT_SIDEBAR_LOGO = RhineRiverLogo;
const DEFAULT_LOGO_ENABLED = false;
export const RiverSidebarLogo = (props) => {
    const env = useEnv();
    const { isMobile } = env;
    const DefaultLogo = DEFAULT_SIDEBAR_LOGO;
    return (_jsx("div", { className: clsx([styles.root, { [styles.mobile]: isMobile }]), children: props.src ? (_jsx("img", { className: "logo-image", src: props.src, alt: "Logo" })) : (DEFAULT_LOGO_ENABLED && _jsx(DefaultLogo, {})) }));
};
