import { ValidatorConstraintInterface, ValidatorConstraint, IsNotEmpty, Validate } from 'class-validator';

@ValidatorConstraint()
export class ModuleTypeValidator implements ValidatorConstraintInterface {

  // ------------
  validate(value: any) {
    if (Array.isArray(value)) {
      return !value.some(v => typeof v !== 'string');
    }
    return typeof value === 'string';
  }

}


export class AdapterRoleModuleDto {

  @IsNotEmpty()
  @Validate(ModuleTypeValidator, { message: 'Invalid type for "module" attribute'})
  module: string | string[];
}