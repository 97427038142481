import React, { ReactElement, useContext } from "react";
import { List, Divider } from "@mui/material";
import { ListItemLink, IListItemLink } from "./list-item-link/list-iItem-link";
import LeftArrowIcon from "./left-arrow";
import { RiverSidebar, useEnv, IUseEnv } from "@river/common-ui";
import { SidebarContext } from "./sidebar-context";
import styles from "./sidebar.module.scss";
import clsx from "clsx";

export interface ISidebarContent {
  parent?: IListItemLink;
  items: IListItemLink[];
}

interface ISidebarProps {
  content: ISidebarContent;
}

export const Sidebar: React.FC<ISidebarProps> = (props): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;
  const { parent, items } = props.content;
  const sidebarContext = useContext(SidebarContext)!;

  const onLeafItemClick = (): void => {
    if (isMobile) {
      sidebarContext.setCollapsed({ collapsed: true });
    }
  };

  return (
    <>
      <RiverSidebar
        context={sidebarContext}
        className={clsx([
          styles.sidebar,
          {
            [styles.collapsed]: sidebarContext.collapsed,
            [styles.mobile]: isMobile,
          },
        ])}
        collapsableInDesktopMode={false}
      >
        <List classes={{ root: styles.list }}>
          {parent && (
            <>
              <ListItemLink
                to={parent.to}
                primary={parent.primary}
                icon={LeftArrowIcon}
                key={parent.to}
                classes={{ icon: styles.parentItemIcon }}
              />
              <Divider classes={{ root: styles.divider }} />
            </>
          )}
          {items.map((item, index) => (
            <ListItemLink
              to={item.to}
              primary={item.primary}
              icon={item.icon}
              key={item.to}
              onClick={onLeafItemClick}
            />
          ))}
        </List>
      </RiverSidebar>
    </>
  );
};
