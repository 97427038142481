import { Config } from "@river/config";
import {
  ICustomer,
  CustomerDto,
  IEnvironment,
  EnvironmentDto,
  IPlatformUser,
  PlatformUserDto,
  IModuleSummary,
  ModuleSummaryDto,
  IEnvOauth2Options,
  EnvOauth2OptionsDto,
  IEnvSaml2Options,
  EnvSaml2OptionsDto,
  IEnvSaml2IdpOptions,
  IEnvSaml2CertOptions,
  IEnvApiKey,
  EnvApiKeyDto,
  ILicense,
  LicenseDto,
  ModuleActionSummaryDto,
  IEnvMobileAppOptions,
  EnvMobileAppOptionsDto,
} from "@river/interfaces";

import { httpService } from "@river/common-ui";

// ----------------
const getCustomers = async (): Promise<ICustomer[]> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers`
  );
  return response.data;
};

const getCustomer = async (customerId: string): Promise<ICustomer> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}`
  );
  return response.data;
};

// ---------------
const createCustomer = async (customerDto: CustomerDto): Promise<ICustomer> => {
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers`,
    customerDto
  );
  return response.data;
};

// ---------------
const updateCustomer = async (
  customerId: string,
  customerDto: CustomerDto
): Promise<ICustomer> => {
  const response = await httpService.put(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}`,
    customerDto
  );
  return response.data;
};

// ----------------
const getEnvironments = async (customerId: string): Promise<IEnvironment[]> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments`
  );
  return response.data;
};

// ---------------
const createCustomerEnvironment = async (
  customerId: string,
  environmentDto: EnvironmentDto
): Promise<ICustomer> => {
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments`,
    environmentDto
  );
  return response.data;
};

// ---------------
const updateCustomerEnvironment = async (
  customerId: string,
  environmentId: string,
  environmentDto: EnvironmentDto
): Promise<IEnvironment> => {
  const response = await httpService.put(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}`,
    environmentDto
  );
  return response.data;
};

// ----------------
const getUsers = async (customerId: string): Promise<IPlatformUser[]> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/users`
  );
  return response.data;
};

// ---------------
const createCustomerUser = async (
  customerId: string,
  userDto: PlatformUserDto
): Promise<IPlatformUser> => {
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/users`,
    userDto
  );
  return response.data;
};

// ----------------
const getCustomerLicenses = async (customerId: string): Promise<ILicense[]> => {
  //
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/licenses`
  );
  return response.data;
};

const createCustomerLicense = async (
  customerId: string,
  licenseDto: LicenseDto
): Promise<ILicense[]> => {
  //
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/licenses`,
    licenseDto
  );
  return response.data;
};

const updateCustomerLicense = async (
  customerId: string,
  licenseId: string,
  licenseDto: LicenseDto
): Promise<ILicense[]> => {
  //
  const response = await httpService.put(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/licenses/${licenseId}`,
    licenseDto
  );
  return response.data;
};

// --------------
const getCustomerModules = async (
  customerId: string,
  licenseId: string
): Promise<IModuleSummary[]> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/licenses/${licenseId}/modules`
  );
  return response.data;
};

// --------------
const changeCustomerModuleStatus = async (
  customerId: string,
  licenseId: string,
  moduleDto: ModuleSummaryDto
): Promise<void> => {
  await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/licenses/${licenseId}/modules`,
    moduleDto
  );
};

// --------------
const changeCustomerModuleActionStatus = async (
  customerId: string,
  licenseId: string,
  module: string,
  moduleActionDto: ModuleActionSummaryDto
): Promise<void> => {
  await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/licenses/${licenseId}/modules/${module}/actions`,
    moduleActionDto
  );
};

// ---------------
const getEnvironmentOauth2Options = async (
  customerId: string,
  environmentId: string
): Promise<IEnvOauth2Options | null> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/oauth2-options`
  );
  return response.data;
};

// ---------------
const saveEnvironmentOauth2Options = async (
  customerId: string,
  environmentId: string,
  oauth2OptionsDto: EnvOauth2OptionsDto
): Promise<IEnvOauth2Options | null> => {
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/oauth2-options`,
    oauth2OptionsDto
  );
  return response.data;
};

// ---------------
const getEnvironmentSaml2Options = async (
  customerId: string,
  environmentId: string
): Promise<IEnvSaml2Options | null> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/saml2-options`
  );
  return response.data;
};

// ---------------
const saveEnvironmentSaml2Options = async (
  customerId: string,
  environmentId: string,
  saml2OptionsDto: EnvSaml2OptionsDto
): Promise<IEnvOauth2Options | null> => {
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/saml2-options`,
    saml2OptionsDto
  );
  return response.data;
};

// --------------
const parseSaml2IdpMetadata = async (
  file: FormData
): Promise<IEnvSaml2IdpOptions> => {
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/saml2-options/idp-metadata`,
    file
  );

  return response.data;
};

// -------------
const parseSaml2IdpCertificate = async (
  file: FormData
): Promise<IEnvSaml2CertOptions> => {
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/saml2-options/idp-certificate`,
    file
  );

  return response.data;
};

// --------------
const downloadServerCertificate = async (): Promise<void> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/saml2-options/server-certificate`,
    { responseType: "blob" }
  );
  //console.log(`Headers: ${JSON.stringify(response.headers, null, 2)}`)
  const fileNameHeader = response.headers["content-disposition"];
  const fileName = fileNameHeader
    ? fileNameHeader.replace(/\w+; ?filename=(.*)/, "$1")
    : "river.crt";
  let blob = new Blob([response.data], {
    type: response.headers["content-type"],
  });

  let file = URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = file;
  a.download = decodeURI(fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// ---------------
const getEnvironmentApiKeys = async (
  customerId: string,
  environmentId: string
): Promise<IEnvApiKey[]> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/api-keys`
  );
  return response.data;
};

// ---------------
const generateEnvironmentApiKey = async (
  customerId: string,
  environmentId: string,
  apiKeyDto: EnvApiKeyDto
): Promise<IEnvApiKey> => {
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/api-keys`,
    apiKeyDto
  );
  return response.data;
};

// ---------------
const regenerateEnvironmentApiKey = async (
  customerId: string,
  environmentId: string,
  apiKeyId: string
): Promise<IEnvApiKey> => {
  const response = await httpService.put(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/api-keys/${apiKeyId}`
  );
  return response.data;
};

// ---------------
const deleteEnvironmentApiKey = async (
  customerId: string,
  environmentId: string,
  apiKeyId: string
): Promise<IEnvApiKey> => {
  const response = await httpService.delete_(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/api-keys/${apiKeyId}`
  );
  return response.data;
};

// ---------------
const getEnvironmentMobileAppInfo = async (
  customerId: string,
  environmentId: string
): Promise<IEnvMobileAppOptions | null> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/mobile-app`
  );
  return response.data;
};

// ---------------
const initEnvironmentMobileApp = async (
  customerId: string,
  environmentId: string,
  envMobileAppOptionsDto?: EnvMobileAppOptionsDto
): Promise<IEnvApiKey> => {
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/mobile-app`,
    envMobileAppOptionsDto || {}
  );
  return response.data;
};

// ---------------
const deleteEnvironmentMobileApp = async (
  customerId: string,
  environmentId: string
): Promise<void> => {
  //
  await httpService.delete_(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/customers/${customerId}/environments/${environmentId}/mobile-app`
  );
};

export const customerService = {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
  getEnvironments,
  createCustomerEnvironment,
  updateCustomerEnvironment,
  getEnvironmentOauth2Options,
  saveEnvironmentOauth2Options,
  getEnvironmentSaml2Options,
  saveEnvironmentSaml2Options,
  parseSaml2IdpMetadata,
  parseSaml2IdpCertificate,
  downloadServerCertificate,
  getUsers,
  createCustomerUser,
  getCustomerLicenses,
  createCustomerLicense,
  updateCustomerLicense,
  getCustomerModules,
  changeCustomerModuleStatus,
  changeCustomerModuleActionStatus,
  getEnvironmentApiKeys,
  generateEnvironmentApiKey,
  regenerateEnvironmentApiKey,
  deleteEnvironmentApiKey,
  getEnvironmentMobileAppInfo,
  initEnvironmentMobileApp,
  deleteEnvironmentMobileApp,
};
