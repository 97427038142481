import { IsNotEmpty, IsIn, IsObject } from 'class-validator';

import { Constants } from '@river/constants';

export class RelationDto {

  @IsNotEmpty()
  relation_name: string;

  @IsNotEmpty()
  child_entity_name: string;

  @IsNotEmpty()
  @IsIn(Object.values(Constants.relation_type))
  relation_type: string = Constants.relation_type.one_to_one; // one-to-one, one-to-many

  @IsNotEmpty()
  lookup: string; // a string representation of lookup pipeline
}
