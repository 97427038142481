import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export function useEnv(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"), {
        noSsr: true,
    });
    return {
        isMobile,
    };
}
