import { IsNotEmpty, IsNumber, IsDate, IsPositive } from "class-validator";

import { IAdapterTimeCard } from "./adapter-timecard.interface";

export class AdapterTimeCardDto
  implements
    Omit<
      IAdapterTimeCard,
      "_id"
      // | "assignment_id"
      // | "CompCode"
      // | "Plant"
      // | "WorkCenterId"
      // | "WorkCenter"
      // | "approved_hours"
      // | "is_confirmed"
    >
{
  @IsNotEmpty()
  folder_id: string;

  // @IsNotEmpty()
  // workorder_id: string;

  @IsNotEmpty()
  operation_id: string;

  @IsNotEmpty()
  @IsDate()
  timecard_date: Date;

  @IsNotEmpty()
  @IsNumber()
  @IsPositive({
    message: "module.execution:validation.completed_hours_validator",
  })
  timecard_hours: number;

  // Other ERP specific attributes
  [s: string]: any;
}
