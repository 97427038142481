import { IsNotEmpty, IsOptional, Matches } from "class-validator";

import { Constants } from "@river/constants";
import { IProperty } from "../common";

/** Data used when creating a custom cron task. */
export class CustomCronTaskCreateDto {
  @IsNotEmpty()
  name: string;

  @IsOptional()
  description?: string;

  @IsNotEmpty()
  task_type: string;

  @IsNotEmpty()
  site_id: any;

  @IsNotEmpty()
  frequency: string;

  @IsOptional()
  action?: string;

  @IsNotEmpty()
  enabled: boolean;
}

/** Data used when updating a custom cron task. */
export class CustomCronTaskUpdateDto {
  @IsOptional()
  name?: string;

  @IsOptional()
  description?: string;

  @IsOptional()
  task_type?: string;

  @IsNotEmpty()
  site_id: any;

  @IsOptional()
  frequency?: string;

  @IsOptional()
  action?: string;

  @IsOptional()
  enabled?: boolean;
}
