import React, { ReactElement, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Protected } from "../";
import { Content } from "../";
import { Sidebar, SidebarContext, ISidebarContent } from "../sidebar";
import { IUseEnv, useEnv, ISidebarCollapsedSetterOpts } from "@river/common-ui";
import sharedStyles from "../shared-styles/shared-styles.module.scss";
import clsx from "clsx";

export const Main: React.FC = (): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const [collapsed, setCollapsed] = useState<boolean>(isMobile);
  const [sidebarContent, setSidebarContent] = useState<ISidebarContent>({
    items: [],
  });
  return (
    <SidebarContext.Provider
      value={{
        collapsed,
        setCollapsed: (opts: ISidebarCollapsedSetterOpts) =>
          setCollapsed(opts.collapsed),
        sidebarContent,
        setSidebarContent,
      }}
    >
      <div
        className={clsx([
          sharedStyles.root,
          { [sharedStyles.mobile]: isMobile },
        ])}
      >
        <Sidebar content={sidebarContent} />
        <Routes>
          <Route
            path="/*"
            element={
              <Protected>
                <Content />
              </Protected>
            }
          />
        </Routes>
      </div>
    </SidebarContext.Provider>
  );
};
