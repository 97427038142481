import { IsNotEmpty, IsInt, Min, Max } from "class-validator";

import { IAdapterShift } from "./adapter-shift.interface";

export class AdapterShiftDto
  implements Omit<IAdapterShift, "_id" | "duration_hours">
{
  @IsNotEmpty()
  shift: string;

  @IsNotEmpty()
  @IsInt()
  @Min(0)
  @Max(23)
  start_hours: number;

  @IsNotEmpty()
  @IsInt()
  @Min(0)
  @Max(59)
  start_minutes: number;

  @IsNotEmpty()
  @IsInt()
  @Min(0)
  @Max(23)
  end_hours: number;

  @IsNotEmpty()
  @IsInt()
  @Min(0)
  @Max(59)
  end_minutes: number;

  // Other ERP specific attributes
  [s: string]: any;
}
