import { IsNotEmpty } from "class-validator";

import { AdapterCrewCraftDto } from "../adapter-crew-craft.dto";

// TODO
export class JdeAdapterCrewCraftDto extends AdapterCrewCraftDto {
  //
  // @IsNotEmpty()
  // WorkCenterId: string;
}
