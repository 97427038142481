import { IsNotEmpty, ValidateNested } from "class-validator";
import { Type } from "class-transformer";

import { StatusDto } from "../common";

export class AdapterFolderStatusDto {
  @IsNotEmpty()
  @ValidateNested()
  @Type(() => StatusDto)
  status: StatusDto;
}
