import { Config } from "@river/config";
import {
  ClusterDto,
  ClusterOptionsDto,
  ICluster,
  IClusterOptions,
} from "@river/interfaces";

import { httpService } from "@river/common-ui";

// ----------------
const getClusters = async (): Promise<ICluster[]> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/clusters`
  );
  return response.data;
};

// ----------------
const getCluster = async (clusterId: string): Promise<ICluster> => {
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/clusters/${clusterId}`
  );
  return response.data;
};

// ---------------
const createCluster = async (clusterDto: ClusterDto): Promise<ICluster> => {
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/clusters`,
    clusterDto
  );
  return response.data;
};

// ---------------
const updateCluster = async (
  clusterId: string,
  clusterDto: ClusterDto
): Promise<ICluster> => {
  const response = await httpService.put(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/clusters/${clusterId}`,
    clusterDto
  );
  return response.data;
};

// ---------------
const getClusterOptions = async (
  clusterId: string
): Promise<IClusterOptions | null> => {
  //
  const response = await httpService.get(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/clusters/${clusterId}/options`
  );
  return response.data;
};

// ---------------
const saveClusterOptions = async (
  clusterId: string,
  clusterOptionsDto: ClusterOptionsDto
): Promise<IClusterOptions> => {
  //
  const response = await httpService.post(
    `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/clusters/${clusterId}/options`,
    clusterOptionsDto
  );
  return response.data;
};

export const clusterService = {
  getClusters,
  getCluster,
  createCluster,
  updateCluster,
  getClusterOptions,
  saveClusterOptions,
};
