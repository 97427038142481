import { IsNotEmpty, IsNumber } from "class-validator";

export abstract class AdapterCrewCraftDto {
  //
  @IsNotEmpty()
  crew: string;

  @IsNotEmpty()
  @IsNumber()
  number_of_units: number = 1;

  // Other ERP specific attributes
  [s: string]: any;
}
