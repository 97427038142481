import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import { Select, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styles from "./river-select.module.scss";
export var DropdownIconVariant;
(function (DropdownIconVariant) {
    DropdownIconVariant[DropdownIconVariant["Classic"] = 0] = "Classic";
    DropdownIconVariant[DropdownIconVariant["Chevron"] = 1] = "Chevron";
})(DropdownIconVariant || (DropdownIconVariant = {}));
const DEFAULT_MIN_WIDTH = "auto";
const DEFAULT_ICON_VARIANT = DropdownIconVariant.Classic;
export const RiverSelect = (props) => {
    var _a, _b, _c;
    const minWidth = `${props.minWidth || DEFAULT_MIN_WIDTH} !important`;
    const dynamicRootStyles = (theme) => ({
        minWidth: props.selectorAutoWidth ? "auto" : minWidth,
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    });
    const [open, setOpen] = React.useState(false);
    const handleChange = (event) => {
        event.stopPropagation();
        const newValue = event.target.value;
        props.onChange(newValue);
    };
    const handleClose = (event) => {
        event.stopPropagation();
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const getIcon = () => {
        const iconVariant = props.iconVariant || DEFAULT_ICON_VARIANT;
        return (props.icon ||
            {
                [DropdownIconVariant.Classic]: ArrowDropDownIcon,
                [DropdownIconVariant.Chevron]: ExpandMoreIcon,
            }[iconVariant]);
    };
    const selectClasses = (_a = props.classes) === null || _a === void 0 ? void 0 : _a.select;
    const menuClasses = (_b = props.classes) === null || _b === void 0 ? void 0 : _b.menu;
    const menuItemClasses = (_c = props.classes) === null || _c === void 0 ? void 0 : _c.menuItem;
    return (_jsx(Select, { sx: dynamicRootStyles, className: clsx([styles.root, props.className]), classes: {
            select: clsx([styles.select, selectClasses === null || selectClasses === void 0 ? void 0 : selectClasses.select]),
            icon: clsx([styles.dropdownIcon, selectClasses === null || selectClasses === void 0 ? void 0 : selectClasses.dropdownIcon]),
        }, open: open, onClose: handleClose, onOpen: handleOpen, value: props.value, onChange: handleChange, IconComponent: getIcon(), MenuProps: {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            transformOrigin: {
                vertical: "top",
                horizontal: "left",
            },
            classes: {
                paper: clsx([styles.paper, menuClasses === null || menuClasses === void 0 ? void 0 : menuClasses.paper]),
                list: clsx([styles.list, menuClasses === null || menuClasses === void 0 ? void 0 : menuClasses.list]),
            },
        }, children: props.items.map((item) => (_jsx(MenuItem, { value: item.value, classes: {
                root: clsx([styles.item, menuItemClasses === null || menuItemClasses === void 0 ? void 0 : menuItemClasses.root]),
                selected: clsx([styles.selected, menuItemClasses === null || menuItemClasses === void 0 ? void 0 : menuItemClasses.selected]),
            }, children: props.renderItem ? props.renderItem(item) : item.label }, item.value))) }));
};
