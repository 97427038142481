import { IsInt, Min, Max, IsOptional, IsNumber } from 'class-validator';

import { IAdapterCalendarDay } from './adapter-calendar-day.interface';

export class AdapterCalendarDay implements IAdapterCalendarDay {

  @IsOptional()
  shift_id?: string | null;

  @IsOptional()
  shift: string;

  @IsOptional()
  duration_hours: number;

  @IsOptional()
  @IsInt()
  @Min(0)
  @Max(23)
  start_hours: number;

  @IsOptional()
  @IsInt()
  @Min(0)
  @Max(59)
  start_minutes: number;

  @IsOptional()
  @IsInt()
  @Min(0)
  @Max(23)
  end_hours: number;

  @IsOptional()
  @IsInt()
  @Min(0)
  @Max(59)
  end_minutes: number;

  @IsOptional()
  @IsNumber()
  available_hours?: number;
}

//export type AdapterCalendarDayDto = AdapterCalendarDay | null;