import { IsNotEmpty, IsInt, IsOptional } from "class-validator";

export class ExternalLinkDto {
  @IsNotEmpty()
  adapter_type: string;

  @IsNotEmpty()
  module: string;

  @IsOptional()
  customer_id?: string;

  @IsOptional()
  environment_id?: string;

  @IsNotEmpty()
  url: string;

  @IsNotEmpty()
  description: string;
}
