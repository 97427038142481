import { IsNotEmpty, IsOptional, IsArray } from "class-validator";
import { IAdapterJobValidationAction } from "./adapter-job-validation-action.interface";

export class AdapterJobValidationActionDto
  implements IAdapterJobValidationAction
{
  // Other ERP specific attributes
  [s: string]: any;

  @IsOptional()
  folder_id?: string;

  @IsNotEmpty()
  entity_name: string;

  @IsOptional()
  @IsArray()
  entity_ids?: string[];

  @IsNotEmpty()
  rule_id: string;

  @IsOptional()
  action_id?: string;

  @IsOptional()
  site_id?: string;
}
