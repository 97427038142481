import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import LogoImage from "./logo-image";
import LogoText from "./logo-text";
import clsx from "clsx";
import styles from "./river-logo-account.module.scss";

interface IRiverLogoAccountProps {
  className?: string;
}

export const RiverLogoAccount: React.FC<IRiverLogoAccountProps> = (
  props
): ReactElement => (
  <Box className={clsx([styles.root, props.className])}>
    <Box className={styles.image}>
      <LogoImage />
    </Box>
    <Box className={styles.text}>
      <LogoText />
    </Box>
  </Box>
);
