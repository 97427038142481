import axiosInstance from "axios";
import xhrAdapter from "axios/lib/adapters/xhr.js";
import qs from "qs";
import { Md5 } from "ts-md5";
import { RiverErrors } from "@river/constants";
import * as localStorageService from "./local-storage.service";
const runningRequests = {};
const noDuplicateRequestsAdapter = (request) => {
    const requestUrl = `${request.baseURL ? request.baseURL : ""}${request.url}`;
    let requestKey = requestUrl + qs.stringify(request.params);
    if (request.data) {
        requestKey = requestKey + Md5.hashStr(request.data);
    }
    // Add the request to runningRequests
    if (!runningRequests[requestKey]) {
        runningRequests[requestKey] = xhrAdapter(request);
    }
    // Return the response promise
    return runningRequests[requestKey].finally(() => {
        // Finally, delete the request from the runningRequests
        delete runningRequests[requestKey];
    });
};
const axios = axiosInstance.create({
    adapter: noDuplicateRequestsAdapter,
});
// Holds a queue of failed requests to be processed once access token is refreshed.
//let failedQueue: Array<any> = [];
let failedQueue = [];
// Processes any outstanding requests in the failed queue.
const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        }
        else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};
let isRefreshing = false;
export const get = axios.get;
export const post = axios.post;
export const put = axios.put;
export const delete_ = axios.delete;
export const setAuthInterceptors = (baseUrl) => {
    axios.interceptors.request.use((config) => {
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        // needed for cookies to be sent back
        config.withCredentials = true;
        return config;
    }, (error) => {
        Promise.reject(error);
    });
    axios.interceptors.response.use((response) => {
        return response;
    }, async (error) => {
        var _a, _b, _c, _d, _e, _f, _g;
        const originalRequest = error.config;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            //
            const jsonData = ((_b = error.request) === null || _b === void 0 ? void 0 : _b.responseType) === "blob"
                ? JSON.parse(await ((_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.text()))
                : ((_e = error.request) === null || _e === void 0 ? void 0 : _e.responseType) === "arraybuffer"
                    ? JSON.parse(new TextDecoder().decode((_f = error.response) === null || _f === void 0 ? void 0 : _f.data))
                    : (_g = error.response) === null || _g === void 0 ? void 0 : _g.data;
            if ((jsonData === null || jsonData === void 0 ? void 0 : jsonData.river_error_code) === RiverErrors.AUTH.ACCESS_TOKEN_EXPIRED) {
                if (originalRequest._retried) {
                    return Promise.reject(error);
                }
                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    })
                        .then((token) => {
                        originalRequest.headers["Authorization"] = "Bearer " + token;
                        return axios(originalRequest);
                    })
                        .catch((err) => {
                        return Promise.reject(err);
                    });
                }
                isRefreshing = true;
                // fetch the token
                return new Promise((resolve, reject) => {
                    axios
                        .get(baseUrl + "/auth/refresh")
                        .then((result) => {
                        const accessToken = result.data.access_token;
                        localStorageService.setAccessToken(result.data.access_token);
                        isRefreshing = false;
                        originalRequest.headers["Authorization"] =
                            "Bearer " + accessToken;
                        originalRequest._retried = true;
                        resolve(axios(originalRequest));
                        processQueue(null, accessToken);
                    })
                        .catch((err) => {
                        processQueue(err, null);
                        reject(err);
                        window.location.href = "/logout";
                    });
                });
            }
        }
        return Promise.reject(error);
    });
};
