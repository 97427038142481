import { IsNotEmpty, IsArray } from "class-validator";

import { IAdapterCompletionDefaultsAction } from "./adapter-completion-defaults-action.interface";

export class AdapterCompletionDefaultsActionDto
  implements IAdapterCompletionDefaultsAction
{
  @IsNotEmpty()
  @IsArray()
  entity_ids: string[];
}
