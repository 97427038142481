import { IsNumber, IsOptional, IsArray, ValidateNested } from 'class-validator';

import { IQuerySimple } from './query-simple.interface';


export class QuerySimpleDto implements IQuerySimple {
  @IsOptional()
  @IsNumber()
  $skip?: number;

  @IsOptional()
  @IsNumber()
  $limit?: number;

  @IsOptional()
  $search?: string;

  @IsOptional()
  $expand?: string | string[];

  @IsOptional()
  $suppress?: string | string[];

  @IsOptional()
  $unwind?: string | string[];
}