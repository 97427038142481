import { IsNotEmpty, IsNumber } from "class-validator";

import { AdapterCrewCraftDto } from "../adapter-crew-craft.dto";

export class OracleEbsAdapterCrewCraftDto extends AdapterCrewCraftDto {
  //
  @IsNotEmpty()
  @IsNumber()
  RESOURCE_ID: number;

  @IsNotEmpty()
  @IsNumber()
  DEPARTMENT_ID: number;
}
