import { Button, Box } from "@mui/material";
import {
  RiverDialogButton,
  RiverPasswordInput,
  RiverTextInput,
  useNotification,
  useValidation,
  IUseValidation,
  IValidationErrors,
  RiverSpinner,
  RiverCheckbox,
} from "@river/common-ui";
import { EnvSaml2OptionsDto, IEnvSaml2Options } from "@river/interfaces";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { customerService } from "../../../services";
import { SubHeader } from "../../sub-header";
import { CustomerContext } from "../../customer";
import { EnvironmentContext } from "../../customer-environment";
import { PageHeader } from "../../page-header";
import sharedStyles from "../../shared-styles/shared-styles.module.scss";
import styles from "./environment-saml2-configuration.module.scss";
import clsx from "clsx";

export const EnvironmentSaml2Configuration: React.FC = () => {
  const environmentHandle: string = useParams().environmentHandle!;

  const { customer } = useContext(CustomerContext)!;
  const { environment } = useContext(EnvironmentContext)!;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validation: IUseValidation = useValidation();
  const [saml2ConfigurationObject, setSaml2ConfigurationObject] =
    useState<EnvSaml2OptionsDto>(new EnvSaml2OptionsDto());

  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });

  const notify = useNotification();

  useEffect(() => {
    fetchEnvironmentSaml2Options();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEnvironmentSaml2Options = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const environmentSaml2Options: IEnvSaml2Options | null =
        await customerService.getEnvironmentSaml2Options(
          customer!._id,
          environment!._id
        );
      if (environmentSaml2Options) {
        Object.setPrototypeOf(
          environmentSaml2Options,
          Object.getPrototypeOf(saml2ConfigurationObject)
        );

        setSaml2ConfigurationObject(environmentSaml2Options);
      }
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const saveEnvironmentSaml2Options = async () => {
    const errors: IValidationErrors = await validateEnvironment(
      saml2ConfigurationObject
    );
    if (errors.list.length > 0) {
      return;
    }
    try {
      await customerService
        .saveEnvironmentSaml2Options(
          customer!._id,
          environment!._id,
          saml2ConfigurationObject
        )
        .then((data) => {
          notify.success(`Successfully saved SAML2 Configuration`);
        });
    } catch (message) {
      notify.error({ message });
    }
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const newState: EnvSaml2OptionsDto = {
      ...saml2ConfigurationObject,
      [event.target.name!]: event.target.value,
    };
    Object.setPrototypeOf(
      newState,
      Object.getPrototypeOf(saml2ConfigurationObject)
    );
    setSaml2ConfigurationObject(newState);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const onCheckboxChange = (
    event: React.ChangeEvent<{ name?: string }>
  ): void => {
    const newState: EnvSaml2OptionsDto = {
      ...saml2ConfigurationObject,
      [event.target.name!]: !saml2ConfigurationObject[event.target.name!],
    };
    Object.setPrototypeOf(
      newState,
      Object.getPrototypeOf(saml2ConfigurationObject)
    );
    setSaml2ConfigurationObject(newState);
  };

  const validateEnvironment = async (
    environment: EnvSaml2OptionsDto
  ): Promise<IValidationErrors> => {
    const errors = await validation.validateFields(environment);
    setValidationErrors(errors);
    return errors;
  };

  const parseIdpMetadata = async (file: File): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const data = await customerService.parseSaml2IdpMetadata(formData);
      const newSaml2Options: EnvSaml2OptionsDto = {
        ...saml2ConfigurationObject,
        ...data,
      };
      Object.setPrototypeOf(
        newSaml2Options,
        Object.getPrototypeOf(saml2ConfigurationObject)
      );
      setSaml2ConfigurationObject(newSaml2Options);
    } catch (message) {
      notify.error({ message });
    }
  };

  const parseIdpCertificate = async (file: File): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const data = await customerService.parseSaml2IdpCertificate(formData);
      const newSaml2Options: EnvSaml2OptionsDto = {
        ...saml2ConfigurationObject,
        ...data,
      };
      Object.setPrototypeOf(
        newSaml2Options,
        Object.getPrototypeOf(saml2ConfigurationObject)
      );
      setSaml2ConfigurationObject(newSaml2Options);
    } catch (message) {
      notify.error({ message });
    }
  };

  const exportServerCertificate = async (): Promise<void> => {
    await customerService.downloadServerCertificate();
  };

  const renderLoginUrlField = (): ReactElement => (
    <RiverTextInput
      id="idp_login_url"
      label="Login Url"
      value={saml2ConfigurationObject.idp_login_url}
      className={sharedStyles.input}
      fullWidth
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["idp_login_url"]}
    />
  );
  const renderIssuerField = (): ReactElement => (
    <RiverTextInput
      id="idp_issuer"
      label="Issuer"
      value={saml2ConfigurationObject.idp_issuer}
      className={sharedStyles.input}
      fullWidth
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["idp_issuer"]}
    />
  );
  const renderCertificateField = (): ReactElement => (
    <RiverTextInput
      id="idp_certificate_subject"
      label="Certificate"
      value={saml2ConfigurationObject.idp_certificate_subject}
      className={sharedStyles.input}
      fullWidth
      disabled
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["idp_certificate_subject"]}
    />
  );
  const renderReplyUrlField = (): ReactElement => (
    <RiverTextInput
      id="idp_reply_url"
      label="Reply URL"
      value={saml2ConfigurationObject.idp_reply_url}
      className={sharedStyles.input}
      fullWidth
      disabled
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["idp_reply_url"]}
    />
  );
  const renderMetadataSignedField = (): ReactElement => (
    <RiverCheckbox
      id="want_metadata_signed"
      label="Metadata Signed?"
      checked={saml2ConfigurationObject.want_metadata_signed}
      onChangeEvent={(event) => onCheckboxChange(event)}
    />
  );
  const renderAssertionsSignedField = (): ReactElement => (
    <RiverCheckbox
      id="want_assertions_signed"
      label="Assertions Signed?"
      checked={saml2ConfigurationObject.want_assertions_signed}
      onChangeEvent={(event) => onCheckboxChange(event)}
    />
  );
  const renderAuthRespnseSignedField = (): ReactElement => (
    <RiverCheckbox
      id="want_auth_response_signed"
      label="Authentication Response Signed?"
      checked={saml2ConfigurationObject.want_auth_response_signed}
      onChangeEvent={(event) => onCheckboxChange(event)}
    />
  );
  const renderTargetIssuerField = (): ReactElement => (
    <RiverTextInput
      id="target_issuer"
      label="Issuer"
      value={saml2ConfigurationObject.target_issuer}
      className={sharedStyles.input}
      fullWidth
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["target_issuer"]}
    />
  );
  const renderTargetAudeienceField = (): ReactElement => (
    <RiverTextInput
      id="target_audience"
      label="Audience"
      value={saml2ConfigurationObject.target_audience}
      className={sharedStyles.input}
      fullWidth
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["target_audience"]}
    />
  );
  const renderTargetReplyUrlField = (): ReactElement => (
    <RiverTextInput
      id="target_reply_url"
      label="Reply URL"
      value={saml2ConfigurationObject.target_reply_url}
      className={sharedStyles.input}
      fullWidth
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["target_reply_url"]}
    />
  );
  const renderTargetScopeField = (): ReactElement => (
    <RiverTextInput
      id="target_scope"
      label="Scope"
      value={saml2ConfigurationObject.target_scope}
      className={sharedStyles.input}
      fullWidth
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["target_scope"]}
    />
  );
  const renderTargetCliendIDField = (): ReactElement => (
    <RiverTextInput
      id="client_id"
      label="Client ID"
      value={saml2ConfigurationObject.client_id}
      className={sharedStyles.input}
      fullWidth
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["client_id"]}
    />
  );
  const renderTargetClientSecretField = (): ReactElement => (
    <RiverPasswordInput
      id="client_secret"
      label={"Client Secret"}
      value={saml2ConfigurationObject.client_secret}
      className={sharedStyles.input}
      fullWidth
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["client_secret"]}
      autoComplete={false}
    />
  );
  return (
    <>
      <PageHeader title={"Environments"} />
      <SubHeader subtitle={`${environmentHandle!} - SAML2 Configuration`} />
      <Box className={sharedStyles.viewContent}>
        <div className={sharedStyles.form}>
          <form
            className={clsx(sharedStyles.formSection)}
            encType="multipart/form-data"
          >
            <div className={sharedStyles.sectionHeader}>
              <div className={sharedStyles.sectionTitle}>IDP Configuration</div>
              <Button
                variant="contained"
                component="label"
                className={clsx([sharedStyles.button])}
              >
                LOAD IDP METADATA...
                <input
                  type="file"
                  hidden
                  accept=".xml"
                  onChange={(e) => {
                    if (e.target.files) {
                      parseIdpMetadata(e.target.files[0]);
                    }
                  }}
                />
              </Button>
            </div>
            {renderLoginUrlField()}
            {renderIssuerField()}
            <div className={styles.certificateInput}>
              {renderCertificateField()}
              <Button
                variant="contained"
                component="label"
                className={sharedStyles.button}
              >
                Upload...
                <input
                  type="file"
                  hidden
                  // accept=".*"
                  onChange={(e) => {
                    if (e.target.files) {
                      parseIdpCertificate(e.target.files[0]);
                    }
                  }}
                />
              </Button>
            </div>
            {renderReplyUrlField()}
            {renderMetadataSignedField()}
            {renderAssertionsSignedField()}
            {renderAuthRespnseSignedField()}
          </form>
          <form className={clsx(sharedStyles.formSection)}>
            <div className={sharedStyles.sectionHeader}>
              <div className={sharedStyles.sectionTitle}>
                Target ERP Configuration / Identity Propagation
              </div>
            </div>
            {renderTargetIssuerField()}
            {renderTargetAudeienceField()}
            {renderTargetReplyUrlField()}
            {renderTargetScopeField()}
            {renderTargetCliendIDField()}
            {renderTargetClientSecretField()}
          </form>
          <div className={sharedStyles.buttonGroup}>
            <RiverDialogButton
              text="Save"
              variant="contained"
              className={sharedStyles.button}
              onClick={saveEnvironmentSaml2Options}
            />
            <RiverDialogButton
              text="Export Certificate"
              variant="contained"
              className={clsx([sharedStyles.button])}
              onClick={exportServerCertificate}
            />
          </div>
        </div>
      </Box>
      <RiverSpinner show={isLoading} />
    </>
  );
};
