import React, { useState, useEffect, useContext } from "react";
import { IEnvironment } from "@river/interfaces";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import { customerService } from "../../services";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { Protected } from "../protected";
import {
  EnvironmentApiKeys,
  EnvironmentDetails,
  EnvironmentMobile,
  EnvironmentOAuth2Configuration,
  EnvironmentSaml2Configuration,
} from "../environment";
import { EnvironmentContext } from "./environment.context";
import { CustomerContext } from "../customer";
import { getEnvironmentSidebarContent } from "../customer-environments/sidebar-content";
import { SidebarContext } from "../sidebar";

export const CustomerEnvironment: React.FC = () => {
  const environmentHandle: string = useParams().environmentHandle!;
  const sidebarContext = useContext(SidebarContext);

  const notify = useNotification();
  const { customer } = useContext(CustomerContext)!;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [environment, setEnvironment] = useState<IEnvironment | null>(null);

  const loadEnvironment = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const environments: IEnvironment[] =
        await customerService.getEnvironments(customer!._id);
      const env: IEnvironment = environments.find(
        (env) => env.handle === environmentHandle
      )!;
      setEnvironment(env);
      sidebarContext?.setSidebarContent(
        getEnvironmentSidebarContent(customer!, env)
      );
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadEnvironment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EnvironmentContext.Provider
      value={{
        environment,
        setEnvironment,
      }}
    >
      <RiverSpinner show={isLoading} />
      {environment && (
        <Routes>
          <Route path={`/*`} element={<Navigate to={`details`} />} />
          <Route
            path={`/details`}
            element={
              <Protected>
                <EnvironmentDetails />
              </Protected>
            }
          />
          <Route
            path={`/saml2_configuration`}
            element={
              <Protected>
                <EnvironmentSaml2Configuration />
              </Protected>
            }
          />
          <Route
            path={`/oauth2_configuration`}
            element={
              <Protected>
                <EnvironmentOAuth2Configuration />
              </Protected>
            }
          />
          <Route
            path={`/api_keys`}
            element={
              <Protected>
                <EnvironmentApiKeys />
              </Protected>
            }
          />
          <Route
            path={`/mobile`}
            element={
              <Protected>
                <EnvironmentMobile />
              </Protected>
            }
          />
        </Routes>
      )}
    </EnvironmentContext.Provider>
  );
};
