import { IsNotEmpty, IsOptional } from "class-validator";
import { oauth2ReplyUrl } from "@river/config";

export class EnvOauth2OptionsDto {
  @IsNotEmpty()
  login_url: string = "https://<server>:<port>/sap/bc/sec/oauth2/authorize";

  @IsNotEmpty()
  reply_url: string = oauth2ReplyUrl("<subdomain>");

  @IsNotEmpty()
  token_url: string = "https://<server>:<port>/sap/bc/sec/oauth2/token";

  @IsOptional()
  scope?: string =
    "/RIVER/ATTA_SRV_0001 /RIVER/LIST_SRV_0001 /RIVER/LOOKUP_SRV_0001 /RIVER/MO_CONF_SRV_0001 /RIVER/NET2WO_SRV_0001 /RIVER/NOTIF_SRV_0001 /RIVER/OQUEUE_SRV_0001 /RIVER/TO_SRV_0001 /RIVER/WO_SRV_0001 /RIVER/NETWORK_SRV_0001 /RIVER/MAT_DOC_SRV_0001";

  @IsNotEmpty()
  grant_type: string = "authorization_code";

  @IsNotEmpty()
  client_id: string = "";

  @IsNotEmpty()
  client_secret: string = "";
}
