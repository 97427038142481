import { IsNotEmpty, IsOptional } from "class-validator";

export class EnvTranslatableDto {
  @IsNotEmpty()
  namespace: string;

  @IsNotEmpty()
  key: string;

  @IsOptional()
  content?: string;
}
