import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
  IUseValidation,
  RiverDialogButton,
  RiverPasswordInput,
  RiverTextInput,
  useNotification,
  useValidation,
  IValidationErrors,
} from "@river/common-ui";
import { Box } from "@mui/material";
import { ClusterOptionsDto } from "@river/interfaces";
import { clusterService } from "../../../services";
import { SubHeader } from "../../sub-header";
import { ClusterContext } from "../cluster.context";
import { PageHeader } from "../../page-header";
import sharedStyles from "../../shared-styles/shared-styles.module.scss";

export const ClusterOptions: React.FC = () => {
  const { cluster } = useContext(ClusterContext)!;
  const [clusterOptionsDto, setClusterOptionsDto] = useState<ClusterOptionsDto>(
    new ClusterOptionsDto()
  );

  const validation: IUseValidation = useValidation();
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });

  const notify = useNotification();

  useEffect(() => {
    fetchClusterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateClusterOptions = async (
    clusterOptions: ClusterOptionsDto
  ): Promise<IValidationErrors> => {
    const errors = await validation.validateFields(clusterOptions);
    setValidationErrors(errors);
    return errors;
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const newState: ClusterOptionsDto = {
      ...clusterOptionsDto,
      [event.target.name!]: event.target.value,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(clusterOptionsDto));
    setClusterOptionsDto(newState);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const submitClusterOptions = async () => {
    const errors: IValidationErrors = await validateClusterOptions(
      clusterOptionsDto
    );
    if (errors.list.length > 0) {
      return;
    }
    try {
      await clusterService.saveClusterOptions(cluster!._id, clusterOptionsDto);
      notify.success(`Cluster Options have been successfully saved`);
    } catch (message) {
      notify.error({ message });
    }
  };

  const fetchClusterOptions = async () => {
    try {
      const result = await clusterService.getClusterOptions(cluster!._id);
      if (result) {
        Object.setPrototypeOf(result, Object.getPrototypeOf(clusterOptionsDto));
        setClusterOptionsDto(result);
      }
    } catch (message) {
      notify.error({ message });
    }
  };

  const renderProjectIdField = (): ReactElement => (
    <RiverTextInput
      id={"project_id"}
      label={"Project Id"}
      value={clusterOptionsDto.project_id}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["project_id"]}
      fullWidth
      className={sharedStyles.input}
    />
  );
  const renderProjectNameField = (): ReactElement => (
    <RiverTextInput
      id={"project_name"}
      label={"Project Name"}
      value={clusterOptionsDto.project_name}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["project_name"]}
      fullWidth
      className={sharedStyles.input}
    />
  );

  const renderApiKeyNameField = (): ReactElement => (
    <RiverTextInput
      id={"api_key_name"}
      label={"API Key Name"}
      value={clusterOptionsDto.api_key_name}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["api_key_name"]}
      fullWidth
      className={sharedStyles.input}
    />
  );
  const renderApiKeyValueField = (): ReactElement => (
    <RiverPasswordInput
      id={"api_key_value"}
      label={"API Key Value"}
      value={clusterOptionsDto.api_key_value}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["api_key_value"]}
      fullWidth
      className={sharedStyles.input}
      autoComplete={false}
    />
  );
  return (
    <>
      <PageHeader title={"DB Clusters"} />
      <SubHeader subtitle={`${cluster!.cluster} - Options`} />
      <Box className={sharedStyles.viewContent}>
        <div className={sharedStyles.form}>
          {renderProjectIdField()}
          {renderProjectNameField()}
          {renderApiKeyNameField()}
          {renderApiKeyValueField()}
          <div className={sharedStyles.buttonGroup}>
            <RiverDialogButton
              text="Save"
              variant="contained"
              className={sharedStyles.button}
              onClick={submitClusterOptions}
            />
          </div>
        </div>
      </Box>
    </>
  );
};
