export const RiverErrors = {
  SYSTEM: {
    UNEXPECTED: "ERROR.SYSTEM.UNEXPECTED",
  },

  AUTH: {
    INVALID_CREDENTIALS: "ERROR.AUTH.INVALID_CREDENTIALS",
    UNAUTHORIZED: "ERROR.AUTH.UNAUTHORIZED",
    ACCESS_TOKEN_EXPIRED: "ERROR.AUTH.ACCESS_TOKEN_EXPIRED",
    REFRESH_TOKEN_EXPIRED: "ERROR.AUTH.REFRESH_TOKEN_EXPIRED",
    ADAPTER_USER_NOT_CONFIGURED: "ERROR.AUTH.ADAPTER_USER_NOT_CONFIGURED",
  },

  ADAPTER: {
    INVALID_CONNECTION_STRING: "ERROR.ADAPTER.INVALID_CONNECTION_STRING",

    INVALID_ENTITY_NAME: "ERROR.ADAPTER.INVALID_ENTITY_NAME",
    INVALID_MODEL_NAME: "ERROR.ADAPTER.INVALID_MODEL_NAME",
    INVALID_RECORD_ID: "ERROR.ADAPTER.INVALID_RECORD_ID",
    INVALID_VALUE: "ERROR.ADAPTER.INVALID_VALUE",

    CANNOT_SYNC_ENTITY: "ERROR.ADAPTER.CANNOT_SYNC_ENTITY",
    CANNOT_CREATE_ENTITY: "ERROR.ADAPTER.CANNOT_CREATE_ENTITY",
    CANNOT_UPDATE_ENTITY: "ERROR.ADAPTER.CANNOT_UPDATE_ENTITY",
    CANNOT_DELETE_ENTITY: "ERROR.ADAPTER.CANNOT_DELETE_ENTITY",
    CANNOT_SEARCH_ENTITY: "ERROR.ADAPTER.CANNOT_SEARCH_ENTITY",

    RECORD_NOT_FOUND: "ERROR.ADAPTER.RECORD_NOT_FOUND",
  },

  ADAPTER_FOLDER: {
    START_AFTER_END: "ERROR.ADAPTER_FOLDER.START_AFTER_END",
    INVALID_STATUS: "ERROR.ADAPTER_FOLDER.INVALID_STATUS",
  },

  ADAPTER_SCHEDULING: {
    INVALID_ENTITY_NAME: "ERROR.ADAPTER_SCHEDULING.INVALID_ENTITY_NAME",
    NO_OPTIMAL_AUTO_SCHEDULE_SOLUTION:
      "ERROR.ADAPTER_SCHEDULING.NO_OPTIMAL_AUTO_SCHEDULE_SOLUTION",
  },

  ADAPTER_USER_STATUS: {
    EMPTY_STATUS_PROFILE: "ERROR.ADAPTER_USER_STATUS.EMPTY_STATUS_PROFILE",
    DIFF_STATUS_PROFILES: "ERROR.ADAPTER_USER_STATUS.DIFF_STATUS_PROFILES",
    NETWORK_ORDERS_NOT_SUPPORTED:
      "ERROR.ADAPTER_USER_STATUS.NETWORK_ORDERS_NOT_SUPPORTED",
  },

  PLATFORM: {
    DATA_DICT: {
      ENTITY_ALREADY_EXISTS: "ERROR.PLATFORM.DATA_DICT.ENTITY_ALREADY_EXISTS",
      ATTRIBUTE_ALREADY_EXISTS:
        "ERROR.PLATFORM.DATA_DICT.ATTRIBUTE_ALREADY_EXISTS",
      RELATION_ALREADY_EXISTS:
        "ERROR.PLATFORM.DATA_DICT.RELATION_ALREADY_EXISTS",
      INDEX_ALREADY_EXISTS: "ERROR.PLATFORM.DATA_DICT.INDEX_ALREADY_EXISTS",

      ENTITY_NOT_FOUND: "ERROR.PLATFORM.DATA_DICT.ENTITY_NOT_FOUND",
      ATTRIBUTE_NOT_FOUND: "ERROR.PLATFORM.DATA_DICT.ATTRIBUTE_NOT_FOUND",
      RELATION_NOT_FOUND: "ERROR.PLATFORM.DATA_DICT.RELATION_NOT_FOUND",
      INDEX_NOT_FOUND: "ERROR.PLATFORM.DATA_DICT.INDEX_NOT_FOUND",

      CANNOT_DELETE_ENTITY: "ERROR.PLATFORM.DATA_DICT.CANNOT_DELETE_ENTITY",
      CANNOT_DELETE_ATTRIBUTE:
        "ERROR.PLATFORM.DATA_DICT.CANNOT_DELETE_ATTRIBUTE",
      CANNOT_DELETE_RELATION: "ERROR.PLATFORM.DATA_DICT.CANNOT_DELETE_RELATION",
      CANNOT_DELETE_INDEX: "ERROR.PLATFORM.DATA_DICT.CANNOT_DELETE_INDEX",
    },
    CLUSTERS: {
      NO_CLUSTER_OPTIONS: "ERROR.PLATFORM.CLUSTERS.NO_CLUSTER_OPTIONS",
    },
    MOBILE: {
      APP_ALREADY_CONFIGURED: "ERROR.PLATFORM.MOBILE.APP_ALREADY_CONFIGURED",
    },
  },

  ATTACHMENTS: {
    FILE_NOT_READY_FOR_DOWNLOAD:
      "ERROR.ATTACHMENTS.FILE_NOT_READY_FOR_DOWNLOAD",
    FILE_UPLOAD_ERROR: "ERROR.ATTACHMENTS.FILE_UPLOAD_ERROR",
    UNKNOWN_ATTACHMENT_TYPE: "ERROR.ATTACHMENTS.UNKNOWN_ATTACHMENT_TYPE",
  },

  REPORTS: {
    INVALID_REPORT_TYPE: "ERROR.REPORTS.INVALID_REPORT_TYPE",
    FILE_NOT_READY_FOR_DOWNLOAD: "ERROR.REPORTS.FILE_NOT_READY_FOR_DOWNLOAD",
    INVALID_OPTION: "ERROR.REPORTS.INVALID_REPORT_TYPE.INVALID_OPTION",
  },

  CRON_TASK: {
    HANDLER_NOT_FOUND: "ERROR.CRON_TASK.HANDLER_NOT_FOUND",
    CRON_TASK_NOT_FOUND: "ERROR.CRON_TASK.CRON_TASK_NOT_FOUND",
    INVALID_OPTION: "ERROR.CRON_TASK.INVALID_OPTION",
  },

  CONSTANTS: {
    UNKNOWN_CONSTANT: "ERROR.CONSTANT.UNKNOWN_CONSTANT",
  },

  ROLE_RESTRICTION: {
    INVALID_EXPRESSION: "ERROR.ROLE_RESTRICTION.INVALID_EXPRESSION",
  },

  // SYNC: {
  //   ENTITY_COLLECTION_MISSING             : 'ERROR.SYNC.ENTITY_COLLECTION_MISSING'
  // }
};
