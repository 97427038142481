import { IsNotEmpty, IsInt, Min } from "class-validator";

import { IAdapterTimelineFoldersAction } from "./adapter-timeline-folders-action.interface";

export class AdapterTimelineFoldersActionDto
  implements IAdapterTimelineFoldersAction
{
  @IsNotEmpty()
  timeline_id: string;

  @IsNotEmpty()
  @IsInt()
  @Min(1)
  number_of_folders: number;
}
