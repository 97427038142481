import { IsNotEmpty, IsDate, IsArray } from "class-validator";

export class AdapterAvailabilityGenerationDto {
  @IsNotEmpty()
  calendar_id: string;

  @IsNotEmpty()
  @IsDate()
  start_date: Date;

  @IsNotEmpty()
  @IsDate()
  end_date: Date;

  @IsNotEmpty()
  @IsArray()
  availability_header_ids: string[];
}
