import React, { ReactElement } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import clsx from "clsx";
import styles from "./list-item-link.module.scss";

export interface IListItemLink {
  icon?: React.FC;
  primary: any;
  to: string;
  classes?: {
    icon?: any;
  };
  onClick?: () => void;
}

export const ListItemLink = (props: IListItemLink): ReactElement => {
  const { icon, primary, to } = props;
  const location = useLocation();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  const onClick = (): void => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        classes={{ root: styles.root, selected: styles.selected }}
        selected={location.pathname === to}
        onClick={() => onClick()}
      >
        {icon ? (
          <ListItemIcon
            classes={{ root: clsx([styles.icon, props.classes?.icon]) }}
          >
            {React.createElement(icon)}
          </ListItemIcon>
        ) : null}
        <ListItemText classes={{ primary: styles.text }} primary={primary} />
      </ListItem>
    </li>
  );
};
