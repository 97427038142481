import React, { SVGProps } from "react";

const ArrowRightIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.590027 10.59L5.17003 6L0.590027 1.41L2.00003 0L8.00003 6L2.00003 12L0.590027 10.59Z"
        fill="#828282"
      />
    </svg>
  );
};

export default ArrowRightIcon;
