import React, { ReactElement, useContext, useState } from "react";
import {
  IUseValidation,
  RiverDialogButton,
  RiverTextInput,
  useNotification,
  useValidation,
  IValidationErrors,
} from "@river/common-ui";
import { Box } from "@mui/material";
import { ClusterDto } from "@river/interfaces";
import { clusterService } from "../../../services";
import { SubHeader } from "../../sub-header";
import { ClusterContext } from "../cluster.context";
import { PageHeader } from "../../page-header";
import sharedStyles from "../../shared-styles/shared-styles.module.scss";

export const ClusterDetails: React.FC = () => {
  const { cluster, setCluster } = useContext(ClusterContext)!;
  const [clusterDto, setClusterDto] = useState<ClusterDto>(
    Object.assign(new ClusterDto(), cluster)
  );

  const validation: IUseValidation = useValidation();
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });
  const notify = useNotification();

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const newState: ClusterDto = {
      ...clusterDto,
      [event.target.name!]: event.target.value,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(clusterDto));
    setClusterDto(newState);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const validateCluster = async (
    cluster: ClusterDto
  ): Promise<IValidationErrors> => {
    const errors = await validation.validateFields(cluster);
    setValidationErrors(errors);
    return errors;
  };

  const submitCluster = async (): Promise<void> => {
    const errors: IValidationErrors = await validateCluster(clusterDto);
    if (errors.list.length > 0) {
      return;
    }
    try {
      await clusterService
        .updateCluster(cluster!._id, clusterDto)
        .then((data) => {
          setCluster(data);
        });
      notify.success(`Cluster has been successfully updated`);
    } catch (message) {
      notify.error({ message });
    }
  };

  const renderClusterNameField = (): ReactElement => (
    <RiverTextInput
      id="cluster"
      label="Cluster"
      value={clusterDto.cluster}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["cluster"]}
      fullWidth
      className={sharedStyles.input}
    />
  );

  const renderDescriptionField = (): ReactElement => (
    <RiverTextInput
      id={"description"}
      label={"Description..."}
      value={clusterDto?.description || ""}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["description"]}
      inputProps={{
        multiline: true,
        minRows: 5,
      }}
      fullWidth
      className={sharedStyles.input}
    />
  );

  const renderConnectionStringField = (): ReactElement => (
    <RiverTextInput
      id={"connection_string"}
      label={"Connection String"}
      value={clusterDto?.connection_string || ""}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["connection_string"]}
      fullWidth
      className={sharedStyles.input}
    />
  );

  const renderConnectionOptionsField = (): ReactElement => (
    <RiverTextInput
      id={"connection_options"}
      label={"Connection Options"}
      value={clusterDto?.connection_options || ""}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["connection_options"]}
      fullWidth
      className={sharedStyles.input}
    />
  );

  return (
    <>
      <PageHeader title={"DB Clusters"} />
      <SubHeader subtitle={`${clusterDto.cluster} - Details`} />
      <Box className={sharedStyles.viewContent}>
        <div className={sharedStyles.form}>
          {renderClusterNameField()}
          {renderDescriptionField()}
          {renderConnectionStringField()}
          {renderConnectionOptionsField()}
          <div className={sharedStyles.buttonGroup}>
            <RiverDialogButton
              text="Save"
              variant="contained"
              className={sharedStyles.button}
              onClick={submitCluster}
            />
          </div>
        </div>
      </Box>
    </>
  );
};
