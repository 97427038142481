import { IsNotEmpty, IsOptional, IsArray } from "class-validator";

import { StatusDto } from "../common";

export class ModuleSummaryDto {
  @IsNotEmpty()
  module: string;

  @IsNotEmpty()
  status: StatusDto;

  // @IsOptional()
  // @IsArray()
  // @Type(() => ModuleActionSummaryDto)
  // actions: ModuleActionSummaryDto[];
}
