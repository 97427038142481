import {
  IsOptional,
  IsBoolean,
  IsDate,
  IsNotEmpty,
  IsObject,
} from "class-validator";
import { IAdapterSchedulingActionBase } from "./adapter-scheduling-action-base.interface";

export abstract class AdapterSchedulingActionBaseDto
  implements IAdapterSchedulingActionBase
{
  @IsOptional()
  @IsDate()
  start_date?: Date;

  // crew, shift/calendar, other parameters...

  //object_type: 'workorder' | 'operation' | 'assignment';
  @IsNotEmpty()
  folder_id: string; // In case of SAP => 'workorder' | 'operation' | 'assignment'

  @IsNotEmpty()
  entity_name: string; // In case of SAP => 'workorder' | 'operation' | 'assignment'

  @IsOptional()
  calendar_id?: string;

  @IsOptional()
  @IsBoolean()
  remove_calendar?: boolean = false;

  @IsOptional()
  crew?: string;

  @IsOptional()
  @IsBoolean()
  breakin?: boolean;

  @IsOptional()
  @IsBoolean()
  auto_post?: boolean;

  @IsOptional()
  @IsObject()
  assignment?: any; //assignment details (if scheduling an assignment)
}
