import React, { useState, useEffect } from "react";
import * as i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { Routes, Route } from "react-router-dom";

import "reflect-metadata"; //necessary for class-validator/class-transformer to work properly

import { IAccessTokenPayload } from "@river/interfaces";

import {
  Logout,
  ResetPassword,
  NotFound,
  NotificationContainer,
  NotificationProvider,
  defaultTheme,
  authService,
  httpService,
} from "@river/common-ui";

import { Config } from "@river/config";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";

import { PlatformLogin, Main, Protected } from "./components";
import { PlatformUserContext } from "./context";

// necessary for Login component
i18n.use(initReactI18next).init({
  react: {
    useSuspense: false,
  },
});

const App: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const [user, setUser] = useState<IAccessTokenPayload>(
    authService.getCurrentUser() || {
      user_id: "",
      display_name: "",
    }
  );
  useEffect(() => {
    httpService.setAuthInterceptors(
      Config.web.REACT_APP_PLATFORM_API_URL + "/api/platform"
    );
    setIsInitialized(true);
  }, []);

  const onLoggedIn = (user: IAccessTokenPayload | null) => setUser(user!);

  return (
    <>
      {isInitialized && (
        <>
          <CssBaseline />
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={defaultTheme}>
              <NotificationProvider>
                <PlatformUserContext.Provider
                  value={{
                    user,
                    onLoggedIn: onLoggedIn,
                  }}
                >
                  <Routes>
                    <Route
                      path="/login"
                      element={
                        <PlatformLogin
                          apiUrl={`${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/auth/login`}
                          onLoggedIn={onLoggedIn}
                        />
                      }
                    />
                    <Route
                      path="/logout"
                      element={
                        <Logout
                          apiUrl={`${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/auth/logout`}
                        />
                      }
                    />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route
                      path="/*"
                      element={
                        <Protected>
                          <Main />
                        </Protected>
                      }
                    />
                  </Routes>
                  <NotificationContainer />
                </PlatformUserContext.Provider>
              </NotificationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </>
      )}
    </>
  );
};

export default App;
