import { IsDate, IsNotEmpty, IsArray } from "class-validator";
import { IAdapterAssignmentAction } from "./adapter-assignment-action.interface";

export class AdapterAssignmentActionDto implements IAdapterAssignmentAction {
  @IsNotEmpty()
  folder_id: string;

  @IsDate()
  start_date: Date;

  // @IsNotEmpty()
  // person: any;
  @IsNotEmpty()
  availability_header_id: string;

  @IsNotEmpty()
  entity_ids: string[]; //operaiton id
}
