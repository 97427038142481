import React from "react";
import { ICustomer } from "@river/interfaces";

export interface ICustomerContext {
  customer: ICustomer | null;
  setCustomer: (customer: ICustomer) => void;
}

export const CustomerContext = React.createContext<ICustomerContext | null>(
  null
);
