import { IsNotEmpty } from "class-validator";

export class AuthDto {
  @IsNotEmpty({ message: "Username is required" })
  //@IsEmail()
  //email: string = '';
  username: string = "";

  @IsNotEmpty({ message: "Password is required" })
  password: string = "";

  // @IsNotEmpty()
  // environment: string;
}
