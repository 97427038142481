import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { RiverErrorMessages } from "./river-error-messages";
const AUTOHIDE_DURATION_TIMEOUT = 2000;
export var NotificationType;
(function (NotificationType) {
    NotificationType["SUCCESS"] = "success";
    NotificationType["INFO"] = "info";
    NotificationType["WARN"] = "warn";
    NotificationType["ERROR"] = "error";
})(NotificationType || (NotificationType = {}));
export const NotificationContext = React.createContext(null);
export const getErrorMessageFromObject = (obj) => {
    var _a;
    let msg = "";
    const error = obj.message;
    if (typeof error === "string") {
        msg = error;
    }
    else {
        // api response
        if (!error.isPromiseCanceled) {
            const responseData = error.isAxiosError
                ? (_a = error.response) === null || _a === void 0 ? void 0 : _a.data
                : error.data;
            if (responseData) {
                msg = RiverErrorMessages[responseData.river_error_code];
                if (!msg) {
                    msg = responseData.message;
                }
            }
            else {
                msg = error.message || "Unknown API Error";
            }
        }
    }
    return msg;
};
const getMessageFromObject = (type, obj) => {
    let msg = "";
    if (type === NotificationType.ERROR) {
        msg = getErrorMessageFromObject(obj);
    }
    else {
        msg = obj.message;
    }
    return msg;
};
export const NotificationProvider = (props) => {
    const [snackbarProps, setSnackbarProps] = useState({
        open: false,
    });
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [notificationType, setNotificationType] = useState(null);
    const notificationState = {
        type: notificationType,
        title,
        message,
        snackbarProps,
        success: (data) => handle(NotificationType.SUCCESS, data),
        warn: (data) => handle(NotificationType.WARN, data),
        error: (data) => handle(NotificationType.ERROR, data),
        info: (data) => handle(NotificationType.INFO, data),
        close: () => hideNotification(),
    };
    const handle = (type, notification) => {
        let newMessage;
        let newTitle;
        if (typeof notification === "string") {
            newTitle = "";
            newMessage = notification;
        }
        else {
            newTitle = notification.title;
            newMessage = getMessageFromObject(type, notification);
        }
        setNotificationType(type);
        setTitle(newTitle);
        setMessage(newMessage);
        if (newMessage) {
            showNotification(type);
        }
    };
    const showNotification = (type) => {
        const snackbarProps = {
            onClose: hideNotification,
            open: true,
        };
        if (type === NotificationType.ERROR) {
            Object.assign(snackbarProps, {
                autoHideDuration: null,
            });
        }
        else {
            Object.assign(snackbarProps, {
                autoHideDuration: AUTOHIDE_DURATION_TIMEOUT,
            });
        }
        setSnackbarProps(snackbarProps);
    };
    const hideNotification = () => {
        setSnackbarProps({
            open: false,
        });
    };
    return (_jsx(NotificationContext.Provider, { value: notificationState, children: props.children }));
};
