import { IsNotEmpty, IsOptional, IsArray } from "class-validator";

export class AdapterUserDto {
  @IsNotEmpty()
  user_name: string;

  @IsOptional()
  email?: string;

  @IsOptional()
  first_name?: string;

  @IsOptional()
  last_name?: string;

  @IsOptional()
  display_name: string;

  @IsOptional()
  @IsArray()
  roles?: string[];

  // Other ERP specific attributes
  // In case of Oracle EBS - RESPONSIBILITY and APPLICATION info
  [s: string]: any;
}
