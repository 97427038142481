import { IsNotEmpty, IsArray } from "class-validator";

import { IAdapterUserStatusAction } from "./adapter-user-status-action.interface";

export class AdapterUserStatusActionDto implements IAdapterUserStatusAction {
  @IsNotEmpty()
  entity_name: string;

  @IsNotEmpty()
  @IsArray()
  entity_ids: string[];
}
