import { IsNotEmpty, ValidateNested } from "class-validator";
import { Type } from "class-transformer";

import { QueryDto } from "../query";
import { IAdapterSchedulingActionAsync } from "./adapter-scheduling-action-async.interface";
import { AdapterSchedulingActionBaseDto } from "./adapter-scheduling-action-base.dto";

export class AdapterSchedulingActionAsyncDto
  extends AdapterSchedulingActionBaseDto
  implements IAdapterSchedulingActionAsync
{
  @IsNotEmpty()
  @ValidateNested()
  @Type(() => QueryDto)
  query: QueryDto;
}
