import { useEffect } from "react";
import { authService } from "../../services";
import { useNavigate } from "react-router";
export const Logout = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        const logout = async () => {
            await authService.logout(props.apiUrl);
            navigate(props.redirectUrl || "/");
        };
        logout();
    });
    return null;
};
