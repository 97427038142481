import React from "react";
import { IRiverSidebarContext } from "@river/common-ui";
import { ISidebarContent } from "./sidebar";

export interface ISidebarContextState extends IRiverSidebarContext {
  sidebarContent: ISidebarContent;
  setSidebarContent: (content: ISidebarContent) => void;
}

export const SidebarContext = React.createContext<ISidebarContextState | null>(
  null
);
