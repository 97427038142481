import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { authService } from "@river/common-ui";

export const Protected = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  return authService.isLoggedIn() ? (
    children
  ) : (
    <Navigate to={"/login"} state={{ from: location }} />
  );
};
