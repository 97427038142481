import React, { useState, useEffect, ReactElement, useContext } from "react";
import { Column } from "react-data-grid";
import { ClusterDto } from "@river/interfaces";
import { clusterService } from "../../services";
import { Box } from "@mui/material";
import { RiverDataGrid, tooltipCell } from "../river-data-grid";
import { PrimaryButton, RiverDialog, useNotification } from "@river/common-ui";
import DeleteIcon from "@mui/icons-material/Delete";
import { ClusterDialog } from "./cluster-dialog";
import { PageHeader } from "../page-header";
import { SidebarContext } from "../sidebar";
import { RootSidebarContent } from "../root-sidebar-content";
import { useNavigate } from "react-router-dom";
import sharedStyles from "../shared-styles/shared-styles.module.scss";

export const Clusters: React.FC = () => {
  const sidebarContext = useContext(SidebarContext);
  const navigate = useNavigate();
  const [clusters, setClusters] = useState<ClusterDto[]>([]);
  const [clusterDialogOpened, setClusterDialogOpened] =
    useState<boolean>(false);
  const [clusterDeleteDialogOpened, setClusterDeleteDialogOpened] =
    useState<boolean>(false);
  const notify = useNotification();

  useEffect(() => {
    loadClusters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadClusters = async () => {
    try {
      const result = await clusterService.getClusters();
      setClusters(result);
    } catch (message) {
      notify.error({ message });
    }
  };

  useEffect(() => {
    sidebarContext?.setSidebarContent(RootSidebarContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openClusterDialog = (): void => setClusterDialogOpened(true);
  const closeClusterDialog = (): void => {
    setClusterDialogOpened(false);
  };

  const columns: Column<any>[] = [
    {
      key: "cluster",
      name: "Cluster",
      formatter: (row) => {
        return (
          <div
            className={sharedStyles.dataGridLink}
            onClick={() => {
              navigate(`/clusters/${(row.row as any)._id}/details`);
            }}
          >
            {tooltipCell(`${row.row.cluster}`)}
          </div>
        );
      },
    },
    { key: "description", name: "Description" },
    { key: "connection_string", name: "Connection String" },
    { key: "connection_options", name: "Options" },
    {
      key: "delete",
      name: "Delete Cluster",
      formatter: (row) => {
        return (
          <div className={sharedStyles.delete}>
            <DeleteIcon
              onClick={() => {
                setClusterDeleteDialogOpened(true);
                // deleteCluster
              }}
            />
          </div>
        );
      },
    },
  ];

  const renderCreateClusterButton = (): ReactElement => (
    <PrimaryButton onClick={openClusterDialog} text={"Create Cluster"} />
  );

  const renderDeleteClusterDialog = (): ReactElement => {
    return (
      <RiverDialog
        title={"Delete Cluster"}
        open={clusterDeleteDialogOpened}
        onClose={() => {
          setClusterDeleteDialogOpened(false);
        }}
        classes={{
          actions: sharedStyles.deleteButton,
        }}
        actionButtonText={"DELETE"}
        showActionsDivider={false}
      >
        <div>Are you sure you want to delete this Cluster?</div>
      </RiverDialog>
    );
  };

  return (
    <>
      <PageHeader title={"DB Clusters"} />
      <Box className={sharedStyles.primaryButtons}>
        {renderCreateClusterButton()}
      </Box>
      <Box className={sharedStyles.viewContent}>
        <RiverDataGrid
          className={sharedStyles.dataGrid}
          columns={columns}
          rows={clusters}
        />
      </Box>
      <ClusterDialog
        open={clusterDialogOpened}
        onClose={(success) => {
          closeClusterDialog();
          if (success) loadClusters();
        }}
      />
      {renderDeleteClusterDialog()}
    </>
  );
};
