import React, { ReactElement, useContext, useEffect, useState } from "react";
import { SubHeader } from "../../sub-header";
import { Box } from "@mui/material";
import { customerService } from "../../../services";
import {
  RiverSpinner,
  RiverTextInput,
  useNotification,
  PrimaryButton,
} from "@river/common-ui";
import { IEnvMobileAppOptions } from "@river/interfaces";
import { CustomerContext } from "../../customer";
import { EnvironmentContext } from "../../customer-environment";
import { PageHeader } from "../../page-header";
import sharedStyles from "../../shared-styles/shared-styles.module.scss";

export const EnvironmentMobile: React.FC = () => {
  const { customer } = useContext(CustomerContext)!;
  const { environment } = useContext(EnvironmentContext)!;

  const notify = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [mobileAppInfo, setMobileAppInfo] =
    useState<IEnvMobileAppOptions | null>();
  useEffect(() => {
    getMobileAppInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMobileAppInfo = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await customerService.getEnvironmentMobileAppInfo(
        customer!._id,
        environment!._id
      );
      if (!!result) {
        setMobileAppInfo(result);
      }
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };
  const initializeMobile = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await customerService.initEnvironmentMobileApp(
        customer!._id,
        environment!._id,
        { app_name: `${customer!.handle}-${environment!.handle}` }
      );
      getMobileAppInfo();
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const disableMobile = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await customerService.deleteEnvironmentMobileApp(
        customer!._id,
        environment!._id
      );
      setMobileAppInfo(null);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const renderAppNameField = (): ReactElement => (
    <RiverTextInput
      id="app_name"
      label="App Name"
      value={mobileAppInfo?.app_name}
      className={sharedStyles.input}
      fullWidth
      disabled
    />
  );
  const renderClientAppIdField = (): ReactElement => (
    <RiverTextInput
      id="client_app_id"
      label="Cliend App Id"
      value={mobileAppInfo?.client_app_id}
      className={sharedStyles.input}
      fullWidth
      disabled
    />
  );

  const renderDisableButton = (): ReactElement => (
    <PrimaryButton text={"Disable..."} onClick={disableMobile} />
  );

  const renderInitializeButton = (): ReactElement => (
    <PrimaryButton text={"Initialize..."} onClick={initializeMobile} />
  );

  return (
    <>
      <PageHeader title={"Environments"} />
      <SubHeader subtitle={`${environment?.handle} - Mobile`} />
      <Box className={sharedStyles.primaryButtons}>
        {mobileAppInfo ? renderDisableButton() : renderInitializeButton()}
      </Box>
      {mobileAppInfo && (
        <Box className={sharedStyles.viewContent}>
          <div className={sharedStyles.form}>
            {renderAppNameField()}
            {renderClientAppIdField()}
          </div>
        </Box>
      )}
      <RiverSpinner show={isLoading} />
    </>
  );
};
