import {
  IsNotEmpty,
  IsOptional,
  IsDate,
  IsBoolean,
  IsInt,
  Min,
} from "class-validator";

import { IAdapterTimeline } from "./adapter-timeline.interface";

export class AdapterTimelineDto implements Omit<IAdapterTimeline, "_id"> {
  @IsNotEmpty()
  timeline: string;

  @IsOptional()
  description?: string;

  @IsNotEmpty()
  @IsDate()
  start_date: Date;

  @IsInt()
  @Min(1)
  folder_duration_days: number = 7;

  /** The following variables can be used
   * {timeline} - the timeline name
   * {year} - four-digit year of the folder's start date
   * {month} - two-digit month of the folder's start date
   * {day} - two-digit day of the folder's start date
   * {week} - week number of the folder's start date
   *
   * Use formatDynamicString from @river/util package to generate folder names
   */
  @IsNotEmpty()
  folder_name_template: string = "{timeline}-{year}-{week}-{month}{day}";

  @IsOptional()
  calendar_id?: string | null;

  @IsBoolean()
  use_avail_to_schedule_assignments: boolean = false;

  // Other ERP specific attributes
  [s: string]: any;
}
