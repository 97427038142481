import { IsNotEmpty, IsOptional, IsArray } from "class-validator";
import { IAdapterPostAction } from "./adapter-post-action.interface";

export class AdapterPostActionDto implements IAdapterPostAction {
  @IsNotEmpty()
  folder_id: string;

  @IsOptional()
  entity_name: string = "workorder";

  @IsOptional()
  @IsArray()
  entity_ids: string[] = [];
}
