import { IsNotEmpty, IsOptional, IsBoolean } from 'class-validator';
import { IAdapterRuleAction } from './adapter-rule-action.interface';


export class AdapterRuleActionDto implements Omit<IAdapterRuleAction, '_id'> {
  @IsNotEmpty()
  action_script: string;

  @IsNotEmpty()
  description: string;

  @IsOptional()
  @IsBoolean()
  is_default?: boolean;  
}