import React, { ReactElement, MouseEvent, SyntheticEvent } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  IconButtonProps,
  ButtonProps,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./cell-editor-menu.module.scss";
import { PopoverOrigin } from "@mui/material/Popover/Popover";
import clsx from "clsx";

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};

const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

const DEFAULT_ANCHOR_ICON: React.FC = MoreVertIcon;

interface CellEditorMenuProps {
  anchorIcon?: React.FC;
  anchorIconButtonProps?: Partial<IconButtonProps>;
  noAnchorIcon?: boolean;
  anchorButtonProps?: Partial<ButtonProps>;
  anchorLabel?: string;
  menuProps?: Partial<MenuProps>;
  items: CellEditorMenuItem[];
}

export interface CellEditorMenuItem {
  label: string;
  action: (event: MouseEvent) => void;
}

export const CellEditorMenu: React.FC<CellEditorMenuProps> = (
  props
): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: SyntheticEvent, reason: string) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const AnchorIcon = props.anchorIcon || DEFAULT_ANCHOR_ICON;
  const renderAnchorIcon = (): ReactElement | null =>
    props.noAnchorIcon ? null : (
      <div className={styles.anchorIcon}>
        <AnchorIcon />
      </div>
    );

  const renderAnchor = (): ReactElement => {
    let anchor: ReactElement;
    if (props.anchorLabel) {
      anchor = (
        <IconButton
          className={styles.anchor}
          onClick={handleClick}
          {...props.anchorIconButtonProps}
        >
          {renderAnchorIcon()}
          {props.anchorLabel}
        </IconButton>
      );
    } else {
      anchor = (
        <IconButton
          className={styles.anchor}
          onClick={handleClick}
          {...props.anchorIconButtonProps}
          size="large"
        >
          {renderAnchorIcon()}
        </IconButton>
      );
    }
    return anchor;
  };

  return (
    <>
      {renderAnchor()}
      <Menu
        className={clsx(styles.menu)}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          list: styles.menuList,
          paper: styles.paper,
        }}
        anchorOrigin={DEFAULT_ANCHOR_ORIGIN}
        transformOrigin={DEFAULT_TRANSFORM_ORIGIN}
        {...props.menuProps}
      >
        {props.items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(e: MouseEvent) => {
              handleClose(e, "actionClicked");
              item.action(e);
            }}
            classes={{
              root: styles.menuItem,
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
