import React, { ReactElement } from "react";
import { FormatterProps } from "react-data-grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import clsx from "clsx";
import styles from "./env-status-cell-formatter.module.scss";

const Connected: React.FC = (): ReactElement => {
  return (
    <div className={clsx([styles.content, styles.connected])}>
      <CheckCircleOutlineIcon className={styles.icon} />
      <div className={styles.textContainer}>
        <div className={styles.text}>{"Connected"}</div>
      </div>
    </div>
  );
};

const NotConnected: React.FC = (): ReactElement => {
  return (
    <div className={clsx([styles.content, styles.notConnected])}>
      <CancelIcon className={styles.icon} />
      <div className={styles.textContainer}>
        <div className={styles.text}>{"Not Connected"}</div>
      </div>
    </div>
  );
};

export const EnvStatusCellFormatter: React.FC<FormatterProps<any>> = (
  props
) => {
  return (
    <div className={styles.root}>
      {props.row?.connected ? <Connected /> : <NotConnected />}
    </div>
  );
};
