import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import uploadPlaceholderImage from "../../../assets/image-upload.svg";
import { useTranslation } from "../../hooks";
import { useEnv } from "../../hooks";
import { withTranslation } from "react-i18next";
import styles from "./image-upload.module.scss";
import clsx from "clsx";
const PLACEHOLDER_IMAGE_URL = uploadPlaceholderImage;
const ImageUpload = (props) => {
    const { t } = useTranslation();
    const env = useEnv();
    const { isMobile } = env;
    const DEFAULT_TITLE = t("shared.image_upload:label.add_photo", {
        defaultValue: "+Add a photo",
    });
    const DEFAULT_SUBTITLE = t("shared.image_upload:label.photo_changed_by_admin", {
        defaultValue: "This photo can be changed by company admin later",
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [uploadUrl, setUploadUrl] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedFile, setSelectedFile] = useState(null);
    useEffect(() => {
        var _a, _b, _c;
        setUploadUrl(props.uploadUrl);
        setImageUrl((_a = props.imageUrl) !== null && _a !== void 0 ? _a : PLACEHOLDER_IMAGE_URL);
        setTitle((_b = props.title) !== null && _b !== void 0 ? _b : DEFAULT_TITLE);
        setSubtitle((_c = props.subtitle) !== null && _c !== void 0 ? _c : DEFAULT_SUBTITLE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.uploadUrl, props.imageUrl, props.title, props.subtitle]);
    const onFileChange = (event) => {
        const input = event.target;
        const file = input.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = (e) => {
                const base64Data = reader.result;
                setImageUrl(base64Data);
                setSelectedFile(file);
            };
            reader.readAsDataURL(file);
        }
    };
    const isBlank = () => imageUrl === PLACEHOLDER_IMAGE_URL;
    return (_jsx(_Fragment, { children: props.tReady && (_jsxs(Box, { className: clsx([styles.root, { [styles.mobile]: isMobile }]), children: [_jsx(Box, { className: clsx([styles.preview, isBlank() && styles.blank]), children: _jsx("div", { className: clsx([styles.logo]), style: {
                            background: `url('${imageUrl}') no-repeat`,
                        } }) }), _jsxs(Box, { className: clsx([styles.info]), children: [_jsxs("div", { className: styles.uploadControl, children: [_jsx("label", { htmlFor: "avatar", className: styles.title, children: title }), _jsx("input", { type: "file", id: "avatar", name: "avatar", accept: "image/*", onChange: onFileChange })] }), _jsx("div", { className: styles.subtitle, children: subtitle })] })] })) }));
};
export default withTranslation(["shared.image_upload"])(ImageUpload);
