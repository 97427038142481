import { IsNotEmpty } from 'class-validator';

export class AdapterRoleModuleActionDto {
  @IsNotEmpty()
  module: string;

  @IsNotEmpty()
  action: string;

  @IsNotEmpty()
  enabled: boolean;
}