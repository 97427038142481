import { IsNotEmpty } from "class-validator";

export class AdapterCrewPersonDto {
  //
  @IsNotEmpty()
  crew: string;

  @IsNotEmpty()
  availability_header_id: string;
}
