import {
  IsNotEmpty,
  IsIn,
  IsBoolean,
  IsOptional,
  Validate,
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
  IsArray,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";

import { Constants } from "@river/constants";
import { AttributeOptionDto } from "./attribute-option.dto";

// --------------
@ValidatorConstraint({ name: "dataTypeConstraint", async: false })
class AttributeDataTypeConstraint implements ValidatorConstraintInterface {
  // --------
  validate(
    text: string,
    args: ValidationArguments
  ): Promise<boolean> | boolean {
    if (text.startsWith(`${Constants.data_type.entity}:`)) {
      return true;
    }

    return Object.values(Constants.data_type).includes(text);
  }

  // --------
  defaultMessage(args: ValidationArguments): string {
    return "Data type $value is invalid";
  }
}

// --------------
export class AttributeDto {
  @IsNotEmpty()
  attribute_name: string;

  @IsOptional()
  description?: string;

  @IsNotEmpty()
  //@IsIn(Object.values(Constants.data_type))
  @Validate(AttributeDataTypeConstraint)
  data_type: string = Constants.data_type.string;

  @IsOptional()
  @IsIn(Object.values(Constants.input_type))
  input_type?: string = Constants.input_type.none;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AttributeOptionDto)
  options?: AttributeOptionDto[];

  @IsOptional()
  @IsIn(Object.values(Constants.attribute_cardinality))
  cardinality: string = Constants.attribute_cardinality.one;

  @IsOptional()
  @IsBoolean()
  is_primary_key?: boolean = false;

  @IsOptional()
  @IsBoolean()
  is_required?: boolean = false;

  @IsOptional()
  @IsBoolean()
  is_persistent?: boolean = true;
}
