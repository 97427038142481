import React, { ReactElement, useState } from "react";
import { Box } from "@mui/material";
import { PrimaryButton, RiverTextInput } from "@river/common-ui";

import styles from "./change-email.module.scss";

export interface IChangeEmailProps {
  onCancel: () => void;
}

export const ChangeEmail: React.FC<IChangeEmailProps> = (
  props
): ReactElement => {
  const [newEmail, setNewEmail] = useState<string>("");

  const saveNewEmail = (): void => {
    console.log(`saving new email: ${newEmail}`);
    props.onCancel();
  };

  const renderCancelButton = (): ReactElement => (
    <PrimaryButton
      text={"CANCEL"}
      onClick={() => props.onCancel()}
      className={styles.cancel}
      classes={{ root: styles.button }}
      variant="text"
    />
  );

  const renderSendButton = (): ReactElement => (
    <PrimaryButton
      text={"SEND"}
      onClick={saveNewEmail}
      classes={{ root: styles.button }}
    />
  );

  return (
    <form onSubmit={saveNewEmail} className={styles.root}>
      <RiverTextInput
        id={"newEmail"}
        value={newEmail}
        onChange={(value) => setNewEmail(value)}
        label={"New E-mail"}
        fullWidth
      />
      <Box className={styles.dialogButtons}>
        {renderCancelButton()}
        {renderSendButton()}
      </Box>
    </form>
  );
};
