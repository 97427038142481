import React, { MouseEvent } from "react";
import { FormatterProps } from "react-data-grid";
import { CellEditorMenu } from "../cell-editor-menu";
import styles from "./user-email-cell-formatter.module.scss";
import { IPlatformUser } from "@river/interfaces";

export interface IUserEmailCellFormatterProps extends FormatterProps<any> {
  editUser: (user: IPlatformUser) => void;
  deleteUser: (user: IPlatformUser) => void;
  blockUser: (user: IPlatformUser) => void;
  reinviteUser: (user: IPlatformUser) => void;
}

export const UserEmailCellFormatter: React.FC<IUserEmailCellFormatterProps> = (
  props
) => {
  return (
    <div className={styles.root}>
      <div className={styles.emailContainer}>
        <div className={styles.email}>{props.row[props.column.key]}</div>
      </div>
      <CellEditorMenu
        items={[
          {
            label: "Edit",
            action: (event: MouseEvent) => props.editUser(props.row),
          },
          {
            label: "Delete",
            action: (event: MouseEvent) => props.deleteUser(props.row),
          },
          {
            label: "Block",
            action: (event: MouseEvent) => props.blockUser(props.row),
          },
          {
            label: "Reinvite",
            action: (event: MouseEvent) => props.reinviteUser(props.row),
          },
        ]}
        menuProps={{
          anchorOrigin: {
            vertical: 10,
            horizontal: "center",
          },
        }}
      />
    </div>
  );
};
