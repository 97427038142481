import React, { ReactElement, useState } from "react";
import { PrimaryButton, RiverPasswordInput } from "@river/common-ui";
import { Box } from "@mui/material";
import styles from "./change-password.module.scss";

export interface IChangePasswordProps {
  onCancel: () => void;
}

interface IChangePasswordState {
  oldPassword: string;
  newPassword: string;
}

export const ChangePassword: React.FC<IChangePasswordProps> = (
  props
): ReactElement => {
  const [passwordState, setPasswordState] = useState<IChangePasswordState>({
    oldPassword: "",
    newPassword: "",
  });

  const onPropertyChange: React.ChangeEventHandler<HTMLInputElement> = async ({
    currentTarget: input,
  }): Promise<void> => {
    const newState: IChangePasswordState = {
      ...passwordState,
      [input.name]: input.value,
    };
    setPasswordState(newState);
  };

  const saveNewPassword = (): void => {
    console.log(`saving new password`);
  };

  const renderCancelButton = (): ReactElement => (
    <PrimaryButton
      text={"CANCEL"}
      onClick={() => props.onCancel()}
      classes={{ root: styles.button }}
      className={styles.cancel}
      variant="text"
    />
  );

  const renderSaveButton = (): ReactElement => (
    <PrimaryButton
      text={"SAVE"}
      type="submit"
      classes={{ root: styles.button }}
    />
  );

  return (
    <form onSubmit={saveNewPassword} className={styles.root}>
      <RiverPasswordInput
        className={styles.oldPassword}
        id="oldPassword"
        value={passwordState.oldPassword}
        onChangeEvent={(event) => onPropertyChange(event)}
        label={"Old Password"}
        fullWidth
      />
      <RiverPasswordInput
        id="newPassword"
        value={passwordState.newPassword}
        onChangeEvent={(event) => onPropertyChange(event)}
        label={"New Password"}
        fullWidth
      />
      <Box className={styles.dialogButtons}>
        {renderCancelButton()}
        {renderSaveButton()}
      </Box>
    </form>
  );
};
