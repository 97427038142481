import DOMPurify from "dompurify";
import { useTranslation as useI18nextTranslation, } from "react-i18next";
export function useTranslation() {
    const { t } = useI18nextTranslation();
    const getSanitizedTranslation = (...args) => {
        const raw = t(args[0], args[1]);
        return DOMPurify.sanitize(raw);
    };
    return {
        t: getSanitizedTranslation,
    };
}
