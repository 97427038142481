import {
  IsNotEmpty,
  IsBoolean,
  IsArray,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";

import { IAdapterUserStatusEditAction } from "./adapter-user-status-edit-action.interface";
import { IAdapterUserStatus } from "./adapter-user-status.interface";

export class AdapterUserStatusDto implements IAdapterUserStatus {
  @IsNotEmpty()
  user_status: string;

  @IsBoolean()
  active: boolean;
}

export class AdapterUserStatusEditActionDto
  implements IAdapterUserStatusEditAction
{
  @IsNotEmpty()
  entity_name: "workorder" | "operation";

  @IsNotEmpty()
  entity_id: string;

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AdapterUserStatusDto)
  with_number: AdapterUserStatusDto[];

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AdapterUserStatusDto)
  without_number: AdapterUserStatusDto[];
}
