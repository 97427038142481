import jwtDecode from "jwt-decode";
import * as httpService from "./http.service";
import * as localStorageService from "./local-storage.service";
// --------------
export const login = async (url, auth) => {
    const result = await httpService.post(url, auth);
    initUser(result);
    return getCurrentUser();
};
// ---------------
export const saveAccessToken = (accessToken) => {
    localStorageService.setAccessToken(accessToken);
};
// ----------------
const initUser = (result) => {
    saveAccessToken(result.data.access_token);
};
// --------------
export const logout = async (url) => {
    localStorageService.removeAccessToken();
    await httpService.post(url);
};
// -------------
export const getCurrentUser = () => {
    try {
        const accessToken = localStorageService.getAccessToken();
        return accessToken ? jwtDecode(accessToken) : null;
    }
    catch (error) {
        return null;
    }
};
// ------------
export const isLoggedIn = () => {
    const user = getCurrentUser();
    return user !== null;
};
