import {
  IsNotEmpty,
  IsOptional,
  IsArray,
  ValidateNested,
  IsIn,
  ValidateIf,
} from "class-validator";
import { Type } from "class-transformer";

import { AdapterRuleActionDto } from "./adapter-rule-action.dto";
import { IAdapterRule } from "./adapter-rule.interface";
import { Constants } from "@river/constants";

export class AdapterRuleDto implements Omit<IAdapterRule, "_id"> {
  @IsNotEmpty()
  rule: string;

  @IsNotEmpty()
  @IsIn(Object.values(Constants.rule_type))
  rule_type: string;

  @IsNotEmpty()
  entity_name: string;

  @IsOptional()
  target_period: number = 0;

  @IsNotEmpty()
  expression: string;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AdapterRuleActionDto)
  actions?: AdapterRuleActionDto[];

  @IsOptional()
  display_options?: string;

  @ValidateIf((o: IAdapterRule) => o.rule_type === Constants.rule_type.table_cell_color_rule)
  @IsNotEmpty()
  attribute?: string;

  // Other ERP specific attributes
  [s: string]: any;
}
