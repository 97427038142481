import { IsNotEmpty } from "class-validator";
import { IAdapterRestriction } from "./adapter-restriction.interface";

export class AdapterRestrictionDto implements Omit<IAdapterRestriction, "_id"> {
  @IsNotEmpty()
  role: string;

  @IsNotEmpty()
  entity_name: string = "";

  @IsNotEmpty()
  expression: string = "";

  @IsNotEmpty()
  active: boolean = false;

  @IsNotEmpty()
  validated: boolean = false;
}
