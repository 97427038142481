import { IsNotEmpty } from "class-validator";

import { IClusterOptions } from "./cluster-options.interface";

// --------------------
export class ClusterOptionsDto
  implements
    Pick<
      IClusterOptions,
      "project_id" | "project_name" | "api_key_name" | "api_key_value"
    >
{
  @IsNotEmpty()
  project_id: string = "";

  @IsNotEmpty()
  project_name: string = "";

  @IsNotEmpty()
  api_key_name: string = "";

  @IsNotEmpty()
  api_key_value: string = "";
}
