import { IsNotEmpty, IsOptional } from "class-validator";

import { ICluster } from "./cluster.interface";

export class ClusterDto
  implements Pick<ICluster, "cluster" | "description" | "connection_string">
{
  // it should match the cluster name as configured in MongoDb Atlas
  // because it is used when the mobile services are enabled
  @IsNotEmpty()
  cluster: string;

  @IsOptional()
  description?: string;

  @IsNotEmpty()
  connection_string: string;

  @IsOptional()
  connection_options?: string;
}
