export var PromiseStatus;
(function (PromiseStatus) {
    PromiseStatus[PromiseStatus["PENDING"] = 0] = "PENDING";
    PromiseStatus[PromiseStatus["CANCELED"] = 1] = "CANCELED";
    PromiseStatus[PromiseStatus["RESOLVED"] = 2] = "RESOLVED";
})(PromiseStatus || (PromiseStatus = {}));
export const makeCancellable = (promise, callback) => {
    let cancelPromise;
    let status = PromiseStatus.PENDING;
    const canceledPromiseData = { isPromiseCanceled: true };
    const wrapper = new Promise((resolve, reject) => {
        cancelPromise = reject;
        promise
            .then((val) => {
            if (status === PromiseStatus.PENDING) {
                resolve(val);
            }
        }, (error) => {
            if (status === PromiseStatus.PENDING) {
                reject(error);
            }
        })
            .finally(() => {
            status = PromiseStatus.RESOLVED;
        });
    });
    if (callback) {
        callback(wrapper);
    }
    return {
        promise: wrapper,
        status,
        cancel() {
            if (status === PromiseStatus.PENDING) {
                cancelPromise(canceledPromiseData);
                status = PromiseStatus.CANCELED;
            }
        },
    };
};
