var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Container, Box, Button, Link } from "@mui/material";
import { styled } from "@mui/system";
import { RiverPasswordInput } from "../forms";
import { useTranslation } from "../../hooks";
import { withTranslation } from "react-i18next";
import styles from "./reset-password.module.scss";
const Title = styled((_a) => {
    var props = __rest(_a, []);
    return (_jsx(Box, Object.assign({}, props, { children: _jsx("div", { className: styles.title, children: props.children }) })));
})(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "24px",
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "35px",
    },
}));
const Subtitle = (props) => (_jsx(Box, { className: styles.subtitle, children: props.children }));
const ResetPassword = (props) => {
    const { t } = useTranslation();
    const [passwords, setPasswords] = useState({
        newPassword: "",
        newPasswordRepeated: "",
    });
    const [isValidationError, setIsValidationError] = useState(false);
    useState(false);
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
    const onPropertyChange = async ({ currentTarget: input, }) => {
        // Attempting to prevent browsers from forcing autocomplete
        const nameMap = {
            first: "newPassword",
            second: "newPasswordRepeated",
        };
        const newPasswords = Object.assign(Object.assign({}, passwords), { [nameMap[input.name]]: input.value });
        validate(newPasswords);
        setPasswords(newPasswords);
    };
    const validate = (passwords) => {
        const validateLength = (val) => !!(val && val.length >= 8);
        const matches = () => passwords.newPassword === passwords.newPasswordRepeated;
        const isValid = validateLength(passwords.newPassword) && matches();
        setIsValidationError(!isValid);
        return isValid;
    };
    const renderHeader = () => _jsx(Box, { className: styles.header });
    const renderNewPasswordField = () => (_jsx(RiverPasswordInput, { id: "first", value: passwords.newPassword, label: t("component.login:label.password", {
            defaultValue: "Password",
        }), onChangeEvent: (event) => onPropertyChange(event), className: styles.password, error: isValidationError, fullWidth: true }));
    const renderNewPasswordRepeatedField = () => (_jsx(RiverPasswordInput, { id: "second", value: passwords.newPasswordRepeated, label: t("component.login:label.repeat_password", {
            defaultValue: "Repeat Password",
        }), onChangeEvent: (event) => onPropertyChange(event), className: styles.newPasswordRepeated, error: isValidationError, fullWidth: true }));
    const renderLoginButton = () => (_jsx(Box, { display: "flex", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", color: "primary", type: "submit", classes: { root: styles.loginButton }, children: t("component.login:button.login", {
                defaultValue: "LOGIN",
            }) }) }));
    const handleLogin = async (e) => {
        e.preventDefault();
        if (validate(passwords)) {
            // Do the actual password update call
            //...
            setIsPasswordUpdated(true);
        }
    };
    const renderMatchInfo = () => (_jsx(Box, { className: styles.matchInfo, children: isValidationError ? (_jsx("span", { className: styles.error, children: t("component.login:label.both_pass_match_and_least_8_char", {
                defaultValue: "Both passwords must match and be at least 8 characters",
            }) })) : (_jsx("span", { children: t("component.login:label.pass_match_and_least_8_char", {
                defaultValue: "Passwords must match and be at least 8 characters",
            }) })) }));
    const renderNewPasswordSetup = () => (_jsxs(_Fragment, { children: [_jsx(Title, { children: t("component.login:label.new_pass_setup", {
                    defaultValue: "New Password Setup",
                }) }), _jsx(Subtitle, { children: t("component.login:label._enter_new_pass", {
                    defaultValue: "Please enter new password",
                }) }), _jsx(Box, { width: { xs: "auto", sm: "328px" }, children: _jsxs("form", { onSubmit: handleLogin, autoComplete: "off", children: [renderNewPasswordField(), renderNewPasswordRepeatedField(), renderMatchInfo(), renderLoginButton()] }) })] }));
    const renderPasswordSuccessfullyUpdated = () => (_jsxs(Box, { className: styles.passwordUpdatedContainer, children: [_jsx(Title, { children: t("component.login:label.pass_updated", {
                    defaultValue: "Password Successfully Updated",
                }) }), _jsx(Subtitle, { children: t("component.login:label.new_pass_is_set", {
                    defaultValue: "Your new password has now been set and you are logged in.",
                }) }), _jsx(Link, { href: "#", className: styles.goToAccount, children: t("component.login:label.go_to_account", {
                    defaultValue: "GO TO ACCOUNT",
                }) })] }));
    const renderContent = () => (_jsx(Box, { className: styles.content, padding: {
            xs: "35px 25px 0",
            sm: "80px 0 0 0",
        }, children: isPasswordUpdated
            ? renderPasswordSuccessfullyUpdated()
            : renderNewPasswordSetup() }));
    const renderFooter = () => _jsx(Box, { className: styles.footer });
    const render = () => (_jsx(_Fragment, { children: props.tReady && (_jsx(Container, { maxWidth: false, className: styles.container, children: _jsxs("div", { className: styles.body, children: [renderHeader(), renderContent(), renderFooter()] }) })) }));
    return render();
};
export default withTranslation(["component.login"])(ResetPassword);
