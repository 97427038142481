import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { Dialog, Button, DialogActions, DialogContent, Slide, Box, Link, } from "@mui/material";
import envelope from "../../../assets/check-email-envelope.svg";
import { RiverTextInput } from "../forms";
import { CloseDialogButton } from "../dialogs";
import { useEnv, useTranslation } from "../../hooks";
import styles from "./forgot-password.module.scss";
import clsx from "clsx";
const Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Slide, Object.assign({ direction: "up", ref: ref }, props));
});
export const ForgotPassword = (props) => {
    const env = useEnv();
    const { isMobile } = env;
    const { t } = useTranslation();
    const [isForgotPasswordDialogOpened, setIsForgotPasswordDialogOpened] = useState(false);
    const [isConfirmationPopupOpened, setIsConfirmationPopupOpened] = useState(false);
    const [email, setEmail] = useState("");
    useEffect(() => {
        setIsForgotPasswordDialogOpened(props.open);
    }, [props.open]);
    const closeForgotPasswordDialog = () => {
        props.onClose();
    };
    const closeConfirmationPopup = () => {
        setIsConfirmationPopupOpened(false);
    };
    const sendEmail = () => {
        closeForgotPasswordDialog();
        console.log("Sending an email");
        setIsConfirmationPopupOpened(true);
    };
    const onEmailChange = async ({ currentTarget: input, }) => {
        setEmail(input.value);
    };
    const preventDefault = (event) => event.preventDefault();
    const renderDialogHeader = () => (_jsxs(Box, { className: styles.header, children: [_jsxs("div", { className: styles.headerText, children: [_jsx("div", { children: t("component.login:label.enter_email", {
                            defaultValue: "Please enter the account email you used to login with.",
                        }) }), _jsx("div", { children: t("component.login:label.email_with_reset_password", {
                            defaultValue: "We'll send you an email with your username and a link to reset your password.",
                        }) })] }), _jsx(CloseDialogButton, { onClick: () => {
                    closeForgotPasswordDialog();
                } })] }));
    const renderEmailInputField = () => (_jsx(RiverTextInput, { className: styles.email, id: "email", value: email, onChangeEvent: (event) => onEmailChange(event), label: t("common.label:email", {
            defaultValue: "Email",
        }), fullWidth: true }));
    const renderHelpInfo = () => (_jsxs("div", { className: styles.helpInfo, children: [t("component.login:label.if_you_still_need_help", {
                defaultValue: "If you still need help,",
            }), _jsx(Link, { href: "#", onClick: preventDefault, className: styles.contactSupport, children: t("component.login:label.contact_suport", {
                    defaultValue: "contact Support.",
                }) })] }));
    const renderForgotPasswordDialog = () => (_jsxs(Dialog, { open: isForgotPasswordDialogOpened, TransitionComponent: Transition, classes: { paper: styles.forgotPassword }, children: [_jsxs(DialogContent, { classes: { root: styles.contentRoot }, children: [renderDialogHeader(), renderEmailInputField()] }), _jsxs(DialogActions, { classes: { root: styles.footer }, children: [renderHelpInfo(), _jsxs(Box, { className: styles.dialogButtons, children: [_jsx(Button, { classes: { root: styles.button }, onClick: () => closeForgotPasswordDialog(), className: styles.cancel, children: t("common.button:cancel", {
                                    defaultValue: "Cancel",
                                }) }), _jsx(Button, { classes: { root: styles.button }, onClick: sendEmail, variant: "contained", color: "primary", type: "submit", children: t("common.button:send", {
                                    defaultValue: "Send",
                                }) })] })] })] }));
    const renderConfirmationPopup = () => {
        return (_jsxs(Dialog, { open: isConfirmationPopupOpened, TransitionComponent: Transition, className: clsx([
                styles.confirmationPopup,
                { [styles.mobile]: isMobile },
            ]), children: [_jsx(CloseDialogButton, { onClick: () => {
                        closeConfirmationPopup();
                    } }), _jsx("img", { src: envelope, alt: "Envelope", style: { maxWidth: "100%" } }), _jsxs("span", { className: styles.confirmationCheck, children: [t("component.login:label.check_your", {
                            defaultValue: "Check your",
                        }), _jsx("span", { style: { whiteSpace: "nowrap" }, children: t("common.label:email", {
                                defaultValue: "Email",
                            }) })] }), _jsxs("div", { className: styles.popupBottomSection, children: [_jsx("span", { className: styles.confirmationSent, children: t("component.login:label.we_sent_recover_link", {
                                email: email,
                                defaultValue: `We have sent a password recover link to e-mail ${email}`,
                            }) }), _jsx(Button, { classes: { root: styles.button }, className: styles.confirmationSendAgain, onClick: () => closeConfirmationPopup(), variant: "contained", color: "primary", type: "submit", children: t("common.button:send", {
                                defaultValue: "Send",
                            }) }), _jsx("span", { className: styles.confirmationSpam, children: t("component.login:label.didnt_receive_email", {
                                defaultValue: "Didn't receive the e-mail? Check your spam folder or try another email address",
                            }) })] })] }));
    };
    const render = () => (_jsxs(_Fragment, { children: [renderForgotPasswordDialog(), renderConfirmationPopup()] }));
    return render();
};
